import React from "react";
const TextArea = ({ rows, cols, placeholder, onChange, value, className }) => {
  return (
    <textarea
      rows={rows}
      cols={cols}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      className={className}
    ></textarea>
  );
};
export default TextArea;
