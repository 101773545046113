import { useState, useEffect } from "react";
import React from "react";
import {
  useRouteMatch,
  Link,
  Route,
  Switch,
  NavLink,
  useParams,
  useHistory,
} from "react-router-dom";
import About from "./../About/About";
import QuestionsTable from "../utils/QuestionTable/QuestionsTable";
import "./Home.css";
import Input from "../utils/reuseableComponent/Input";
import WelcomePopUp from "../Login/WelcomePopUp";
import { BsPlus } from "react-icons/bs";
import AskQuestionModal from "./AskQuestionModal";
import Button from "../utils/reuseableComponent/Button";
import { ImCancelCircle } from "react-icons/im";
import {
  getRecentQuestionsApi,
  getFeaturedQuestionsApi,
  getMostViewedQuestionsApi,
  filterQuestionsApi,
} from "../../services/api/QuestionsApi";
import { BsSearch } from "react-icons/bs";
import { trackPromise } from "react-promise-tracker";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { useTranslation } from "react-i18next";
localStorage.setItem("CheckIn", true);
const Home = () => {
  const { t, i18n } = useTranslation();
  const home = false;
  localStorage.setItem("adminPannel", home);
  const [tracker, setTracker] = useState();
  const { url, path } = useRouteMatch();
  const [searchQues, setSearchQues] = useState("");
  const [recentQues, setRecentQues] = useState([]);
  const [mostViewedQues, setMostViewedQues] = useState([]);
  const [featuredQues, setFeaturedQues] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [askQuestionShow, setaskQuestionShow] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const [filterQues, setFilterQues] = useState([]);
  const [welcomeCount, setWelcomeCount] = useState();
  const [filterEmpty, setFilterEmpty] = useState([]);
  const [clearSearch, setClearSearch] = useState(false);
  const [noQuestionsFound, setNoQuestionsFound] = useState(false);
  const [reCallRecentQuestion, setReCallRecentQuestion] = useState(false);
  const [reCallMostViwedQuestion, setReCallMostViwedQuestion] = useState(false);
  const [reCallFeatureQuestion, setReCallFeatureQuestion] = useState(false);

  const [stopLoader, setStopLoader] = useState(true);
  const [loaderQuestionCall, setLoaderQuestionCall] = useState(false);

  const history = useHistory();
  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    console.log("pop", pop_status);
    if (!pop_status) {
      setModalShow(true);
      localStorage.setItem("pop_status", true);
      console.log("useEffect");
    }

    console.log("recall");

    if (window.location.pathname == "/home") {
      setSearchQues("");
      trackPromise(recentQuestions());
    }

    if (window.location.pathname == "/home/modern") {
      setSearchQues("");
      trackPromise(featuredQuestions());
    }
    if (window.location.pathname == "/home/special") {
      setSearchQues("");
      trackPromise(mostViewedQuestions());
    }
    setWelcomeCount(welcomeCount);
  }, [
    pageNum,
    window.location.pathname,
    reCallRecentQuestion,
    reCallMostViwedQuestion,
    reCallFeatureQuestion,
  ]);

  async function searchQuestions(word) {
    if (word == "" && window.location.pathname.includes("search")) {
      history.push(`${path}`);
    }

    history.push(`${path}/search=${word}`);
    const response = await filterQuestionsApi({ searchOffset, word });
    console.log("response search home", response);
    if (response?.data?.results?.length === 0) {
      setNoQuestionsFound(true);
    } else {
      if (response?.data?.next || filterQues?.length <= response?.data?.count) {
        for (let i = 0; i <= filterQues.length; ++i) {
          filterQues.pop();
        }
        setFilterQues([...filterQues, ...response.data.results]);
        setSearchOffset(searchOffset + 10);
      } else {
        let fliterLenght = filterQues.length;
        for (let j = 0; j <= fliterLenght; ++j) {
          filterQues.pop();
        }
        setClearSearch(true);
        setFilterQues([...filterQues, ...response.data.results]);
      }
    }
    if (word == "") {
      setFilterQues(filterEmpty);
      setClearSearch(false);
    }
    if (word == "" && window.location.pathname.includes("search")) {
      history.push(`${path}`);
    }
  }

  function searchChange(search) {
    setSearchQues(search);
    setFilterQues([]);
    setSearchOffset(0);
    setNoQuestionsFound(false);
  }

  function scrollToEnd() {
    if (!searchQues) {
      setPageNum(pageNum + 10);
    }

    if (searchOffset >= 10 && window.location.pathname.includes("search")) {
      searchQuestions(searchQues);
      console.log("enter search");
    }
  }

  window.onscroll = function scroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };

  async function mostViewedQuestions() {
    try {
      setStopLoader(true);
      setLoaderQuestionCall(false);
      setReCallRecentQuestion(true);
      setReCallFeatureQuestion(true);
      if (reCallMostViwedQuestion) {
        setPageNum(0);
        let tableLength = mostViewedQues.length;
        for (let i = 0; i <= tableLength; ++i) {
          mostViewedQues.pop();
        }
      }
      if (pageNum === 0 && mostViewedQues.length === 0) {
        const response = await getMostViewedQuestionsApi({ pageNum });
        setMostViewedQues([...mostViewedQues, ...response.data.results]);
        setReCallMostViwedQuestion(false);
      }
      if (pageNum > 0) {
        setLoaderQuestionCall(true);
        const response = await getMostViewedQuestionsApi({ pageNum });
        setMostViewedQues([...mostViewedQues, ...response.data.results]);
        if (response.data.next == null) {
          setStopLoader(false);
        }
        setReCallMostViwedQuestion(false);
      }
      setLoaderQuestionCall(false);
    } catch (err) {
      console.log("Error is ", err);
    }
  }

  async function featuredQuestions() {
    try {
      setStopLoader(true);
      setLoaderQuestionCall(false);
      setReCallMostViwedQuestion(true);
      setReCallRecentQuestion(true);
      if (reCallFeatureQuestion) {
        setPageNum(0);
        let tableLength = featuredQues.length;
        for (let i = 0; i <= tableLength; ++i) {
          featuredQues.pop();
        }
      }
      if (pageNum === 0 && featuredQues.length === 0) {
        const response = await getFeaturedQuestionsApi({ pageNum });
        setFeaturedQues([...featuredQues, ...response.data.results]);
        setReCallFeatureQuestion(false);
      }
      if (pageNum > 0) {
        setLoaderQuestionCall(true);
        const response = await getFeaturedQuestionsApi({ pageNum });
        setFeaturedQues([...featuredQues, ...response.data.results]);
        if (response.data.next == null) {
          setStopLoader(false);
        }
        setReCallFeatureQuestion(false);
      }
      setLoaderQuestionCall(false);
    } catch (err) {
      console.log("Error is ", err);
    }
  }
  async function recentQuestions() {
    try {
      setStopLoader(true);
      setLoaderQuestionCall(false);
      setReCallMostViwedQuestion(true);
      setReCallFeatureQuestion(true);
      console.log("setReCallRecentQuestion", reCallRecentQuestion);
      if (reCallRecentQuestion) {
        setPageNum(0);
        let tableLength = recentQues.length;
        for (let i = 0; i <= tableLength; ++i) {
          recentQues.pop();
        }
      }
      console.log("recall-get-recent-question");
      if (pageNum === 0 && recentQues.length === 0) {
        console.log("enter # 1");
        const response = await getRecentQuestionsApi({ pageNum });
        setRecentQues([...recentQues, ...response.data.results]);
        setTracker(false);
      }
      if (pageNum > 0) {
        setLoaderQuestionCall(true);
        console.log("enter # 2");
        const response = await getRecentQuestionsApi({ pageNum });
        console.log(" response get-recent-questions-api", response);
        setRecentQues([...recentQues, ...response.data.results]);
        if (response.data.next == null) {
          setStopLoader(false);
        }
        setTracker(false);
        setReCallRecentQuestion(false);
      }
      setLoaderQuestionCall(false);
    } catch (err) {
      console.log("Error is ", err);
    }
  }

  return (
    <section className="container min-h-100">
      <div className="main">
        <div className="tabs-search-bar">
          {/* {localStorage.getItem("CheckIn") == "true" ? (
            null
          ) : */}
          <>
            <WelcomePopUp
              show={modalShow}
              onHide={() => setModalShow(false)}
              setWelcomeCount={setWelcomeCount}
            />
          </>
          {/* } */}

          <Route path={path}>
            <div className="Tabs">
              <div className="Tablinks">
                <>
                  <NavLink
                    exact
                    onClick={() => {
                      setPageNum(0);
                    }}
                    activeClassName="active"
                    to={`${url}`}
                  >
                    {t("Recent Questions")}
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setPageNum(0);
                    }}
                    activeClassName="active"
                    to={`${url}/special`}
                  >
                    {t("Most Viewed")}
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setPageNum(0);
                    }}
                    activeClassName="active"
                    to={`${url}/modern`}
                  >
                    {t("Featured Questions")}
                  </NavLink>
                </>
              </div>
            </div>
            <div className="SearchBox">
              <Input
                placeholder={t("Search By Title")}
                value={searchQues}
                onKeyPress={(e) =>
                  !searchQues
                    ? null
                    : e.key === "Enter" && searchQuestions(searchQues)
                }
                onChange={(e) => {
                  searchChange(e.target.value);
                  searchQuestions(e.target.value);
                }}
              />
              <div className="question-search-btn">
                {clearSearch ? (
                  <ImCancelCircle
                    className="cancel"
                    onClick={() => {
                      searchQuestions("");
                      searchChange("");
                    }}
                  />
                ) : null}
                <button
                  onClick={() => searchQuestions(searchQues)}
                  disabled={!searchQues}
                >
                  <BsSearch />
                </button>
              </div>
            </div>

            <div className="write-question">
              <Link to="/ask-question">
                <BsPlus />
                {t("Write Your Question")}
              </Link>
              <AskQuestionModal
                show={askQuestionShow}
                onHide={() => setaskQuestionShow(false)}
              />
            </div>
          </Route>
        </div>
        <div>
          {tracker ? (
            <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
              <BeatLoader
                type="ThreeDots"
                color="rgb(195, 156, 51)"
                size="20px"
                margin="3px"
              />
            </div>
          ) : (
            <div>
              <Route exact path={`${path}`}>
                <QuestionsTable
                  data={recentQues}
                  loaderQuestionCall={loaderQuestionCall}
                />
              </Route>
              <Route exact path={`${path}/special`}>
                <QuestionsTable
                  data={mostViewedQues}
                  loaderQuestionCall={loaderQuestionCall}
                />
              </Route>
              <Route exact path={`${path}/modern`}>
                <QuestionsTable
                  data={featuredQues}
                  loaderQuestionCall={loaderQuestionCall}
                />
              </Route>
              <Route path={`${path}/search=${searchQues}`}>
                <h5>Search Results</h5>
                <QuestionsTable
                  data={filterQues.length !== 0 ? filterQues : recentQues}
                  noQuestionsFound={noQuestionsFound}
                  loaderQuestionCall={loaderQuestionCall}
                />
              </Route>
            </div>
          )}
        </div>
      </div>
      {stopLoader && !loaderQuestionCall ? (
        <div className="load-question-more-mobile">
          <Button
            title="See More"
            className="blue-btn"
            onClick={() => scrollToEnd()}
          />
        </div>
      ) : null}
    </section>
  );
};

export default Home;
