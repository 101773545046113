import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Button from '../reuseableComponent/Button';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Home from '../../Home/Home.css';
const ImagePopUpModul = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="img-popup">
        <img src={props.Url} />
        <Button
          onClick={props.onHide}
          title="Close"
          className="img-popup-close-btn blue-btn"
        >
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ImagePopUpModul;
