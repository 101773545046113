import React, { useState, useEffect } from 'react';
import Input from '../utils/reuseableComponent/Input';
import Button from '../utils/reuseableComponent/Button';
import { postUsersApi } from './../../services/api/UsersApi';
import PopUpMessage from './components/PopUpMessage';
import validator from 'validator';
import RequiredToolTip from './components/RequiredToolTip';
import { AiOutlineInfo } from 'react-icons/ai';
import BreadCrumbs from './components/BreadCrumbs';

const GrandMuftiCreateUser = () => {
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [firstNameUrdu, setFirstNameUrdu] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameUrdu, setLastNameUrdu] = useState('');
  const [active, setActive] = useState('');
  const [userRole, setUserRole] = useState('');

  const [emailError, setEmailError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [activeError, setActiveError] = useState('');
  const [userRoleError, setUserRoleError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [firstNameUrduError, setFirstNameUrduError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [lastNameUrduError, setLastNameUrduError] = useState('');

  const [messageAlert, setMessageAlert] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        email &&
        active &&
        userName &&
        lastNameUrdu &&
        firstNameUrdu &&
        userRole &&
        firstName &&
        lastName &&
        userRole !== 'Select the status' &&
        active !== 'Select the option'
      ) {
        if (!validator.isEmail(email)) {
          setEmailError('Enter valid email');
        } else {
          console.log('user-role => ', userRole);
          if (userRole == 'true') {
            var staff = true;
            var superStaff = false;
          } else if (userRole == 'false') {
            var staff = false;
            var superStaff = true;
          }
          let UserData = {
            email: email,
            username: userName,
            is_active: active,
            first_name: firstName,
            last_name: lastName,
            is_staff: staff,
            is_superuser: superStaff,
          };
          const response = await postUsersApi(UserData);
          console.log('response => ', response);
          if (response.status == '201') {
            setMessageAlert(true);
          }
        }
      } else {
        if (!email) setEmailError('Email is required');
        if (!userName) setUserNameError('Username is required');
        if (!firstName) setFirstNameError('Username is required');
        if (!lastName) setLastNameError('Username is required');
        if (!firstNameUrdu) setFirstNameUrduError('Username is required');
        if (!lastNameUrdu) setLastNameUrduError('Username is required');
        if (!userRole || userRole == 'Select the option')
          setUserRoleError('User role is required');
        if (!active || active == 'Select the option')
          setActiveError('User status is required');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } catch (err) {
      const { response } = err;
      console.log('error');
    }
  };
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    { name: " Create Users", },
  ])

  const pageName = "Create Users"
  return (
    <>
      <div className="tables-breadcrums">
        <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
      </div>
      <form onSubmit={onSubmit}>
        <div className="wrap-grand-mufti main grand-mufti-user-create">
          <div class="language-dedication">
            <h4>
              Create User{' '}
              <RequiredToolTip
                Text="Use this form to create the staff member or administrator"
                Direction="right"
                icon={<AiOutlineInfo />}
                className="information-ttoltip"
              />{' '}
            </h4>
          </div>

          <div className="grand-mufti-input-topic">
            <h6>
              First Name{' '}
              <RequiredToolTip
                Text="Feild Required"
                Direction="right"
                icon="*"
                className="required-ttoltip"
              />
            </h6>
            <Input
              placeholder="First Name"
              value={firstName}
              onChange={(e) => {
                if (firstName != null) {
                  setFirstNameError('');
                }
                setFirstName(e.target.value);
              }}
            />
            {firstNameError ? (
              <p className="email-error">{firstNameError}</p>
            ) : null}
          </div>
          <div className="grand-mufti-input-topic urdu-language font-urdu">
            <h6>
              {' '}
              <RequiredToolTip
                Text="فیلڈ درکار ہے"
                Direction="left"
                icon="*"
                className="required-ttoltip "
                urduFont="font-urdu"
              />{' '}
              پہلا نام
            </h6>
            <Input
              placeholder="اپنا نام درج کریں"
              value={firstNameUrdu}
              onChange={(e) => {
                if (firstNameUrdu != null) {
                  setFirstNameUrduError('');
                }
                setFirstNameUrdu(e.target.value);
              }}
              className="urdu-language"
            />
            {firstNameUrduError ? (
              <p className="email-error">{firstNameUrduError}</p>
            ) : null}
          </div>

          <div className="grand-mufti-input-topic">
            <h6>
              Last Name{' '}
              <RequiredToolTip
                Text="Feild Required"
                Direction="right"
                icon="*"
                className="required-ttoltip"
              />
            </h6>
            <Input
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => {
                if (lastName != null) {
                  setLastNameError('');
                }
                setLastName(e.target.value);
              }}
            />
            {lastNameError ? (
              <p className="email-error">{lastNameError}</p>
            ) : null}
          </div>
          <div className="grand-mufti-input-topic urdu-language font-urdu">
            <h6>
              {' '}
              <RequiredToolTip
                Text="فیلڈ درکار ہے"
                Direction="left"
                icon="*"
                className="required-ttoltip "
                urduFont="font-urdu"
              />{' '}
              آخری نام
            </h6>
            <Input
              placeholder="اپنا نام درج کریں"
              value={lastNameUrdu}
              onChange={(e) => {
                if (lastNameUrdu != null) {
                  setLastNameUrduError('');
                }
                setLastNameUrdu(e.target.value);
              }}
              className="urdu-language"
            />
            {lastNameUrduError ? (
              <p className="email-error">{lastNameUrduError}</p>
            ) : null}
          </div>

          <div className="right-side-form">
            <div className="grand-mufti-input-topic">
              <h6>
                User Name{' '}
                <RequiredToolTip
                  Text="Feild Required"
                  Direction="right"
                  icon="*"
                  className="required-ttoltip"
                />
              </h6>
              <Input
                placeholder="User Name"
                value={userName}
                onChange={(e) => {
                  if (userName != null) {
                    setUserNameError('');
                  }
                  setUserName(e.target.value);
                }}
              />
              {userNameError ? (
                <p className="email-error">{userNameError}</p>
              ) : null}
            </div>
            <div className="grand-mufti-input-topic">
              <h6>
                Email{' '}
                <RequiredToolTip
                  Text="Feild Required"
                  Direction="right"
                  icon="*"
                  className="required-ttoltip"
                />
              </h6>
              <Input
                placeholder="User Email"
                value={email}
                onChange={(e) => {
                  if (email != null) {
                    setEmailError('');
                  }
                  setEmail(e.target.value);
                }}
              />
              {emailError ? <p className="email-error">{emailError}</p> : null}
            </div>

            <div className="grand-mufti-select-topic">
              <h6>
                User role{' '}
                <RequiredToolTip
                  Text="Feild Required"
                  Direction="right"
                  icon="*"
                  className="required-ttoltip"
                />
              </h6>
              <select
                className="topic-dropdown"
                onChange={(e) => {
                  if (!userRole) {
                    setUserRoleError('');
                  }
                  setUserRole(e.target.value);
                }}
              >
                <option>Select Role </option>
                <option value="true">Staff Member</option>
                <option value="false">Administrator</option>
              </select>
              {userRoleError ? (
                <p className="email-error">{userRoleError}</p>
              ) : null}
            </div>
            <div className="grand-mufti-select-topic">
              <h6>
                Status{' '}
                <RequiredToolTip
                  Text="Feild Required"
                  Direction="right"
                  icon="*"
                  className="required-ttoltip"
                />
              </h6>
              <select
                className="topic-dropdown"
                onChange={(e) => {
                  if (active != null) {
                    setActiveError('');
                  }
                  setActive(e.target.value);
                }}
              >
                <option>Select Status </option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              {activeError ? <p className="email-error">{activeError}</p> : null}
            </div>
          </div>


          <div className="grand-mufti-button">
            <Button title="Create User" className="grand-mufti-create-topic" />
          </div>
          <PopUpMessage
            LinkURL="/admin/user"
            message="User has been created"
            show={messageAlert}
            onHide={() => setMessageAlert(false)}
          />
        </div>
      </form>
    </>
  );
};
export default GrandMuftiCreateUser;
