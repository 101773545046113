import React, { useState, useEffect } from 'react';
// import { useParams } from "react-router-dom";
import DashTable from './components/DashTable';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md'
import BreadCrumbs from './components/BreadCrumbs';
import {
  getPendingQuestionsApi,
  deleteQuestionsApi,
  filterPendingQuestionsApi,

} from '../../services/api/QuestionsApi';
import moment from 'moment';
import { BsSearch } from 'react-icons/bs';
import Input from '../utils/reuseableComponent/Input';
import { BeatLoader } from 'react-spinners';
import Button from '../utils/reuseableComponent/Button';
import { ImCancelCircle } from 'react-icons/im';
const GrandMuftiPending = ({ setNavigationLoad }) => {
  const [tableData, setTableData] = useState([]);
  // const [loginUser, setLoginUser] = useState([]);
  const [offset, setOffset] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const [filterQues, setFilterQues] = useState([]);
  const [searchQues, setSearchQues] = useState('');
  const [endGetRequests, setEndGetRequests] = useState(false);
  const [tracker, setTracker] = useState(true);
  const [noQuestionsFound, setNoQuestionsFound] = useState(false);
  const [viewClick, setViewClick] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [filterEmpty, setFilterEmpty] = useState([]);
  const [callingComponent, setCallingComponent] = useState(false);
  const [statsCalling, setStatsCalling] = useState(false);

  // const params = useParams();
  // const questionID = params.id;

  useEffect(() => {
    getNewQuestions();
    // getLoginUser();
    localStorage.setItem('QuestionCondition', true);
    localStorage.setItem('Appect', false);
    localStorage.setItem("Breadcurms", "Review Questions")
    localStorage.setItem("breadcurmsPath", "/admin/questions/grand-mufti-pending-questions")
    localStorage.setItem("reAgain", "false")
    setViewClick(false)
  }, [offset, viewClick, callingComponent]);

  function tittleTurncate(description) {
    return description?.split(' ').splice(0, 7).join(' ');
  }
  async function searchQuestions(word) {
    let id = localStorage.getItem('UserLoginID');
    const response = await filterPendingQuestionsApi({
      id,
      searchOffset,
      word,
    });
    console.log("pending question response ", response)
    if (response?.data?.results.length === 0) {
      setNoQuestionsFound(true);
    } else {
      setClearSearch(true)
      if (response.data.next || filterQues.length <= response.data.count) {
        for (let i = 0; i <= filterQues.length + 1; ++i) {
          filterQues.pop()
        }
        setFilterQues([...filterQues, ...response.data.results]);
        setSearchOffset(searchOffset + 10);
      } else {
        let fliterLenght = filterQues.length
        for (let j = 0; j <= fliterLenght + 1; ++j) {
          filterQues.pop()
        }
        setFilterQues([...filterQues, ...response.data.results]);
      }
    }
    if (word == "") {
      setFilterQues(filterEmpty);
      setClearSearch(false)
      setNoQuestionsFound(false)
    }
  }
  console.log("filter tabe ", filterQues)
  function searchChange(search) {
    setSearchQues(search);
    setFilterQues([]);
    setSearchOffset(0);
    setNoQuestionsFound(false);
  }

  async function getNewQuestions() {
    try {
      if (!endGetRequests) {
        const response = await getPendingQuestionsApi({ offset });
        console.log("pending", response);
        if (response?.data?.results?.length == 0) {
          setTracker(false);
        } else {
          console.log('assigned response ', response);
          setTableData([...tableData, ...response.data.results]);
          console.log('tableData ', tableData);
          setTracker(false);
        }
        if (!response.data.next) {
          setEndGetRequests(true);
        }
      }
      if (callingComponent) {
        if (filterEmpty) {
          setFilterQues(filterEmpty);
        }
        const response = await getPendingQuestionsApi({ offset });
        if (response?.data?.results?.length == 0 && tableData.length != 1) {

        } else {
          let tableLength = tableData.length
          for (let i = 0; i <= tableLength; ++i) {
            tableData.pop()
          }
          setTableData([...tableData, ...response.data.results]);
          console.log("tableData =>", tableData)
          console.log("response ", response)
          setCallingComponent(false)
        }
      }
    } catch (err) {
      console.log('Error is ', err);
    }
  }

  function scrollToEnd() {
    if (!searchQues) {
      setOffset(offset + 10);
    }

    if (searchOffset >= 10) {
      searchQuestions(searchQues);
    }
  }

  window.onscroll = function scroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };
  const handlePending = () => {
    localStorage.setItem('QuestionCondition', true);
    localStorage.setItem('Appect', false);
  };

  const [tableHead, setTableHead] = useState([
    {
      title: 'Title',
      render: (rowData) => {
        return (
          <span>{`${tittleTurncate(rowData.title_en)}...`}</span>
        );
      },
      className: 'dash-ques-title',
    },
    {
      title: 'Topics',
      render: (rowData) => {
        return <span>{rowData.category[0].name}</span>;
      },
    },
    {
      title: 'Created By',
      render: (rowData) => {
        return <span>{rowData?.created_by_name}</span>;
      },
    },
    {
      title: 'Created By Email',
      render: (rowData) => {
        return <span>{(rowData?.created_by_email)}</span>;
      },
      className: 'email-view',
    },
    {
      title: 'Date',
      render: (rowData) => {
        return <span>{moment(rowData.updated_at).format('MM/DD/YYYY')}</span>;
      },
    },
    {
      title: 'View',
      render: (rowData) => {
        return (
          <>
          </>
        );
      },
      className: 'action-view',
    },
  ]);

  const [viewSection, setViewSection] = useState([
    {
      path: "/admin/questions/pending-answer-review",
      buttonTitle: "View Details",
      Icon: <BsEye />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      isDeleted: true,
      buttonTitle: "Delete",
      Icon: <MdDelete />,
      ColorClass: "delete-question",
      isActive: true
    }
  ]);

  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    { name: "Review Questions", },
  ])
  const pageName = "Review Questions"
  const QuestionTitle = 'Dashboard > Pending Questions';
  const messageData = "Pending Question"
  const questionPath = "/admin/questions/pending-answer-review/"

  const pageTableName = "Review Question"
  const pageTableLink = "/admin/questions/grand-mufti-pending-questions"
  const previousPage = "/admin/questions/pending-answer-review/"
  return (
    <>
      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>
          <div className="tables-breadcrums">
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
          </div>
          <div className="main-table-cotainer box-shadow">
            <div className="dash-table-top-bar">
              <div className="search-side">
                <h4>Questions Tables</h4>
              </div>
              <div className="SearchBox">
                <Input
                  placeholder="Search By Title"
                  value={searchQues}
                  onKeyPress={(e) =>
                    !searchQues
                      ? null
                      : e.key === 'Enter' && searchQuestions(searchQues)
                  }
                  onChange={(e) => {
                    searchChange(e.target.value)
                    searchQuestions(e.target.value)
                  }}
                />
                <div className="question-search-btn">
                  {clearSearch ? <ImCancelCircle className="cancel" onClick={() => {
                    searchQuestions("")
                    searchChange("")
                  }} /> : null}
                  <button
                    onClick={() => searchQuestions(searchQues)}
                    disabled={!searchQues}
                  >
                    <BsSearch />
                  </button>
                </div>
              </div>
            </div>
            <DashTable
              noQuestionsFound={noQuestionsFound}
              tableData={filterQues.length !== 0 ? filterQues : tableData}
              QuestionTitle={QuestionTitle}
              tableHead={tableHead}
              viewSection={viewSection}
              setViewClick={setViewClick}
              deletedApi={deleteQuestionsApi}
              messageData={messageData}
              questionPath={questionPath}
              setCallingComponent={setCallingComponent}
              pageTableName={pageTableName}
              pageTableLink={pageTableLink}
              previousPage={previousPage}
              setNavigationLoad={setNavigationLoad}
              setStatsCalling={setStatsCalling}
            />
          </div>
        </>
      )}
    </>
  );
};

export default GrandMuftiPending;
