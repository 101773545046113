import React, { useState } from 'react';
const Input = ({
  label,
  type,
  placeholder,
  name,
  multiple,
  className,
  onChange,
  value,
  onKeyPress,
  oninput,
  checked
}) => {
  return (
    <>
      <label htmlFor={name} >{label}</label>
      <input
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        multiple={multiple}
        className={className}
        onChange={onChange}
        value={value}
        onKeyPress={onKeyPress}
        oninput={oninput}
        checked={checked}
      />
    </>
  );
};

export default Input;
