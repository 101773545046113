import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AnswerDescription from './AnswerDescription';
import QuestionDetail from './QuestionDetail';
import './QuestionDetail.css';
import { getQuestionsDetailsApi, QuestionsRefrencesApi, getFatwa } from '../../../services/api/QuestionsApi';
import { trackPromise } from 'react-promise-tracker';
import { BeatLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
const QuestionDetailsPage = () => {
  const params = useParams();
  const questionID = params.id;
  const { t, i18n } = useTranslation();
  let { id: questionId } = useParams();

  const [Question, setQuestion] = useState({});
  const [Answer, setAnswer] = useState([]);
  const [countNumber, setCountNumber] = useState(0);
  const [tracker, setTracker] = useState(true);
  const [refrences, setRefrences] = useState([]);
  const [fatwa, setFatwa] = useState("");

  useEffect(() => {
    trackPromise(getQuestionsDetails());
  }, []);

  async function getQuestionsDetails() {
    try {
      const response = await getQuestionsDetailsApi({ id: questionID });
      setQuestion(response.data);
      console.log("Question ", response)
      setAnswer(response.data.answer);
      setCountNumber(response.data.views_count)
      console.log("Answer ", Answer)
      setTracker(false)
      const responseRefrences = await QuestionsRefrencesApi(response.data.id);
      console.log("Refrences Question ", responseRefrences)
      setRefrences(responseRefrences)
      const responseFatwa = await getFatwa(response.data.id);
      console.log(" Fatwa ", responseFatwa.data.results[0].fatwa_number)
      setFatwa(responseFatwa.data.results[0].fatwa_number)

    } catch (error) {
      console.log('Error', error);
    }
  }
  return (
    <section className="container">
      <div className="answer-detail main">
        {tracker ? (
          <div className="question-detail-loader">
            <BeatLoader
              type="ThreeDots"
              color="rgb(195, 156, 51)"
              size="20px"
              margin="3px"
            />
          </div>
        ) : (
          <div>
            {Question && <QuestionDetail Question={Question} questionID={questionID} Answer={Answer} />}
            {Question && <AnswerDescription
              Answer={Answer}
              questionId={questionId}
              countNumber={countNumber}
              refrences={refrences}
              fatwa={fatwa}
            />}
          </div>
        )}

        <ul className="answer-bottom">
          <li>{t("Allah (subhana Wa Ta'ala) Knows Best")}</li>
          <li>{t('Hidayah Academy')}</li>
        </ul>
      </div>
    </section>
  );
};

export default QuestionDetailsPage;
