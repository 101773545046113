import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { postQuestionsApi } from "./../../services/api/QuestionsApi";
import RequiredToolTip from "./components/RequiredToolTip";
import Button from "../utils/reuseableComponent/Button";
import TextEditor from "../utils/reuseableComponent/TextEditor/TextEditor";
import { BsPencilSquare } from "react-icons/bs";
import { convertToRaw } from "draft-js";
import Input from "../utils/reuseableComponent/Input";
import "./assests/DashboardQuestionDetailPage.css";
import PopUpMessage from "./components/PopUpMessage";
import { Link, useParams, useHistory } from "react-router-dom";
import { getPublicTopicsApi } from "./../../services/api/TopicsApi";
import { getLoginUserApi } from "./../../services/api/UsersApi";
import BreadCrumbs from "./components/BreadCrumbs";

const CreateQuestionAdmin = () => {
  const params = useParams();
  const questionID = params.id;

  const linkParams = useLocation();
  const linkPage = linkParams.state?.linkPage;
  const Breadcrum = linkParams.state?.Breadcrum;

  const location = useLocation();

  useEffect(() => {
    getTopics();
  }, []);

  async function getTopics() {
    try {
      const response = await getPublicTopicsApi();
      setTopics(response.data);
      const responseUser = await getLoginUserApi();
      setEmail(responseUser?.data?.email);
      setName(responseUser?.data?.username);
    } catch (error) {}
  }

  const [Question, setQuestion] = useState({});

  const [editorContent, setEditorContent] = useState("");
  const [editorContentUrdu, setEditorContentUrdu] = useState("");
  const [editQuestion, setEditQuestion] = useState("");
  const [editQuestionUrdu, setEditQuestionUrdu] = useState("");
  const [englishEditTitle, setEnglishEditTitle] = useState("");
  const [urduEditTitle, setUrduEditTitle] = useState("");
  const [topics, setTopics] = useState([]);
  const [topicId, setTopicId] = useState("");
  const [topicError, setTopicError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messageAlert, setMessageAlert] = useState(false);
  const [directionLink, setDirectionLink] = useState("");

  const [urduEdit, setUrduEdit] = useState(false);
  const [englishEdit, setEnglishEdit] = useState(false);

  const [errorEditQuestion, setErrorEditQuestion] = useState("");
  const [errorEditQuestionUrdu, setErrorEditQuestionUrdu] = useState("");
  const [errorEnglishEditTitle, setErrorEnglishEditTitle] = useState("");
  const [errorUrduEditTitle, setErrorUrduEditTitle] = useState("");

  const [directPath, setDirectPath] = useState("");

  let questionId = useParams();
  let ID = questionId.id;

  async function EditQUestion(e) {
    e.preventDefault();
    try {
      if (
        englishEditTitle &&
        urduEditTitle &&
        editQuestionUrdu &&
        editQuestion &&
        topicId &&
        topicId != "Select the topic"
      ) {
        var editorData =
          editorContent && JSON.stringify(convertToRaw(editorContent));
        var editorDataUrdu =
          editorContentUrdu && JSON.stringify(convertToRaw(editorContentUrdu));
        var data = {
          title: englishEditTitle,
          title_ur: urduEditTitle,
          title_en: englishEditTitle,
          description_ur: editorDataUrdu,
          description_en: editorData,
          is_visible: true,
          is_active: true,
          email: email,
          name: name,
          topic: topicId,
        };
        const response = await postQuestionsApi(data);
        if (response?.status == "201") {
          setMessageAlert(true);
          setDirectionLink(`/admin/new-question-view/${response?.data?.id}`);
        }
      } else {
        if (!englishEditTitle) setErrorEnglishEditTitle("Feild is required");
        if (!urduEditTitle) setErrorUrduEditTitle(" فیلڈ ضروری ہے");
        if (!editQuestion) setErrorEditQuestion("Feild is required");
        if (!editQuestionUrdu) setErrorEditQuestionUrdu(" فیلڈ ضروری ہے");
        if (!topicId || topicId == "Select the topic")
          setTopicError("Please select Topic");
      }
    } catch (error) {}
  }

  let QuestionDescription = Question.description_en;
  let QuestionDescriptionUrdu = Question.description_ur;
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin",
    },
    { name: "Create New Question" },
  ]);
  const pageName = "Create New Question";

  return (
    <>
      <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
      <section className="detail-question-bar edit-page">
        <div className="row">
          <div className="col-md-12">
            <>
              <div className="select-topics">
                <h2 className="title bold-haeding">Topic :</h2>
                <select
                  onChange={(e) => {
                    if (topicId != null) {
                      setTopicError("");
                    }
                    setTopicId(e.target.value);
                  }}
                  className="topic-dropdown"
                >
                  <option>Select the topic</option>
                  {topics &&
                    topics.map((topic) =>
                      topic.children.map((child, index) => (
                        <option key={index} value={child.id}>
                          {localStorage.getItem("language") == "en"
                            ? child.name_en
                            : child.name_ur}
                        </option>
                      ))
                    )}
                </select>
              </div>
              <p className="error">{topicError}</p>
              <div className="div-sepration"></div>
              <div className="question-title">
                <h2 className="title bold-haeding">Title :</h2>
                <div className="wrap-text-Title">
                  <Input
                    type="text"
                    placeholder={"Enter Your Title"}
                    value={englishEditTitle}
                    onChange={(e) => {
                      setEnglishEditTitle(e.target.value);
                      if (!englishEditTitle) {
                        setErrorEnglishEditTitle("");
                      }
                    }}
                  />
                </div>
              </div>
              <p className="error">{errorEnglishEditTitle}</p>
              <div className="question-question">
                <h2 className="title bold-haeding">Re-write Question : </h2>
              </div>
              <div className="wrap-text-editor">
                <TextEditor
                  sendToEditor={setEditorContent}
                  Edit={QuestionDescription}
                  validationForEmpty={setEditQuestion}
                  isEdit={setEnglishEdit}
                />
                <div className="text-editor-submit-button"></div>
              </div>
              <p className="error">{errorEditQuestion}</p>
              <div className="div-sepration"></div>

              <div className="question-title urdu-language font-urdu">
                <h2 className="title bold-haeding">: عنوان</h2>

                <div className="wrap-text-Title">
                  <Input
                    type="text"
                    placeholder={"اپنا عنوان درج کریں"}
                    value={urduEditTitle}
                    onChange={(e) => {
                      setUrduEditTitle(e.target.value);
                      if (!urduEditTitle) {
                        setErrorUrduEditTitle("");
                      }
                    }}
                    className="urdu-language"
                  />
                </div>
              </div>
              <p className="error urdu-language font-urdu">
                {errorUrduEditTitle}
              </p>

              <div className="question-question urdu-language font-urdu">
                <h2 className="title bold-haeding">: سوال دوبارہ لکھیں</h2>
              </div>
              <div className="wrap-text-editor font-urdu">
                <TextEditor
                  sendToEditor={setEditorContentUrdu}
                  Edit={QuestionDescriptionUrdu}
                  validationForEmpty={setEditQuestionUrdu}
                  isEdit={setUrduEdit}
                />
              </div>
              <p className="error urdu-language font-urdu">
                {errorEditQuestionUrdu}
              </p>
              <div className="div-sepration"></div>
              <div className="submited-button">
                <Button
                  title="Submit"
                  className="Save-Button"
                  onClick={EditQUestion}
                />
              </div>
            </>
          </div>
        </div>
      </section>
      <PopUpMessage
        LinkURL={directionLink}
        message="Question has been submitted"
        show={messageAlert}
        onHide={() => setMessageAlert(false)}
      />
    </>
  );
};
export default CreateQuestionAdmin;
