import React, { useState, useEffect } from "react";
import UserProfileDetail from "./UserProfileDetail";
import { getLoginUserApi } from "../../../services/api/UsersApi";
import { getUserStatsApi } from "../../../services/api/UserStats";
import { BeatLoader } from "react-spinners";

const UserProfile = () => {
  useEffect(() => {
    getLoginUser();
    getStats();
  }, []);
  const [profileDetail, setProfileDetail] = useState([]);
  const [stats, setStats] = useState([]);
  const [tracker, setTracker] = useState(true);
  async function getLoginUser() {
    try {
      const response = await getLoginUserApi();
      console.log("Edit Profile Data", response?.data);
      setProfileDetail((data) => [...data, response?.data]);
    } catch (err) {
      console.log("Error is ", err);
    }
  }

  async function getStats() {
    let id = localStorage.getItem("UserLoginID");
    try {
      const response = await getUserStatsApi({ id });
      console.log("Edit Profile Data Stats", response?.data?.response);
      setStats((data) => [...data, response?.data]);
      setTracker(false);
      console.log("Stats => ", stats);
    } catch (err) {
      console.log("Error is ", err);
    }
  }
  console.log("Stats => ", stats);

  return (
    <>
      {
        tracker ? (
          <div className="dash-preloader" >
            <BeatLoader
              type="ThreeDots"
              color="rgb(195, 156, 51)"
              size="20px"
              margin="3px"
            />
          </div >
        ) : (
          <div className="container">
            <div className="main user-profile">
              {profileDetail && (
                <UserProfileDetail profileDetail={profileDetail} stats={stats} />
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default UserProfile;
