import React, { useEffect } from "react";
import BreadCrumbs from "./components/BreadCrumbs";
import ReviewAnswerDetailPage from "./ReviewAnswerDetailPage";
import { useParams } from 'react-router-dom';
const PendingAnswerReview = ({ setNavigationLoad }) => {

    const params = useParams();
    const questionID = params.id;
    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: "Review Answer",
                path: "/admin/questions/grand-mufti-pending-questions",
            },
            { name: "Answer Detail Page", },
        ]

    const pageName = "Answer Detail Page"
    const Accept = false
    const isRequiredComment = true
    return (
        <>
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
            <ReviewAnswerDetailPage
                Accept={Accept}
                isRequiredComment={isRequiredComment}
                setNavigationLoad={setNavigationLoad}
            />
        </>
    );
};
export default PendingAnswerReview;
