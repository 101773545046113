import React from 'react';
import Button from '../utils/reuseableComponent/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

const AskQuestionModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}

      <Modal.Body className="ask-question-modal">
        <h1>Want to ask Questions?</h1>
        <Link to="/login">
          <Button title="Login" className="blue-btn" />
        </Link>
      </Modal.Body>
    </Modal>
  );
};

export default AskQuestionModal;
