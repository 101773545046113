import { axiosPublicApi } from "../axios-config/axios";

export const postEmailAPi = async (emailData) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `auth/password/reset/`,
      method: "POST",
      bodyData: emailData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
