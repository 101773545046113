import React, { useState, useEffect } from "react";
import DashTable from "./components/DashTable";
import { Link } from "react-router-dom";
import { BsEye } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import BreadCrumbs from "./components/BreadCrumbs";
import {
  getApprovedQuestionsApi,
  filterApprovedQuestionsApi,
  deleteQuestionsApi,
} from "../../services/api/QuestionsApi";
import moment from "moment";
import { BsSearch } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import Input from "../utils/reuseableComponent/Input";
import { BeatLoader } from "react-spinners";
const ApprovedQuestion = ({ setNavigationLoad }) => {
  const [tableData, setTableData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const [filterQues, setFilterQues] = useState([]);
  const [searchQues, setSearchQues] = useState("");
  const [endGetRequests, setEndGetRequests] = useState(false);
  const [tracker, setTracker] = useState(true);
  const [noQuestionsFound, setNoQuestionsFound] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [filterEmpty, setFilterEmpty] = useState([]);
  const [callingComponent, setCallingComponent] = useState(false);
  const [statsCalling, setStatsCalling] = useState(false);

  const [viewClick, setViewClick] = useState(false);

  useEffect(() => {
    getApprovedQuestions();
    localStorage.setItem("QuestionCondition", false);
    setViewClick(false);
    localStorage.setItem("Breadcurms", "Approved Questions");
    localStorage.setItem(
      "breadcurmsPath",
      "/admin/questions/approved-questions"
    );
    localStorage.setItem("reAgain", "false");
  }, [offset, viewClick, callingComponent, statsCalling]);

  function tittleTurncate(description) {
    return description?.split(" ").splice(0, 7).join(" ");
  }

  async function searchQuestions(word) {
    let id = localStorage.getItem("UserLoginID");
    const response = await filterApprovedQuestionsApi({
      id,
      searchOffset,
      word,
    });
    console.log("Approved Doble Data", response);
    if (response?.data?.results.length === 0 && word.length !== 0) {
      setNoQuestionsFound(true);
    } else {
      setClearSearch(true);
      if (response.data.next || filterQues.length <= response.data.count) {
        console.log("enter in # Filter ", filterQues);
        console.log("enter in first length  ", filterQues.length);
        for (let i = 0; i <= filterQues.length + 1; ++i) {
          filterQues.pop();
        }
        console.log("enter in first Filter ", filterQues);
        console.log("enter in first");
        setFilterQues([...filterQues, ...response.data.results]);
        setSearchOffset(searchOffset + 10);
      } else {
        let fliterLenght = filterQues.length;
        for (let j = 0; j <= fliterLenght + 1; ++j) {
          filterQues.pop();
        }
        console.log("enter in second");
        setFilterQues([...filterQues, ...response.data.results]);
      }
    }
    if (word == "") {
      setFilterQues(filterEmpty);
      setClearSearch(false);
      setNoQuestionsFound(false);
    }
  }
  console.log(" Apprved FilterQues Table ", filterQues);
  function searchChange(search) {
    setSearchQues(search);
    setFilterQues([]);
    setSearchOffset(0);
    setNoQuestionsFound(false);
  }
  async function getApprovedQuestions() {
    try {
      let id = localStorage.getItem("UserLoginID");
      console.log("enter in Block 1.0");
      if (!endGetRequests) {
        const response = await getApprovedQuestionsApi({ id, offset });
        console.log("Approved Loaded Question", response);
        console.log("offset", offset);
        console.log("enter in Block");
        if (response?.data?.results?.length == 0) {
          setTracker(false);
        } else {
          setTableData([...tableData, ...response.data.results]);
          setTracker(false);
        }
        if (!response?.data?.next) {
          setEndGetRequests(true);
        }
      }
      if (callingComponent) {
        console.log("enter in callingComponent");
        if (filterEmpty) {
          setFilterQues(filterEmpty);
        }
        setOffset(0);
        const response = await getApprovedQuestionsApi({ id, offset });
        console.log(" response ", response);
        if (response?.data?.results?.length == 0 && tableData.length != 1) {
        } else {
          let tableLength = tableData.length;
          for (let i = 0; i <= tableLength + 1; ++i) {
            tableData.pop();
          }
          setTableData([...tableData, ...response.data.results]);
          setCallingComponent(false);
          if (!response?.data?.next) {
            setEndGetRequests(true);
          }
        }
      }
      if (statsCalling) {
        console.log("enter in callingComponent");
        const response = await getApprovedQuestionsApi({ id, offset });
        console.log(" response ", response);
        if (response?.data?.results?.length == 0 && tableData.length != 1) {
        } else {
          let tableLength = tableData.length;
          for (let i = 0; i <= tableLength + 1; ++i) {
            tableData.pop();
          }
          setTableData([...tableData, ...response.data.results]);
          setStatsCalling(false);
        }
      }
    } catch (err) { }
  }

  console.log(" tableData ", tableData);

  function scrollToEnd() {
    if (!searchQues) {
      setOffset(offset + 10);
      console.log("enter in offset");
    }

    if (searchOffset >= 10) {
      searchQuestions(searchQues);
    }
  }

  window.onscroll = function scroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };
  const handlePending = () => {
    localStorage.setItem("QuestionCondition", false);
  };
  const [tableHead, setTableHead] = useState([
    {
      title: "Title",
      render: (rowData) => {
        return <span>{`${tittleTurncate(rowData?.title)}...`}</span>;
      },
      className: "dash-ques-title",
    },
    {
      title: "Topics",
      render: (rowData) => {
        return <span>{rowData?.category[0]?.name}</span>;
      },
      className: "topics-view",
    },
    {
      title: "Created By",
      render: (rowData) => {
        return <span>{rowData?.created_by_name}</span>;
      },
    },
    {
      title: "Created By Email",
      render: (rowData) => {
        return <span>{rowData?.created_by_email}</span>;
      },
      className: "email-view",
    },
    {
      title: "Visible",
      render: (rowData) => {
        return (
          <span
            className={`status ${rowData.is_visible ? "status-active" : "status-inactive"
              } `}
          >
            {rowData.is_visible ? "Active" : "Inactive"}
          </span>
        );
      },
    },
    {
      title: "Date",
      render: (rowData) => {
        return (
          <span>{rowData ? moment(rowData.answer[0].approved_date).format("MMMM Do YYYY") : ""}</span>
        );
      },
    },
    {
      title: "View",
      render: (rowData) => {
        return <></>;
      },
      className: "action-view",
    },
  ]);
  let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  const [viewSection, setViewSection] = useState([
    {
      path: "/admin/questions/approve-answer-review",
      buttonTitle: "View Details",
      Icon: <BsEye />,
      ColorClass: "View-detail-page",
      isActive: true,
    },
    {
      buttonTitle: "Feature",
      ColorClass: "View-detail-page",
      isActive: isAdmin ? true : false,
      isFeature: true,
    },
    {
      buttonTitle: "Is visiable",
      ColorClass: "View-detail-page",
      isActive: isAdmin ? true : false,
      isVisiable: true,
    },
    {
      path: "",
      buttonTitle: "Delete",
      Icon: <MdDelete />,
      ColorClass: "delete-question",
      isActive: isAdmin ? true : false,
      isDeleted: true,
    },
  ]);
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin",
    },
    { name: "Approved Questions" },
  ]);
  const pageName = "Approved Questions";
  const QuestionTitle = "Question Table";
  const questionPath = "/admin/questions/approve-answer-review/";

  const pageTableName = "Approved Question";
  const pageTableLink = "/admin/questions/approved-questions";
  const previousPage = "/admin/questions/approve-answer-review/";

  console.log(" Approved Question TableData ", tableData);
  return (
    <>
      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>
          <div className="tables-breadcrums">
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
          </div>
          <div className="main-table-cotainer box-shadow">
            <div className="dash-table-top-bar">
              <div className="search-side">
                <h4>{QuestionTitle}</h4>
              </div>
              <div className="SearchBox">
                <Input
                  placeholder="Search By Title"
                  value={searchQues}
                  onKeyPress={(e) =>
                    !searchQues
                      ? null
                      : e.key === "Enter" && searchQuestions(searchQues)
                  }
                  onChange={(e) => {
                    searchChange(e.target.value);
                    searchQuestions(e.target.value);
                  }}
                />
                <div className="question-search-btn">
                  {clearSearch ? (
                    <ImCancelCircle
                      className="cancel"
                      onClick={() => {
                        searchQuestions("");
                        searchChange("");
                      }}
                    />
                  ) : null}
                  <button
                    onClick={() => searchQuestions(searchQues)}
                    disabled={!searchQues}
                  >
                    <BsSearch />
                  </button>
                </div>
              </div>
            </div>
            <DashTable
              noQuestionsFound={noQuestionsFound}
              tableData={filterQues.length !== 0 ? filterQues : tableData}
              QuestionTitle={QuestionTitle}
              tableHead={tableHead}
              viewSection={viewSection}
              setViewClick={setViewClick}
              questionPath={questionPath}
              setCallingComponent={setCallingComponent}
              deletedApi={deleteQuestionsApi}
              pageTableName={pageTableName}
              pageTableLink={pageTableLink}
              previousPage={previousPage}
              setNavigationLoad={setNavigationLoad}
              setStatsCalling={setStatsCalling}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ApprovedQuestion;
