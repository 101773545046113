import React, { useEffect } from "react";
import BreadCrumbs from "./components/BreadCrumbs";
import ReviewAnswerDetailPage from "./ReviewAnswerDetailPage";
import { useParams } from 'react-router-dom';
const ApprovedAnswerReview = ({ setNavigationLoad }) => {

    const params = useParams();
    const questionID = params.id;
    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: "Approved Answer",
                path: "/admin/questions/approved-questions",
            },
            { name: "Answer Detail Page", },
        ]

    const pageName = "Answer Detail Page"
    const isRequiredComment = false
    const isRejected = true
    const nonClass = "none-display"
    const onlyAdminEdit = true
    return (
        <>
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
            <ReviewAnswerDetailPage
                isRequiredComment={isRequiredComment}
                isRejected={isRejected}
                nonClass={nonClass}
                onlyAdminEdit={onlyAdminEdit}
                setNavigationLoad={setNavigationLoad}
            />
        </>
    );
};
export default ApprovedAnswerReview;
