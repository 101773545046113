import React, { useState } from 'react';
import Dropdown from '../../utils/reuseableComponent/Dropdown';
import '../assests/GraphBox.css';
import '../assests/Dashboard.css';
import Chartjs from './Chart';

import RequiredToolTip from './RequiredToolTip';
import { BsQuestion } from 'react-icons/bs';

const GraphBox = ({ graphStats }) => {

  console.log("graphStats", graphStats)

  let questionsData = graphStats?.last_seven_days_questions_asked?.data;
  let questionsLabels = graphStats?.last_seven_days_questions_asked?.labels;
  let answersData = graphStats?.last_seven_days_answered_by_user?.data;
  let answersLabels = graphStats?.last_seven_days_answered_by_user?.labels;
  let rejectedAnswersData = graphStats?.last_seven_dasys_approved_questions?.data;
  let rejectedAnswersLabels = graphStats?.last_seven_dasys_approved_questions?.labels;
  let assignedAnswersData = graphStats?.last_seven_dasys_assigned_questions?.data;
  let assignedAnswersLabels = graphStats?.last_seven_dasys_assigned_questions?.labels;
  let approvedAnswersData = graphStats?.last_seven_dasys_approved_questions?.data;
  let approvedAnswersLabels = graphStats?.last_seven_dasys_approved_questions?.labels;

  console.log('Graph Data', graphStats);

  const options = {
    chart: {
      height: '280', // 16:9 ratio
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: questionsLabels,
    },
    yAxis: {
      title: {
        text: 'No of Questions',
        // style: {
        //   fontSize: '12.5px',
        // }
      },
    },
    series: [
      {
        name: 'Questions',
        data: questionsData,
      },
    ],
  };
  const options1 = {
    chart: {
      height: '280', // 16:9 ratio
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: answersLabels,
    },
    yAxis: {
      title: {
        text: 'Number of occurrences',
        // style: {
        //   fontSize: '12.5px',
        // }
      },
    },
    series: [
      {
        name: 'Total questions',
        data: questionsData,
      },
      {
        name: 'Total answers',
        data: answersData,
      },
      {
        name: 'Total rejected answers',
        data: rejectedAnswersData,
      },
      {
        name: 'Total approved answers',
        data: approvedAnswersData,
      },
      {
        name: 'Total pending questions',
        data: assignedAnswersData,
      },
    ],
  };
  const options2 = {
    chart: {
      height: '280', // 16:9 ratio
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      title: {
        text: 'No of Questions',
        // style: {
        //   fontSize: '12.5px',
        // }
      },
    },
    series: [
      {
        name: 'Questions',
        data: [5, 50, 30, 25, 100, 40, 70],
      },
    ],
  };
  return (
    <div className="graph-box-wrapper">
      {/* <div className="graph-box box-shadow">
        <div className="graph-box-header">
          <h4>
            Questions of this week{' '}
            <RequiredToolTip
              Text="Total number of questions asked by user of this week"
              Direction="right"
              icon={<AiOutlineInfo />}
              className="information-ttoltip"
            />
          </h4>
        </div>
        <Chartjs option={options} />
      </div> */}
      <div className="graph-box box-shadow">
        <div className="graph-box-header">
          <h4>
            {/* Stats of this week{' '} */}
            <RequiredToolTip
              title="Stats of this week"
              Text="Following graph shows total number of questions, answers, rejected answers, approved answers and assigned questions of this week."
              Direction="right"
              icon={<BsQuestion />}
              className="information-ttoltip"
            />
          </h4>
        </div>
        <Chartjs option={options1} />
      </div>
    </div>
  );
};

export default GraphBox;
