import React, { useState, useEffect } from "react";
import ImagePopUpModul from "../utils/AskQuestion/ImagePopUpModul";
import TextEditor from "../utils/reuseableComponent/TextEditor/TextEditor";
import "../Dashboard/assests/DashboardAnswerDetail.css";
import { AiOutlineFilePdf } from "react-icons/ai";
import { GrDocumentTxt } from "react-icons/gr";
import { AiOutlineFileWord } from "react-icons/ai";
import Button from "../utils/reuseableComponent/Button";
import { Link, useParams, useHistory } from "react-router-dom";
import { convertToRaw } from "draft-js";
import {
  postAnswersApi,
  postRefrences,
  getFatwa,
} from "./../../services/api/AnswersApi";
import {
  putQuestionsDetailsApi,
  SelectQuestionsBookApi,
} from "../../services/api/QuestionsApi";
import RequiredToolTip from "./components/RequiredToolTip";
import PopUpMessage from "./components/PopUpMessage";
import { getQuestionsDetailsApi } from "../../services/api/QuestionsApi";

const DashboardAnswerDetail = ({
  Question,
  imageCondition,
  audioCondition,
  urduValidation,
  urduEditTitle,
  englishEditTitle,
  englishValidation,
  setErrorEditQuestionUrdu,
  setErrorEditQuestion,
  setErrorEnglishEditTitle,
  setErrorUrduEditTitle,
  setIsAccepted,
  isAssigned,
  setNavigationLoad,
}) => {
  let { id: questionId } = useParams();
  let history = useHistory();
  let breadcurmsPath = localStorage.getItem("breadcurmsPath");
  console.log("breadcurmsPath", breadcurmsPath);

  const [editorContent, setEditorContent] = useState("");
  const [editRefrences, setEditRefrences] = useState("");
  const [EditorContentUrdu, setEditorContentUrdu] = useState("");
  const [imageUpload, setImageUpload] = useState([]);
  const [openImage, setOpenImage] = useState(false);
  const [popURL, setPopURL] = useState("");
  const [assined, setAssined] = useState(
    localStorage.getItem("assignedQuestion")
  );

  const [errorEnglishAnswer, setErrorEnglishAnswer] = useState("");
  const [errorRefrences, setErrorRefrences] = useState("");
  const [errorUrduAnswer, setErrorUrduAnswer] = useState("");
  const [editQuestionUrdu, setEditQuestionUrdu] = useState("");
  const [editQuestionRefrences, setEditQuestionRefrences] = useState("");
  const [editQuestionEnglish, setEditQuestionEnglish] = useState("");
  const [book, setBook] = useState([]);
  const [bookID, setBookID] = useState("");
  const [bookError, setBookError] = useState("");

  const [editable, setEditable] = useState(false);

  const [isMyselfAssigned, setIsMyselfAssigned] = useState(false);

  const Back = () => {
    history.goBack();
  };

  async function getBook() {
    try {
      let offset = 0;
      let limit = 20;
      const response = await SelectQuestionsBookApi({ offset, limit });
      console.log("response Book ", response);
      setBook(response.data.results);
    } catch (error) { }
  }

  console.log("Book Response  ", book);
  console.log("bookID ", bookID);

  const [messageAlert, setMessageAlert] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (
        englishValidation &&
        urduEditTitle &&
        englishEditTitle &&
        urduValidation &&
        editQuestionEnglish &&
        editQuestionUrdu &&
        editQuestionRefrences &&
        bookID &&
        bookID != "Select the topic"
      ) {
        let editorData =
          editorContent && JSON.stringify(convertToRaw(editorContent));
        let editorDataUrdu =
          EditorContentUrdu && JSON.stringify(convertToRaw(EditorContentUrdu));
        let answeredbBy = localStorage.getItem("UserLoginID");
        const data = {
          description_en: editorData,
          description_ur: editorDataUrdu,
          is_visible: true,
          question: questionId,
          answered_by: answeredbBy,
        };
        const response = await postAnswersApi(data);
        if (response.status == "201") {
          let editorDataRefrences =
            editRefrences && JSON.stringify(convertToRaw(editRefrences));
          const response = await getQuestionsDetailsApi({ id: questionId });
          console.log("response answer Id", response.data.answer[0].id);
          let answerID = response.data.answer[0].id;
          const RefrencesData = {
            question: questionId,
            answer: answerID,
            reference_by: answeredbBy,
            description: editorDataRefrences,
            book: bookID,
          };
          const refrencesResponse = await postRefrences(RefrencesData);
          console.log("refrencesResponse ", refrencesResponse);
          if (refrencesResponse.status == "201") {
            setNavigationLoad(true);
            setMessageAlert(true);
          }
        }
      } else {
        console.log("empty validation urdu", editQuestionUrdu);
        console.log("empty validation english", editQuestionEnglish);
        console.log("empty validation refrences", editQuestionRefrences);
        if (!englishValidation) setErrorEditQuestion("Feild is required");
        if (!urduValidation) setErrorEditQuestionUrdu("فیلڈ ضروری ہے");

        if (!editQuestionEnglish) setErrorEnglishAnswer("Feild is Required");
        if (!editQuestionUrdu) setErrorUrduAnswer(" فیلڈ ضروری ہے");
        if (!editQuestionRefrences) setErrorRefrences("Refrences is REquired");

        if (!englishEditTitle) setErrorEnglishEditTitle("Feild is Required");
        if (!urduEditTitle) setErrorUrduEditTitle("فیلڈ ضروری ہے");
        if (!bookError) setBookError("Book feild is required");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (err) {
      console.log("Error is", err);
    }
  }
  const loadingStopOnSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    setImageUpload(Question.media);
    getBook();
  }, []);
  console.log(
    "localStorage.getItem('assignedQuestion')",
    localStorage.getItem("assignedQuestion")
  );
  const handleAssigned = async (e) => {
    e.preventDefault();
    try {
      let userid = localStorage.getItem("UserLoginID");
      let UserID = {
        assigned_to: userid,
      };
      const response = await putQuestionsDetailsApi(questionId, UserID);
      if (response.status == "200") {
        setNavigationLoad(true);
        setAssined("true");
        setIsAccepted(true);
        setIsMyselfAssigned(true);
        console.log("isMyselfAssigned", isMyselfAssigned);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("assined", assined);

  console.log(" editorContent ", editorContent);
  console.log(" EditorContentUrdu ", EditorContentUrdu);

  return (
    <>
      {imageCondition || audioCondition ? (
        <div className="wrap-image-record">
          {imageCondition ? (
            <>
              <div className="wrap-image-attatchments">
                <div className="attatchments-heading">
                  <h4>Attatchments</h4>
                </div>
                <div className="image-attatchments">
                  {imageUpload.map((item, index) => {
                    return item.file.split(".").pop() == "jpg" ||
                      item.file.split(".").pop() == "png" ? (
                      <div key={index} className="previewImageBox">
                        <div className="previewImage">
                          <img
                            onClick={() => {
                              setOpenImage(true);
                              setPopURL(item.file);
                            }}
                            src={item.file}
                            alt="..."
                            className="img-fluid"
                          />
                          <ImagePopUpModul
                            show={openImage}
                            onHide={() => setOpenImage(false)}
                            Url={popURL}
                          />
                        </div>
                      </div>
                    ) : item.file.split(".").pop() == "pdf" ||
                      item.file.split(".").pop() == "docx" ||
                      item.file.split(".").pop() == "txt" ? (
                      <>
                        <div key={index} className="previewImageBox">
                          <div className="previewImage-Documents">
                            {item.file.split(".").pop() == "pdf" ? (
                              <AiOutlineFilePdf />
                            ) : item.file.split(".").pop() == "txt" ? (
                              <GrDocumentTxt />
                            ) : (
                              <AiOutlineFileWord />
                            )}
                            <p>{item.file.split(".").pop()} Document</p>
                            <a
                              href={item.file}
                              className="attach-download-btn"
                              download
                              className="Document-button"
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </>
                    ) : null;
                  })}
                </div>
              </div>
            </>
          ) : null}
          {audioCondition ? (
            <div className="record-attatchments">
              <div className="attatchments-heading">
                <h4>Recording</h4>
              </div>
              <div className="audio-attatchments">
                {/* {audioCondition ? ( */}
                <>
                  {imageUpload.map((audio, index) => {
                    return (
                      audio.type == "AUDIO" && (
                        <audio key={index} src={audio.file} controls />
                      )
                    );
                  })}
                </>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {isAssigned || isMyselfAssigned ? (
        <div className="wrap-text-editor">
          <h4>
            Write Answer{" "}
            <RequiredToolTip
              Text="Feild Required"
              Direction="right"
              icon="*"
              className="required-ttoltip"
            />
          </h4>
          <h5>Write your answer in detail</h5>
          <form onSubmit={handleSubmit}>
            <TextEditor
              sendToEditor={setEditorContent}
              validationForEmpty={setEditQuestionEnglish}
              isEdit={setEditable}
            />
            {errorEnglishAnswer && !editQuestionEnglish ? (
              <p className="error">{errorEnglishAnswer}</p>
            ) : null}
            <div className="urdu-language font-urdu Urdu-Description">
              <h4>
                {" "}
                <RequiredToolTip
                  Text="فیلڈ درکار ہے"
                  Direction="left"
                  icon="*"
                  className="required-ttoltip "
                  urduFont="font-urdu"
                />{" "}
                جواب لکھیں
              </h4>
              <h5>اپنے جواب کو تفصیل سے لکھیں</h5>
            </div>
            <div className="font-urdu">
              <TextEditor
                sendToEditor={setEditorContentUrdu}
                validationForEmpty={setEditQuestionUrdu}
                isEdit={setEditable}
              />
            </div>
            {errorUrduAnswer && !editQuestionUrdu ? (
              <p className="error urdu-language font-urdu">{errorUrduAnswer}</p>
            ) : null}

            <div className="wrap-divs admin-books">
              <h3 className="ask-title">Books</h3>
              <div className="row">
                <div className="col-md-12">
                  <select
                    onChange={(e) => {
                      if (bookID != null) {
                        setBookError("");
                      }
                      setBookID(e.target.value);
                    }}
                    className="topic-dropdown"
                  >
                    <option>Select the Book</option>
                    {book.map((bookitem, index) => {
                      console.log("bookitem", bookitem.name);
                      let bookListName = bookitem.name;
                      return (
                        <option key={index} value={bookitem.id}>
                          {bookListName}
                        </option>
                      );
                    })}
                  </select>
                  <p className="error">{bookError}</p>
                </div>
              </div>
            </div>

            <div className="refrences-section">
              <h4>
                Write Refrences
                <RequiredToolTip
                  Text="Feild Required"
                  Direction="right"
                  icon="*"
                  className="required-ttoltip"
                />
              </h4>

              <TextEditor
                sendToEditor={setEditRefrences}
                validationForEmpty={setEditQuestionRefrences}
                isEdit={setEditable}
              />
              {errorRefrences && !editQuestionRefrences ? (
                <p className="error">{errorRefrences}</p>
              ) : null}
            </div>

            <div className="text-editor-submit-button">
              <Button title="Submit" type="submit" />
            </div>
          </form>
          <PopUpMessage
            LinkURL={breadcurmsPath}
            message="Answer has been submitted for review"
            show={messageAlert}
            onHide={() => setMessageAlert(false)}
          />
        </div>
      ) : (
        <>
          <div class="comment-box main">
            <div className="div-sepration"></div>
            <div className="assigned-button">
              <div className="assigned-bUtton-note">
                <p>
                  <span>Note: </span>Click on the Assigned Button to write the
                  answer
                </p>
              </div>

              <form onSubmit={handleAssigned}>
                <Button title="Assign Myself" />
              </form>

              <Link to="/admin/new-question">
                <Button
                  className="cancel-button"
                  title="Cancel"
                  onClick={Back}
                />
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default DashboardAnswerDetail;
