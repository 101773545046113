import axiosApi from "../axios-config/axios";

export const postFireBaseApi = async (FireBaseData) => {
    try {
        const { data, status } = await axiosApi({
            endpoint: `devices/`,
            method: "POST",
            bodyData: FireBaseData,
        });
        return { data, status };
    } catch (error) {
        console.log(error);
    }
};