import axiosApi from "../axios-config/axios";
import { axiosPublicApi } from "../axios-config/axios";

export const getTopicsApi = async () => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `topics/`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const patchTopicApi = async (topicData, id) => {
  console.log("Data Edit Topic =>", topicData);
  console.log("Data Edit id =>", id);
  try {
    const { data, status } = await axiosApi({
      endpoint: `topics/${id}/`,
      method: "PATCH",
      bodyData: topicData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getPublicTopicsApi = async () => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `public/topics/`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const postTopicsApi = async (TopicData) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `topics/`,
      method: "POST",
      bodyData: TopicData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getChilderTopics = async (id) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `topics/${id}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const deleteTopicsApi = async (id) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `topics/${id}/`,
      method: "DELETE",
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
