import React from "react";
import { AiOutlineShareAlt } from "react-icons/ai";
import TextEditor from "./../reuseableComponent/TextEditor/TextEditor";
import { useTranslation } from "react-i18next";
import { RWebShare } from "react-web-share";
import { AiOutlinePrinter } from "react-icons/ai";
const QuestionDetail = ({ Question, questionID, Answer }) => {
  console.log("Question.category ", Question);
  const { t, i18n } = useTranslation();
  let QuestionDescription = Question.description_en;
  let QuestionDescriptionUrdu = Question.description_ur;
  let webSite = ["facebook", "whatsapp"];
  console.log("saddsaadsa");
  console.log(localStorage.getItem("language"));
  console.log(Question.category[0].breadcrum_en);
  console.log(Question.category[0].breadcrum_ur);

  return (
    <section className="detail-question-bar">
      <div className="row">
        <div className="col-md-12">
          <div className="question-answer-top">
            <p className="bread-crumb">
              {localStorage.getItem("language") == "en"
                ? " Topic: " + Question.category[0].breadcrum_en
                : "موضوع  : " + Question.category[0].breadcrum_ur}
            </p>
            <div className="social-activity">
              <div className="social-media-activity">
                <RWebShare
                  data={{
                    text: "Visit hidayah academy to ask your questions",
                    url: `http://askhidayah.com/question-details/${questionID}/${Question.slug}`,
                    title: "Hidayah Academy",
                  }}
                  sites={["facebook", "whatsapp", "copy"]}
                >
                  <button>
                    <AiOutlineShareAlt />
                  </button>
                </RWebShare>
                <button onClick={() => window.print()}>
                  <AiOutlinePrinter />
                </button>
              </div>
            </div>
          </div>
          <div className="div-sepration"></div>
          <div className="name-id">
            <p className="question-id">
              {t("Ledger Number")}: <span>{Question.ledger_number}</span>
            </p>
            <p className="question-id"></p>
          </div>
          <div className="question-title">
            <h2 className="title bold-haeding">{t("Title")} : </h2>
            <p>
              {localStorage.getItem("language") == "en"
                ? Question.title_en
                : Question.title_ur}
            </p>
          </div>
          <div className="question-question">
            <h2 className="title bold-haeding">{t("Question")} : </h2>
            {localStorage.getItem("language") == "en" ? (
              <TextEditor description={QuestionDescription} />
            ) : (
              <TextEditor description={QuestionDescriptionUrdu} />
            )}
          </div>
          <div className="div-sepration"></div>
        </div>
      </div>
    </section>
  );
};

export default QuestionDetail;
