import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./assests/DashTable.css";
import DashTable from "./components/DashTable";
import { getTopicsApi, deleteTopicsApi } from "./../../services/api/TopicsApi";
import moment from "moment";
import Button from "../utils/reuseableComponent/Button";
import { BeatLoader } from "react-spinners";
import { FaRegEdit } from 'react-icons/fa';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md'
import BreadCrumbs from './components/BreadCrumbs';
const Topic = () => {
  const [searchDashQues, setSearchDashQues] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tracker, setTracker] = useState(true);
  const [viewClick, setViewClick] = useState(false);
  const [callingComponent, setCallingComponent] = useState(false);
  const [statsCalling, setStatsCalling] = useState(false);
  const [navigationLoad, setNavigationLoad] = useState(false);

  async function getTopics() {
    try {
      const response = await getTopicsApi();
      console.log("Topic API ", response);
      setTableData(response.data);
      setTracker(false);
      console.log(response.data);
      if (callingComponent) {
        const response = await getTopicsApi();
        let tableLength = tableData.length
        for (let i = 0; i <= tableLength; ++i) {
          tableData.pop()
        }
        setTableData([...tableData, ...response.data.results]);
        console.log("tableData =>", tableData)
        console.log("response ", response)
        setCallingComponent(false)
      }
    } catch (err) {
      console.log("Error is ", err);
    }
  }
  useEffect(() => {
    getTopics();
  }, [callingComponent]);
  const [tableHead, setTableHead] = useState([
    {
      title: "Title",
      render: (rowData) => {
        return (

          <span>{rowData.name_en}</span>
        );
      },
      className: "dash-ques-title",
    },
    {
      title: "عنوان",
      render: (rowData) => {
        return (
          <span>{rowData.name_ur}</span>
        );
      },
      className: "dash-ques-title urdu-language font-urdu",
    },
    {
      title: "Childs",
      render: (rowData) => {
        return (
          <Link to={`/admin/childerns-view/${rowData.id}`}>
            <span>{rowData.children.length}</span>
          </Link>
        );
      },

      className: "text-center",
    },
    {
      title: "Date",
      render: (rowData) => {
        return <span>{moment(rowData.created_at).format("MM/DD/YYYY")}</span>;
      },
    },
    {
      title: "Action",
      render: (rowData) => {
        return (
          <>
          </>
        );
      },
      className: "action-view",
    },
  ]);

  const [viewSection, setViewSection] = useState([
    {
      path: "/admin/childerns-view",
      buttonTitle: "View Details",
      Icon: <BsEye />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      path: "/admin/edit-parents-topics",
      buttonTitle: "Edit",
      Icon: <FaRegEdit />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      isDeleted: true,
      buttonTitle: "Delete",
      Icon: <MdDelete />,
      ColorClass: "delete-question",
      isActive: true
    }
  ]);
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    { name: "Topics ", },
  ])
  const pageName = "Topics"
  const QuestionTitle = "Topics Table";
  let messageData = "Topics"
  const questionPath = "/admin/childerns-view/"
  return (
    <>
      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>
          <div className="tables-breadcrums">
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
          </div>
          <div className="main-table-cotainer box-shadow">
            <div className="dash-table-top-bar">
              <div className="search-side">
                <h4>{QuestionTitle}</h4>
              </div>
            </div>
            <DashTable
              tableData={tableData ? tableData : []}
              QuestionTitle={QuestionTitle}
              tableHead={tableHead}

              setViewClick={setViewClick}
              viewSection={viewSection}

              deletedApi={deleteTopicsApi}
              messageData={messageData}
              setCallingComponent={setCallingComponent}
              questionPath={questionPath}
              setStatsCalling={setStatsCalling}
              setNavigationLoad={setNavigationLoad}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Topic;
