import React from "react";
import SuccessCheck from "../../../images/success-check.png";
import { Link } from "react-router-dom";

const EditProfileSuccess = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="main edit-profile-success">
            <img src={SuccessCheck} alt="Success Check Image" />
            <p>Your password sucessfully changed</p>
            <Link to="/admin" className="blue-btn">
              Back to home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfileSuccess;
