import React, { useState, useEffect } from "react";
import './UserProfile.css';
import ChangeAccounInfo from './ChangeAccountInfo';
import ChangePassword from './ChangePassword';
import { BeatLoader } from "react-spinners";
import { getLoginUserApi } from "../../../services/api/UsersApi";
const EditProfile = () => {
  const [tracker, setTracker] = useState(true);
  const [getName, setGetName] = useState("");
  const [getEmail, setGetEmail] = useState("");
  const [getFirstName, setGetFirstName] = useState("");
  const [getLastName, setGetLastName] = useState("");

  useEffect(() => {
    getLoginUser();
  }, []);

  async function getLoginUser() {
    try {
      const response = await getLoginUserApi();
      console.log("Edit Profile Data", response?.data);
      setGetName(response?.data?.username);
      setGetEmail(response?.data?.email);
      setGetFirstName(response?.data?.first_name)
      setGetLastName(response?.data?.last_name)
      setTracker(false);
    } catch (err) {
      console.log("Error is ", err);
    }
  }

  return (
    <>
      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <section className="edit-profile">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                <div className="main">
                  <ChangeAccounInfo
                    getName={getName}
                    getEmail={getEmail}
                    getFirstName={getFirstName}
                    getLastName={getLastName}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                <div className="main">
                  <ChangePassword />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EditProfile;
