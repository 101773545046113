import axiosApi from "../axios-config/axios";
export const postDeviceActiveApi = async (id) => {
    try {
        const { data, status } = await axiosApi({
            endpoint: `FCM-Device-Active/?user_id=${id}`,
            method: "POST",
            bodyData: null,
        });
        return { data, status };
    } catch (error) {
        console.log(error);
    }
};

export const postDeviceDeactiveApi = async (id) => {
    try {
        const { data, status } = await axiosApi({
            endpoint: `FCM-Device-InActive/?user_id=${id}`,
            method: "POST",
            bodyData: null,
        });
        return { data, status };
    } catch (error) {
        console.log(error);
    }
};