import React from "react";
const Footer = () => {
  return (
    <>
      <div className="Footer">
        <div className="Footer_Copyright">
        © Hidayah Academy 2021 All right reserved.
        </div>
      </div>
    </>
  );
};

export default Footer;
