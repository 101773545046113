import ChangeAccounPassword from "./ChangeAccountPassword";
import React, { useState } from "react";
import { postPasswordApi } from "../../../services/api/PasswordChangeApi";
import PopUpMessage from "../../Dashboard/components/PopUpMessage";

const ChangePassword = () => {
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [prevPass, setPrevPass] = useState("");

  const [messageAlert, setMessageAlert] = useState(false);
  const [errorOldPassword, setErrorOldPassword] = useState();
  const [errorNewPassword, setErrorNewPassword] = useState();

  const [errorEmptyPreviousPassword, setErorEmptyPreviousPassword] = useState();
  const [errorEmptyNewPassword, setErrorEmptyNewPassword] = useState();
  const [errorEmptyConfirmPassword, setErorEmptyConfirmPassword] = useState();

  const ChangePassword = async (e) => {
    e.preventDefault();
    try {
      if (newPass && confirmPass && prevPass) {
        let passwordData = {
          new_password1: newPass,
          new_password2: confirmPass,
          old_password: prevPass,
        };
        const response = await postPasswordApi(passwordData,);
        setErrorOldPassword(response?.data?.old_password);
        setErrorNewPassword(response?.data?.new_password2);
        if (response.status == "200") {
          setMessageAlert(true);
        }
      } else {
        if (!newPass) setErrorEmptyNewPassword("Feild is required");
        if (!confirmPass) setErorEmptyConfirmPassword("Feild is required");
        if (!prevPass) setErorEmptyPreviousPassword("Feild is required");
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  return (
    <div className="">
      <form onSubmit={ChangePassword}>
        <ChangeAccounPassword
          needed="Change Needed"
          setNewPass={setNewPass}
          setCnfrmPass={setConfirmPass}
          setPrevPass={setPrevPass}
          newPass={newPass}
          cnfrmPass={confirmPass}
          prevPass={prevPass}
          errorNewPassword={errorNewPassword}
          errorOldPassword={errorOldPassword}
          errorEmptyPreviousPassword={errorEmptyPreviousPassword}
          errorEmptyNewPassword={errorEmptyNewPassword}
          errorEmptyConfirmPassword={errorEmptyConfirmPassword}
          setErorEmptyPreviousPassword={setErorEmptyPreviousPassword}
          setErrorEmptyNewPassword={setErrorEmptyNewPassword}
          setErorEmptyConfirmPassword={setErorEmptyConfirmPassword}
        />
      </form>
      <PopUpMessage
        LinkURL="/admin"
        message="Your password has been changed successfully"
        show={messageAlert}
        onHide={() => setMessageAlert(false)}
      />
    </div>
  );
};

export default ChangePassword;
