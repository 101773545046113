import Modal from "react-bootstrap/Modal";
import { RiDeleteBin5Line } from "react-icons/ri";
import Button from "../../utils/reuseableComponent/Button";
const ConformationPopUp = (props) => {
    const isDelete = async () => {
        const response = await props.deletedApi(props?.isDeletedID);
        console.log("Delete.response ", response)
        if (response?.status == "204") {
            props.setShowAction(false)
            props.setNavigationLoad(true)
            props.onHide()
            props.setCallingComponent(true)
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="Delete-Conformation"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body className="delete-conformation-modal">
                <div className="wrap-icon-div">
                    <div className="Icon-Div">
                        <RiDeleteBin5Line />
                    </div>
                </div>
                <h2>Are you sure you want to <span className="deleted-color">DELETE</span> this {props.message}</h2>
                <div className="conformaion-button">
                    <Button className="cancel-button" onClick={props.onHide} title="Cancel" />
                    <Button className="delete-button"
                        onClick={isDelete}
                        title="Delete" />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConformationPopUp;
