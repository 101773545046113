import React from 'react';
import '../../Dashboard/assests/RequiredToolTip.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const RequiredToolTip = ({
  title,
  Text,
  Direction,
  icon,
  className,
  urduFont,
}) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className={urduFont}>
      {Text}
    </Tooltip>
  );
  return (
    <>
      <OverlayTrigger
        placement={Direction}
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <div className={className}>
          {title} <span id="Tooltipicon">{icon}</span>
        </div>
      </OverlayTrigger>
    </>
  );
};

export default RequiredToolTip;
