import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DashTable from './components/DashTable';
import { Link } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md'
import BreadCrumbs from './components/BreadCrumbs';
import { patchQuestionsApi } from "../../services/api/QuestionsApi";
import {
    getAllStatus,
    getAllOpenQuestions,
    getAllQuestionsApi,
    getNewQuestionsApi,
    filterQuestionsAdminApi,
    deleteQuestionsApi,
} from '../../services/api/QuestionsApi';
import moment from 'moment';
import { BsSearch } from 'react-icons/bs';
import Input from '../utils/reuseableComponent/Input';
import { BeatLoader } from 'react-spinners';
import { ImCancelCircle } from 'react-icons/im';
const AllOpenQuestions = ({ setNavigationLoad }) => {
    const [tableData, setTableData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);
    const [filterQues, setFilterQues] = useState([]);
    const [searchQues, setSearchQues] = useState('');
    const [endGetRequests, setEndGetRequests] = useState(false);
    const [tracker, setTracker] = useState(true);
    const [noQuestionsFound, setNoQuestionsFound] = useState(false);
    const [viewClick, setViewClick] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [filterEmpty, setFilterEmpty] = useState([]);
    const [requestInProgress, serRequestInProgress] = useState(false);
    const [callingComponent, setCallingComponent] = useState(false);
    const [statsCalling, setStatsCalling] = useState(false);
    const [checkStatusUpdate, setcheckStatusUpdate] = useState([]);
    const [allStatus, setAllStatus] = useState([]);
    const [directLink, setdirectLink] = useState("");
    const params = useParams();
    const questionID = params.id;
    let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    useEffect(async () => {
        let response = await getAllStatus();

        if (response?.data?.results?.length > 0) {
            await setAllStatus(response.data.results);

            console.log(allStatus);
        }
    }
        , [])
    useEffect(async () => {
        getNewQuestions();
        await localStorage.setItem('assignedQuestion', false);
        setViewClick(false)
        localStorage.setItem("Breadcurms", "New Questions")
        localStorage.setItem("breadcurmsPath", "/admin/all-open-questions")
    }, [offset, viewClick, callingComponent]);
    function tittleTurncate(description) {
        return description?.split(' ').splice(0, 10).join(' ');
    }

    useEffect(async () => {
        console.log("status");
    }, [checkStatusUpdate]);


    console.log("New Question LocalStroage", localStorage.getItem('assignedQuestion'))
    async function updateStatus(id, newstatus) {
        console.log(newstatus); console.log(id.target.selectedIndex);
    }
    async function searchQuestions(word) {
        debugger;
        if (requestInProgress) return;
        await serRequestInProgress(true);
        const response = await filterQuestionsAdminApi({ searchOffset, word });
        console.log("new question filter", response)
        if (response?.data?.results?.length === 0) {
            setNoQuestionsFound(true);
        } else {
            setClearSearch(true)
            if (response.data.next || filterQues.length <= response.data.count) {
                for (let i = 0; i <= filterQues.length + 1; ++i) {
                    filterQues.pop()
                }
                await setFilterQues([...filterQues, ...response.data.results]);
                //setSearchOffset(searchOffset + 10);
            } else {
                let fliterLenght = filterQues.length
                for (let j = 0; j <= fliterLenght + 1; ++j) {
                    filterQues.pop()
                }
                await setFilterQues([...filterQues, ...response.data.results]);
            }
        }
        if (word == "") {
            await setFilterQues(filterEmpty);
            console.log(" filterQues ", filterQues)
            await setClearSearch(false)
            console.log("Clear Search", clearSearch)
            await setNoQuestionsFound(false)
        }
        await setSearchOffset(filterQues.length - 1);
        await serRequestInProgress(false);
    }

    console.log("new filterQues Question", filterQues)

    function searchChange(search) {

        setSearchQues(search);
        setFilterQues([]);
        setSearchOffset(0);
        setNoQuestionsFound(false);
    }
    function findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        window.location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }
    async function getNewQuestions() {
        //--
        if (requestInProgress) return;
        try {
            await serRequestInProgress(true);
            console.log("offset", offset)
            if (!endGetRequests) {

                const response = await getAllOpenQuestions(directLink);
                console.log('Get new questions', response);
                if (response?.data?.results?.length == 0) {

                    await setTracker(false);
                    if (response?.data?.count == tableData.length) {
                        await setEndGetRequests(true);
                    }
                } else {
                    console.log('assigned response ', response);
                    setdirectLink(response.data.next.split("?")[1]);
                    await setTableData([...tableData, ...response.data.results]);
                    console.log('tableData ', tableData);
                    await setTracker(false);
                }
                if (!response.data.next) {
                    //setEndGetRequests(true);
                }
            }

            await serRequestInProgress(false);
        } catch (err) {
            await serRequestInProgress(false);
            console.log('Error is ', err);
        }

    }

    //console.log("New Question tableData", tableData)

    function scrollToEnd() {
        debugger
        if (requestInProgress) return;

        getNewQuestions();

    }

    /* window.onscroll = function scroll() {
       //alert(document.documentElement.offsetHeight);
       //alert(window.innerHeight + document.documentElement.scrollTop);
       if (
         window.innerHeight + document.documentElement.scrollTop >=
         document.documentElement.offsetHeight
       ) {
         scrollToEnd();
       }
     };*/
    const handleAssigned = () => {

        localStorage.setItem('assignedQuestion', false);
    };
    const [tableHead, setTableHead] = useState([
        {
            title: 'Title',
            render: (rowData) => {
                return (
                    rowData?.title_en
                        ? <span>{`${tittleTurncate(rowData?.title_en)}...`}</span>
                        : <span className="urdu-language font-urdu">{`${tittleTurncate(rowData?.title_ur)}...`}</span>
                );
            },
            className: 'dash-ques-title',
        },
        {
            title: 'Topics',
            render: (rowData) => {
                return <span>{rowData?.category[0].name}</span>;
            },
        },
        {
            title: 'Created By',
            render: (rowData) => {
                return <span>{rowData?.created_by_name}</span>;
            },
        },
        {
            title: 'Created By Email',
            render: (rowData) => {
                return <span>{(rowData?.created_by_email)}</span>;
            },
        },
        {
            title: 'Date',
            render: (rowData) => {
                return <span>{moment(rowData?.created_at).format('MMMM Do YYYY')}</span>;
            },
        },
        {
            title: 'Status',

            render: (rowData, index) => {
                let Localoptions = ["مجیب", "نائب مفتی", "مفتی", "ٹرانسلیٹر"]
                Localoptions = rowData.localOptions;
                if (Localoptions == undefined || Localoptions.length < 1) {
                    return <p> </p>
                }
                console.log("####################");
                console.log(rowData);
                let sv = "";
                let local = rowData.progress_status;
                return <select value={Localoptions[local].text_value} onChange={(e) => rowData.handleStatusChange(e, index)} >
                    {Localoptions.map((value, index) => {
                        return <option>{value.text_value}</option>
                    })}
                </select>;
            }
        },

    ]);

    function updateQuestionStatus(e, index) {
        let Localoptions = allStatus
        let selected = e.target.value;
        let selectedIndex = 0;
        Localoptions.filter((value, index) => {
            if (value.text_value == selected) {
                selectedIndex = index;
            }
        })
        let tableDatacopy = tableData;

        tableDatacopy[index].progress_status = Localoptions[selectedIndex].id;
        console.log(tableData[index]);
        setTableData([...tableDatacopy]);
        const data = {

            progress_status: selectedIndex
        };
        const response = patchQuestionsApi(data, tableDatacopy[index].id);
    }
    console.log("re-render")
    const [viewSection, setViewSection] = useState("");
    const [breadcrum, setBreadcrum] = useState([
        {
            name: "Dashboard",
            path: "/admin"
        },
        { name: "All Questions", },
    ])

    const pageName = "All Questions"
    const messageData = "All Question"
    const questionPath = "/admin/new-question-view/"
    const pageLink = "/admialln/new-question"
    const pageBreadcrum = "New Question"
    const EditQuestionPage = "/admin/edit-questions/"
    return (
        <>
            {allStatus.length < 1 ? "" : tracker ? (
                <div className="dash-preloader">
                    <BeatLoader
                        type="ThreeDots"
                        color="rgb(195, 156, 51)"
                        size="20px"
                        margin="3px"
                    />
                </div>
            ) : (
                <>
                    <div className="tables-breadcrums">
                        <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
                    </div>
                    <div className="main-table-cotainer box-shadow">
                        <div className="dash-table-top-bar">
                            <div className="search-side">
                                <h4>Questions Table</h4>
                            </div>
                            <div className="SearchBox">
                                <Input
                                    placeholder="Search By Title"
                                    value={searchQues}
                                    onKeyPress={(e) =>
                                        !searchQues
                                            ? null
                                            : e.key === 'Enter' && searchQuestions(searchQues)
                                    }
                                    onChange={(e) => {
                                        searchChange(e.target.value)
                                        searchQuestions(e.target.value)
                                    }}
                                />
                                <div className="question-search-btn">
                                    {clearSearch ? <ImCancelCircle className="cancel" onClick={() => {
                                        searchQuestions("")
                                        searchChange("")
                                    }} /> : null}
                                    <button
                                        onClick={() => searchQuestions(searchQues)}
                                        disabled={!searchQues}
                                    >
                                        <BsSearch />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <DashTable
                            noQuestionsFound={noQuestionsFound}
                            tableData={filterQues.length !== 0 ? filterQues : tableData}
                            tableHead={tableHead}
                            showAcctionCommponent={false}
                            setCallingComponent={setCallingComponent}
                            pageLink={pageLink}
                            pageBreadcrum={pageBreadcrum}
                            EditQuestionPage={EditQuestionPage}
                            setNavigationLoad={setNavigationLoad}
                            setStatsCalling={setStatsCalling}
                            isAllQ={true}
                            updatingFunction={updateQuestionStatus}
                            allStatus={allStatus}
                        />
                        <a href='#' className="search-side" onClick={() => { scrollToEnd(); }}><h1>Load More</h1></a>
                    </div>

                </>
            )}
        </>
    );
};

export default AllOpenQuestions;
