import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./assests/DashTable.css";
import { useParams } from "react-router-dom";
import DashTable from "./components/DashTable";
import { getChilderTopics, deleteTopicsApi } from "./../../services/api/TopicsApi";
import Button from "../utils/reuseableComponent/Button";
import { BeatLoader } from "react-spinners";
import { FaRegEdit } from 'react-icons/fa';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md'
import BreadCrumbs from './components/BreadCrumbs';
const ChilderTopics = () => {
  const [tableData, setTableData] = useState([]);
  const [parent, setParent] = useState("");
  const params = useParams();
  const TopicsID = params.id;
  const [tracker, setTracker] = useState(true);
  const [viewClick, setViewClick] = useState(false);
  const [callingComponent, setCallingComponent] = useState(false);
  const [statsCalling, setStatsCalling] = useState(false);
  const [navigationLoad, setNavigationLoad] = useState(false);

  async function getTopics() {
    try {
      const response = await getChilderTopics(TopicsID);
      setTableData(response.data.children);
      setParent(response.data.name);
      setTracker(false);
      if (callingComponent) {
        const response = await getChilderTopics();
        let tableLength = tableData.length
        for (let i = 0; i <= tableLength; ++i) {
          tableData.pop()
        }
        setTableData([...tableData, ...response.data.results]);
        setCallingComponent(false)
      }
    } catch (err) {
      console.log("Error is ", err);
    }
  }
  useEffect(() => {
    getTopics();
  }, [callingComponent]);

  function tittleTurncate(description, number) {
    return description?.split(" ").splice(0, number).join(" ");
  }

  const [tableHead, setTableHead] = useState([
    {
      title: "Name",
      render: (rowData) => {
        return (
          <span>{`${tittleTurncate(rowData.name, 3)}...`}</span>
        );
      },
      className: "dash-ques-title",
    },
    {
      title: "عنوان",
      render: (rowData) => {
        return (
          <span>{`... ${tittleTurncate(rowData.name_ur, 3)}`}</span>
        );
      },
      className: "dash-ques-title urdu-language font-urdu",
    },
    {
      title: "Description",
      render: (rowData) => {
        return <span>{`${tittleTurncate(rowData.description, 2)}...`}</span>
      },
      className: "description",
    },

    {
      title: "Display",
      render: (rowData) => {
        return <span>{rowData.is_visible_frontend ? "Yes" : "No"}</span>;
      },
      className: "text-fornt-display",
    },
    {
      title: "Status",
      render: (rowData) => {
        return (
          <span
            className={`status ${rowData.is_active ? "status-active" : "status-inactive"
              } `}
          >
            {rowData.is_active ? "Active" : "Inactive"}
          </span>
        );
      },
      className: "text-center",
    },
    {
      title: "Action",
      render: (rowData) => {
        return (
          <>
            {/* <Link to={`/admin/edit-topics/${rowData.id}`}>
              <Button icon={<FaRegEdit />} className="Edit-Button" />
            </Link> */}
          </>
        );
      },
      className: `action-view none-class `,
    },
  ]);

  const [viewSection, setViewSection] = useState([
    {
      path: "/admin/edit-child-topics",
      buttonTitle: "Edit",
      Icon: <FaRegEdit />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      isDeleted: true,
      buttonTitle: "Delete",
      Icon: <MdDelete />,
      ColorClass: `delete-question `,
      isActive: true
    }
  ]);
  let QuestionTitle = parent;
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    {
      name: "Topics",
      path: "/admin/topic"
    },
    {
      name: `${QuestionTitle}`,
    },
  ])

  const pageName = "Topics Childerns"
  const topicChildClass = "none-div-child"
  const topicchildTable = true
  let messageData = "Topics Child"
  return (
    <>
      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>
          <div className="tables-breadcrums">
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} QuestionTitle={QuestionTitle} />
          </div>
          <div className="main-table-cotainer box-shadow">
            <DashTable
              tableData={tableData ? tableData : []}
              QuestionTitle={QuestionTitle}
              tableHead={tableHead}
              viewSection={viewSection}
              setViewClick={setViewClick}
              topicChildClass={topicChildClass}
              topicchildTable={topicchildTable}

              deletedApi={deleteTopicsApi}
              messageData={messageData}
              setCallingComponent={setCallingComponent}
              setStatsCalling={setStatsCalling}
              setNavigationLoad={setNavigationLoad}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ChilderTopics;
