import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./assests/DashTable.css";
import DashTable from "./components/DashTable";
import { getTopicsApi } from "./../../services/api/TopicsApi";
import moment from "moment";
import Button from "../utils/reuseableComponent/Button";
import { BeatLoader } from "react-spinners";
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md'
import { FaRegEdit } from 'react-icons/fa';
import BreadCrumbs from './components/BreadCrumbs';
const MuftiTopics = () => {
    const [searchDashQues, setSearchDashQues] = useState("");
    const [tableData, setTableData] = useState([]);
    const [tracker, setTracker] = useState(true);
    const [callingComponent, setCallingComponent] = useState(false);
    const [viewClick, setViewClick] = useState(false);

    async function getTopics() {
        try {
            const response = await getTopicsApi();
            console.log("Topic API ", response);
            setTableData(response.data);
            setTracker(false);
            console.log(response.data);
            if (callingComponent) {
                const response = await getTopicsApi();
                let tableLength = tableData.length
                for (let i = 0; i <= tableLength; ++i) {
                    tableData.pop()
                }
                setTableData([...tableData, ...response.data.results]);
                console.log("tableData =>", tableData)
                console.log("response ", response)
                setCallingComponent(false)
            }
        } catch (err) {
            console.log("Error is ", err);
        }
    }
    useEffect(() => {
        getTopics();
    }, [callingComponent]);
    const [tableHead, setTableHead] = useState([
        {
            title: "Title",
            render: (rowData) => {
                return (
                    <Link to={`/admin/mufti-childerns-view/${rowData.id}`}>
                        <span>{rowData.name}</span>
                    </Link>
                );
            },
            className: "dash-ques-title",
        },
        {
            title: "Childs",
            render: (rowData) => {
                return (
                    <Link to={`/admin/mufti-childerns-view/${rowData.id}`}>
                        <span>{rowData.children.length}</span>
                    </Link>
                );
            },

            className: "text-center",
        },
        {
            title: "Date",
            render: (rowData) => {
                return <span>{moment(rowData.created_at).format("MM/DD/YYYY")}</span>;
            },
        },
        {
            title: "View",
            render: (rowData) => {
                return (
                    <>
                        {/* <Link to={`/admin/mufti-childerns-view/${rowData.id}`}>
                        <Button title="View" />
                    </Link> */}
                    </>
                );
            },
            className: "action-view",
        },
    ]);

    const [viewSection, setViewSection] = useState([
        {
            path: "/admin/mufti-childerns-view",
            buttonTitle: "View Details",
            Icon: <BsEye />,
            ColorClass: "View-detail-page",
            isActive: true
        },
    ]);
    const questionPath = "/admin/childerns-view/"

    const [breadcrum, setBreadcrum] = useState([
        {
            name: "Dashboard",
            path: "/admin"
        },
        { name: "Topics ", },
    ])
    const pageName = "Topics"
    const QuestionTitle = "Topics Table"
    return (

        <>

            {tracker ? (
                <div className="dash-preloader">
                    <BeatLoader
                        type="ThreeDots"
                        color="rgb(195, 156, 51)"
                        size="20px"
                        margin="3px"
                    />
                </div>
            ) : (
                <>
                    <div className="tables-breadcrums">
                        <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
                    </div>
                    <div className="main-table-cotainer box-shadow">
                        <div className="dash-table-top-bar">
                            <div className="search-side">
                                <h4>{QuestionTitle}</h4>
                            </div>
                        </div>
                        <DashTable
                            tableData={tableData ? tableData : []}
                            QuestionTitle={QuestionTitle}
                            tableHead={tableHead}

                            setViewClick={setViewClick}
                            viewSection={viewSection}
                            setCallingComponent={setCallingComponent}
                            questionPath={questionPath}
                        />
                    </div>
                </>
            )}

        </>
    );
};

export default MuftiTopics;
