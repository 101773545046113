import React from "react";
import BreadCrumbs from "./components/BreadCrumbs";
import QuestionEditPageAdmin from "./QuestionEditPageAdmin";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const AssignedEditQuestionPage = () => {

    const params = useParams();
    const questionID = params.id;

    const Link = useLocation();

    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: "Assigned Question",
                path: "/admin/questions/assigned-question",
            },
            // {
            //     name: "Question Details",
            //     path: `/admin/assigned-question-view/${questionID}`
            // },
            { name: "Edit Questions", },
        ]

    const pageName = "Edit Questions"
    return (
        <>
            <BreadCrumbs
                breadcrum={breadcrum}
                pageName={pageName}
                linkPage={Link?.state?.editPageLink}
            />
            <QuestionEditPageAdmin idOfQuestion={questionID} />
        </>
    );
};
export default AssignedEditQuestionPage;
