import React, { useEffect, useState } from "react";
import BreadCrumbs from "./components/BreadCrumbs";
import MuftiEditUserProfile from "./MuftiEditUserProfile";
import { useParams } from 'react-router-dom';
const UserEdit = () => {
    const params = useParams();
    const userID = params.id;
    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: "User",
                path: "/admin/user"
            },
            {
                name: "User Profile",
                path: `/admin/user-dashtable/${userID}`
            },
            {
                name: "User Edit",
            },
        ]

    const pageName = "Edit User Profile"
    return (
        <>
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
            <MuftiEditUserProfile />
        </>
    );
};
export default UserEdit;
