import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import moment from "moment";
import { Link } from "react-router-dom";
import "./QuestionTable.css";
import { convertFromRaw } from "draft-js";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./QuestionTableUr.css";
import { BeatLoader } from "react-spinners";
const QuestionsTable = ({ data, noQuestionsFound, loaderQuestionCall }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  function QuestionDescription(description) {
    let plainText = convertFromRaw(JSON.parse(description)).getPlainText();
    return plainText.split(" ").splice(0, 20).join(" ");
  }
  console.log("Questions Data", data);
  return (
    <>
      <div className="questions">
        <table>
          <thead>
            <tr>
              <th className="questoin-heading">{t("Questions")} </th>
              <th className="ten">{t("Views")} </th>
              <th className="ten">{t("Date")}</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && noQuestionsFound ? (
              <tr>
                <td className="question" data-label="Questions">
                  {t("No Questions Found")}
                </td>
              </tr>
            ) : (
              data.map((question, index) => {
                return (
                  <>
                    <tr
                      key={index}
                      onClick={() => {
                        history.push({
                          pathname: `/question-details/${question?.id}/${question?.slug}`,
                        });
                      }}
                    >
                      <td className="question" data-label="Questions">
                        <Link
                          to={`/question-details/${question?.id}/${question?.slug}`}
                        >
                          {localStorage.getItem("language") == "ur"
                            ? question?.title_ur
                            : question?.title_en}
                        </Link>
                        <p className="short-description">
                          {`${QuestionDescription(
                            localStorage.getItem("language") == "ur"
                              ? question?.description_ur
                              : question?.description
                          )}...`}
                        </p>
                      </td>
                      <td className="ten" data-label="Views">
                        <span>{question.views_count}</span>
                      </td>
                      <td className="ten" data-label="Date">
                        {moment(question.answer[0].approved_date).format("MM/DD/YYYY")}
                      </td>
                    </tr>
                    <div className="mobile-questions-detail">
                      <div className="view-date-question">
                        <h6>Date : </h6>
                        <span>
                          {moment(question.answer[0].approved_date).format("MM/DD/YYYY")}
                        </span>
                      </div>
                      <div className="view-date-question">
                        <h6>View : </h6>
                        <span>{question.views_count}</span>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="loader-section">
        {loaderQuestionCall ? (
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="15px"
            margin="3px"
          />
        ) : null}
      </div>
    </>
  );
};

export default QuestionsTable;
