import React, { useState } from 'react';
import './Contact.css';
import Contact_img from '../../../images/contact.png';
import Input from '../reuseableComponent/Input';
import { useTranslation } from 'react-i18next';
import { postContactApi } from '../../../services/api/ContactApi';
import RequiredToolTip from '../../Dashboard/components/RequiredToolTip';
import { AiOutlineInfo } from "react-icons/ai";
import validator from 'validator';
const Contact = () => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactMessage, setContactMessage] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [contactMessageError, setContactMessageError] = useState('');

  const [validNameError, setValidNameError] = useState("");
  const [validPhoneError, setValidPhoneError] = useState("");

  const [toolTip, setToolTip] = useState("");
  const [toolTipTrue, setToolTipTrue] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [validEmail, setvalidEmail] = useState(false);

  let language = localStorage.getItem('language');
  const onContactSubmit = async (e) => {
    e.preventDefault();
    try {
      // if (name) {
      //   if (localStorage.getItem("language") == "en" ? /[^a-zA-Z- ]/.test(name) : /[^ آ-ے]/.test(name)) {
      //     setValidNameError(localStorage.getItem("language") == "en" ? "Invalid Name" : "غلط نام")
      //     var validName = false
      //   } else {
      //     setValidNameError("")
      var validName = true
      //   }
      // }
      if (isNaN(contactPhone)) {
        setValidPhoneError("Invalid Phone Number")
        var validPhone = false
      } else {
        setValidPhoneError("")
        var validPhone = true
      }
      console.log("valid name", validName)
      console.log("valid phone", validPhone)
      if (name && email && contactMessage && validName && validPhone) {
        var data = {
          name: name,
          email: email,
          phone_number: contactPhone,
          descriptions: contactMessage,
        };
        const response = await postContactApi(data);
        // console.log("Contact Response ", response)
        setName("")
        setEmail("")
        setContactPhone("")
        setContactMessage("")
      } else {
        let LanguageConatct = localStorage.getItem("language")
        if (!name) setNameError(LanguageConatct == "en" ? "Name is required" : "نام کی ضرورت ہے")
        if (!email) setEmailError(LanguageConatct == "en" ? "Email is required" : "ای میل کی ضرورت ہے")
        if (!contactMessage) setContactMessageError(LanguageConatct == "en" ? "Description is required" : "تفصیل ضروری ہے")
        console.log("error")
      }
    } catch {
      console.log("error")
    }
  };
  let LanguageName = localStorage.getItem("language")
  const loadToolTip = () => {
    setToolTip(<RequiredToolTip urduFont={LanguageName == "en" ? null : "font-urdu"} Text={LanguageName == "en" ? "Feild is required" : "فیلڈ درکار ہے"} Direction={LanguageName == "en" ? "right" : "left"} icon="*" className="required-ttoltip " />)
    setToolTipTrue(true)
  }
  return (
    <div className="container">
      <div className="contact">
        <div className="contact-form">
          <h2>{t('Contact')}</h2>
          <form onSubmit={onContactSubmit}>
            <label>{t('Full Name')} <p className="toolTip-star" onMouseEnter={loadToolTip} >{toolTipTrue ? toolTip : "*"}</p></label>
            <Input
              type="text"
              placeholder={t('Enter Your Full Name')}
              tooltipActive="true"
              onChange={(e) => {
                if (name != null) {
                  setName(e.target.value)
                  setNameError("")
                  setValidNameError("")
                }
              }}
              value={name}
            />

            {nameError || validNameError ? <p className="error">{nameError}{validNameError}</p> : null}
            <label>{t('Email Address')} <p className="toolTip-star" onMouseEnter={loadToolTip} >{toolTipTrue ? toolTip : "*"}</p></label>
            <Input
              type="email"
              placeholder={t('Enter Your Email Address')}
              tooltipActive="true"
              value={email}
              onChange={(e) => {
                if (validator.isEmail(e.target.value)) {
                  setEmailError('Valid Email :)');
                  setvalidEmail(true)
                  setEmailValid(true)
                } else {
                  setEmailError('Enter valid Email!');
                  setvalidEmail(false)
                  setEmailValid(false)
                }
                setEmail(e.target.value);
              }}
            />
            <p className={validEmail ? "email-valid" : "error"}>{emailError}</p>
            <label>{t('Phone Number')} <RequiredToolTip urduFont={LanguageName == "en" ? null : "font-urdu"} Text={LanguageName == "en" ? "Input the number without space between and - " : "جگہ نہ دیں اور - نمبر کے درمیان"} Direction={LanguageName == "en" ? "right" : "left"} icon={<AiOutlineInfo />} className="information-ttoltip" /></label>
            <Input
              type="number"
              min={"0"}
              onkeypress={(e) => {
                const re = /^[0-9\b]+$/;
                // if value is not blank, then test the regex
                if (e.target.value === '' || re.test(e.target.value)) {
                  setContactPhone(e.target.value)
                }
              }

              }
              ondrop={false}
              onpaste={false}
              placeholder={t('Enter Your Phone Number')}
              onChange={(e) => {
                const validation = /^[0-9\b]+$/;
                if (contactPhone != null && e.target.value.length <= 12) {
                  setContactPhone(e.target.value)
                } else {
                  return
                }
              }}
              value={contactPhone}
            />
            {validPhoneError ? <p className="error">{validPhoneError}</p> : null}
            <label>{t('Message')} <p className="toolTip-star" onMouseEnter={loadToolTip} >{toolTipTrue ? toolTip : "*"}</p></label>
            <textarea
              placeholder={t('Write Your Message')}
              onChange={(e) => {
                if (contactMessage != null) {
                  setContactMessage(e.target.value)
                  setContactMessageError("")
                }
              }}
              value={contactMessage}
            ></textarea>
            {contactMessageError ? <p className="error">{contactMessageError}</p> : null}
            <button type="submit" className="blue-btn">
              {t('Submit')}
            </button>
          </form>
        </div>
        <div className="contact-img">
          <img src={Contact_img} alt="Contact Image" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
