import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

export const ProtectedRoutes = ({ children, isAuthenticated, ...rest }) => {
  //   let isAuthenticated = localStorage.getItem("isAuthenticated");
  //   console.log("isAuthenticated", isAuthenticated);
  var location = useLocation();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        // return isAuthenticated ? children : <Redirect to="/login" />;
        return localStorage.getItem("loggedin") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
