import axiosApi from "../axios-config/axios";
export const postContactApi = async (ContactData) => {
    try {
        const { data, status } = await axiosApi({
            endpoint: `contact/`,
            method: "POST",
            bodyData: ContactData,
        });
        return { data, status };
    } catch (error) {
        console.log(error);
    }
};