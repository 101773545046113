import React from "react";
import Button from "../utils/reuseableComponent/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
const WelcomePopUp = (props) => {
  const changeCheckIn = () => {
    localStorage.setItem("CheckIn", true);
    props.onHide();
  };
  localStorage.setItem("CheckIn", false);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="welcome-modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body className="welcome">
        <img src={Logo} alt="" />
        <h1>Welcome To Ask Hidayah</h1>
        <p>
          Ask Hidayah is a miltilingual online global platform designed to cater
          to the evergrowing need of the daily life questions and queries with
          respect to the islamic injunctions and islamic jurisprudence. At Ask
          Hidayah, with the blessings of Allah SWT and under the guidance of
          Quran, Sunnah, Ijma & Qiyas, your queries are responded to, in both
          Urdu and English.
        </p>
        <p>
          Questions that are received at Ask Hidayah are responded under the
          able guidance of Muftis who are Expert in various disciples of Islamic
          Jurisprudence. Ask Hidayah is being managed under the umbrella of
          Hidayah Academy. Hidayah Academy is an Islamic Education Institute
          which caters to the need of individuals who are busy in their
          professional lives and they also desire to learn the deen. Under the
          umbrella of Jamia Hidayah both men & women can attain the blessed Noor
          of Uloom e Nabawiyyah (Prophetic Knowledge) in flexible timings.
        </p>
        <p>
          With a team of qualified individuals and experts in different Islamic
          disciplines this institution not only imparts Islamic knowledge and
          skills but also focuses on the spiritual training of its students.
          Alhamdulilah, Jamia Hidayah has campuses in 4 cities in Pakistan and
          have established network portals for online students in 27 countries
          of the world. By the Grace of Allah this Institute has grown
          enormously in a short span of 15 years.
        </p>
      </Modal.Body>
      <Modal.Footer className="welcome-footer">
        <Link to="ask-question">
          <Button
            onClick={changeCheckIn}
            title="Ask Hidayah"
            className="blue-btn"
          />
        </Link>
        <Button
          onClick={changeCheckIn}
          title="Visit Hidayah"
          className="blue-btn"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomePopUp;
