import React, { useState, useEffect } from 'react';
import { ReactMic } from 'react-mic';
import Button from './Button';
import '../../../App.css';
import { BsFillMicFill } from 'react-icons/bs';
import { BsFillMicMuteFill } from 'react-icons/bs';

const AudioRecorder = ({ recordUrl, setRecordUrl }) => {
  const [record, setRecord] = useState(false);

  const [second, setSecond] = useState('00');
  const [minute, setMinute] = useState('00');
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function stopTimer() {
    setIsActive(false);
    setCounter(0);
    setSecond('00');
    setMinute('00');
  }
  function startRecording() {
    setRecord(true);
    setIsActive(true);
  }
  function stopRecording() {
    setRecord(false);
    stopTimer();
  }

  function onData(recordedBlob) {}

  function onStop(recordedBlob) {
    setRecordUrl((data) => [...data, recordedBlob]);
  }
  return (
    <div className="voice-recorder">
      <div className="recorder">
        <ReactMic
          record={record}
          className="sound-wave"
          onStop={onStop}
          onData={onData}
          strokeColor="#f00"
          backgroundColor="#fff"
          mimeType="audio/mp3"
        />
        {record ? (
          <Button
            onClick={stopRecording}
            type="button"
            id="RecordEnd"
            title={<BsFillMicMuteFill />}
          />
        ) : (
          <Button
            onClick={startRecording}
            type="button"
            title={<BsFillMicFill />}
            id="RecordStart"
          />
        )}
        {record ? (
          <div className="time">
            <span className="minute">{minute}</span>
            <span>:</span>
            <span className="second">{second}</span>
          </div>
        ) : null}
      </div>

      <div className="wrap-recorder">
        {recordUrl.map((recoderUrl, index) => (
          <>
            <audio key={index} controls>
              <source src={recoderUrl.blobURL} type="audio/mp3" />
            </audio>
          </>
        ))}
      </div>
    </div>
  );
};

export default AudioRecorder;
