import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import ReviewAnswer from "./ReviewAnswer";
import DashboardReagainAnswer from "./DashboardReagainAnswer";
import "./assests/DashboardQuestionDetailPage.css";
import { getQuestionsDetailsApi, QuestionsRefrencesApi } from "../../services/api/QuestionsApi";
import { trackPromise } from "react-promise-tracker";
import { BeatLoader } from "react-spinners";

const ReviewAnswerDetailPage = ({
  Accept,
  isRequiredComment,
  isRejected,
  marginComments,
  reAgain,
  nonClass,
  onlyAdminEdit,
  setNavigationLoad
}) => {
  const params = useParams();
  const questionID = params.id;

  console.log(" nonClass # 1", nonClass)
  const [Question, setQuestion] = useState({});
  const [Answer, setAnswer] = useState([]);
  const [tracker, setTracker] = useState(true);
  const [questionDetailID, setQuestionDetailID] = useState(true);
  const [imageCondition, setImageCondition] = useState(false);
  const [audioCondition, setAudioCondition] = useState(false);
  const [answerID, setAnswerID] = useState();
  const [comments, setComments] = useState();
  const [refrences, setRefrences] = useState([]);
  const [refrencesId, setRefrencesId] = useState("");
  const [commentsBy, setCommentsBy] = useState("");



  async function getQuestionsDetails() {
    try {
      const response = await getQuestionsDetailsApi({ id: questionID });
      console.log("Assigned Question ", response);
      setQuestion(response?.data);
      setAnswerID(response?.data?.answer[0]?.id);
      setAnswer(response?.data?.answer);
      setQuestionDetailID(questionID);
      let commentsLength = response?.data?.answer[0]?.comments.length
      console.log(" commentsLength ", commentsLength)
      setComments(response?.data?.answer[0]?.comments[commentsLength - 1]?.description);
      setCommentsBy(response?.data?.answer[0]?.reviewed_by_name_en)

      setTracker(false)
      for (let i = 0; i < response.data.media.length; ++i) {
        console.log("Enter Conditions");
        if (response.data.media[i].type == "AUDIO") {
          setAudioCondition(true);
        } else if (response.data.media[i].type == "IMAGE") {
          setImageCondition(true);
        }
      }
      const responseRefrences = await QuestionsRefrencesApi(response.data.id);
      console.log("Refrences Question ", responseRefrences)
      setRefrences(responseRefrences)
      setRefrencesId(responseRefrences.data.results[0].id)
      console.log("first name", responseRefrences.data.results[0].id)
    } catch (error) {
      console.log("Error", error);
    }
  }
  const pageBreadCurms = useLocation();
  useEffect(() => {
    trackPromise(getQuestionsDetails());
    console.log("  pageTableLink  # 1", pageBreadCurms?.state?.pageTableLink);
    console.log("  pageTableName  # 1", pageBreadCurms?.state?.pageTableName);
    console.log("  previousPage   # 1", pageBreadCurms?.state?.previousPage);
  }, []);
  return (
    <>
      {tracker ? (
        <div className="question-detail-loader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>

          <div>
            {Question && (
              <ReviewAnswer
                Question={Question}
                Answer={Answer}
                imageCondition={imageCondition}
                audioCondition={audioCondition}
                refrences={refrences}
                reAgain={reAgain}
                onlyAdminEdit={onlyAdminEdit}
                setNavigationLoad={setNavigationLoad}
              />
            )}
          </div>
          <div className="wrap-comments-Again-Answer">
            <>
              <>
                {Question && (
                  <DashboardReagainAnswer
                    Question={Question}
                    Answer={Answer}
                    answerID={answerID}
                    comments={comments}
                    refrencesId={refrencesId}
                    commentsBy={commentsBy}
                    Accept={Accept}
                    isRequiredComment={isRequiredComment}
                    isRejected={isRejected}
                    reAgain={reAgain}
                    marginComments={marginComments}
                    nonClass={nonClass}
                    setNavigationLoad={setNavigationLoad}
                  />
                )}
              </>
            </>
          </div>
        </>
      )}
    </>
  );
};
export default ReviewAnswerDetailPage;
