import React, { useEffect } from "react";
import BreadCrumbs from "./components/BreadCrumbs";
import ReviewAnswerDetailPage from "./ReviewAnswerDetailPage";
import { useParams } from 'react-router-dom';
const RejectAnswerReview = ({ setNavigationLoad }) => {

    const params = useParams();
    const questionID = params.id;
    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: "Rejected Answer",
                path: "/admin/questions/rejected-questions",
            },
            { name: "Answer Detail Page", },
        ]

    const pageName = "Answer Detail Page"
    const Accept = true
    const isRequiredComment = true
    const marginComments = "marginComments"
    const reAgain = true
    return (
        <>
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
            <ReviewAnswerDetailPage
                Accept={Accept}
                isRequiredComment={isRequiredComment}
                marginComments={marginComments}
                reAgain={reAgain}
                setNavigationLoad={setNavigationLoad}
            />
        </>
    );
};
export default RejectAnswerReview;
