import React, { useState, useEffect } from "react";
import Input from "../reuseableComponent/Input";
import Transcript from "../../../images/transcript.png";
import Pdf from "../../../images/download.png";
import AudioRecorder from "../reuseableComponent/AudioRecorder";
import Button from "../reuseableComponent/Button";
import { AiOutlineInfo } from "react-icons/ai";
import "./AskQuestion.css";
import "../../../App.css";
import SubmitAskQuestionModul from "./SubmitAskQuestionModul";
import { getPublicTopicsApi } from "./../../../services/api/TopicsApi";
import {
  postQuestionsApi,
  postImagesApi,
  postAudioApi,
} from '../../../services/api/QuestionsApi';
import { BiUpload } from 'react-icons/bi';
import { BiCloudUpload } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import ImagePopUpModul from './ImagePopUpModul';
import validator from 'validator';
import TextEditor from './../reuseableComponent/TextEditor/TextEditor';
import { EditorState, convertToRaw } from 'draft-js';
import { useTranslation } from 'react-i18next';
import RequiredToolTip from "../../Dashboard/components/RequiredToolTip";

const AskQuestion = () => {
  const { t, i18n } = useTranslation();
  const [questionCity, setQuestionCity] = useState("");
  const [questionCountry, setQuestionCountry] = useState("");
  const [questionTitle, setQuestionTitle] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [topicId, setTopicId] = useState('');
  const [topics, setTopics] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [topicError, setTopicError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [descriptionError, setDescriptionError] = useState("");
  const [askQuestionSubmit, setaskQuestionSubmit] = useState(false);
  const [questionID, setQuestionID] = useState();
  const [imageUpload, setImageUpload] = useState([]);
  const [openImage, setOpenImage] = useState(false);
  const [popURL, setPopURL] = useState("");
  const [imagesList, setImagesList] = useState([]);
  const [recordUrl, setRecordUrl] = useState([]);
  const [content, setContent] = useState("");
  const [checkPrivate, setCheckPrivate] = useState(true);
  const [validEmail, setvalidEmail] = useState(false);
  const [validNameError, setValidNameError] = useState("");
  const [invalidImageUpload, setInvalidImageUpload] = useState("");
  const [editQuestionEnglish, setEditQuestionEnglish] = useState("");
  const [editable, setEditable] = useState(false);
  const [toolTip, setToolTip] = useState("");
  const [toolTipTrue, setToolTipTrue] = useState(false);
  const notdisplay = {
    display: 'block'
  };
  const types = [
    "image/png",
    "image/jpeg",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
  ];
  useEffect(() => {
    getTopics();
  }, []);

  async function getTopics() {
    try {
      const response = await getPublicTopicsApi();
      setTopics(response.data);
      console.log("Topics Response => ", topics);
    } catch (error) { }
  }
  const uploadMultipleFiles = (e) => {
    console.log("Files ", e.target.files)
    const imageSaveArray = []
    for (let i = 0; i < e.target.files.length; ++i) {
      for (let j = 0; j <= types.length; ++j) {
        if (e.target.files[i].type == types[j]) {
          let iamgeFile = e.target.files[i]
          imageSaveArray.push(iamgeFile);
        } else {
          setInvalidImageUpload("Invalid Image Extension")
        }
      }
    }
    console.log("Image Save", imageSaveArray)
    for (let i = 0; i < imageSaveArray.length; i++) {
      console.log("Convert Image", imageSaveArray[i])
      let imageURLCreater = {
        URL: URL.createObjectURL(imageSaveArray[i]),
        Type: imageSaveArray[i].type,
        Name: imageSaveArray[i].name,
      };
      console.log("imageURLCreater", imageURLCreater)
      setImageUpload((data) => [...data, imageURLCreater]);
      imagesList.push(imageSaveArray[i]);
      setContent("");
    }
  };
  console.log("topicId ", topicId)
  console.log("imageUpload", imageUpload)
  async function handleSubmit(e) {
    e.preventDefault();

    // if (localStorage.getItem("language") == "en" ? /[^a-zA-Z- ]/.test(name) : /[^ آ-ے]/.test(name)) {
    //   setValidNameError(localStorage.getItem("language") == "en" ? "Invalid Name" : "غلط نام")
    //   var validName = false
    // } else {
    //   setValidNameError("")
    var validName = true
    // }
    if (
      topicId &&
      questionTitle &&
      editQuestionEnglish &&
      name &&
      email &&
      topicId != "Select the topic" &&
      emailValid &&
      validName &&
      questionCity &&
      questionCountry
    ) {
      try {
        if (localStorage.getItem("language") == "en") {
          let editorData =
            editorContent && JSON.stringify(convertToRaw(editorContent));
          var data = {
            title: questionTitle,
            title_en: questionTitle,
            title_ur: "",
            description_en: editorData,
            is_visible: checkPrivate,
            is_active: true,
            email: email,
            name: name,
            topic: topicId,
          };
          console.log("Input English API")
        } else {
          let editorData =
            editorContent && JSON.stringify(convertToRaw(editorContent));
          var data = {
            title_ur: questionTitle,
            title: questionTitle,
            title_en: "",
            description_ur: editorData,
            is_visible: checkPrivate,
            is_active: true,
            email: email,
            name: name,
            topic: topicId,
          };
          console.log("Input Urdu API")
        }
        data.q_city = questionCity;
        data.q_country = questionCountry;
        const response = await postQuestionsApi(data);
        console.log("post data ", (response.data.description))
        const result = JSON.parse(response.data.description)
        console.log("Result ", result)
        const text = result.blocks[0].text;
        console.log("Text ", text)
        setQuestionID(response && response.data && response.data.id);
        setaskQuestionSubmit(true);
        localStorage.setItem("updateTopics", true);

        let imageAttachments = await Promise.all(
          imagesList.map(async (image, index) => {
            let form_data = new FormData();
            form_data.append("question", response.data.id);
            form_data.append("type", "IMAGE");
            form_data.append("order", index);
            form_data.append("file", image);
            const responseImageApi = await postImagesApi(form_data);
            console.log("responseImageApi =>", responseImageApi);
            return responseImageApi;
          })
        );

        let audioAttachments = await Promise.all(
          recordUrl.map(async (audio, index) => {
            let form_data = new FormData();
            form_data.append("question", response.data.id);
            form_data.append("type", "AUDIO");
            form_data.append("order", index);
            form_data.append("file", audio.blob);
            const responseAudioApi = await postAudioApi(form_data);
            return responseAudioApi;
          })
        );
      } catch (err) {
        const { response } = err;
      }
    } else {
      if (!topicId || topicId == "Select the topic")
        setTopicError(localStorage.getItem("language") == "en" ? "Please select Topic" : "برائے کرم عنوان منتخب کریں");
      if (!questionTitle) setTitleError(localStorage.getItem("language") == "en" ? "Title is required" : "برائے کرم عنوان منتخب کریں");
      if (!editQuestionEnglish) setDescriptionError(localStorage.getItem("language") == "en" ? "Please Enter description" : "برائے کرم عنوان منتخب کریں");
      if (!name) setNameError(localStorage.getItem("language") == "en" ? "Please Enter Name" : "برائے کرم عنوان منتخب کریں");
      if (!email) setEmailError(localStorage.getItem("language") == "en" ? "Please Enter Email" : "برائے کرم عنوان منتخب کریں");
      if (!questionCity) { setCityError("Please Enter city of residence.") } else setCityError("")
      if (!questionCountry) { setCountryError("Please Enter country of residence.") } else setCountryError("")
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
  const deleteImage = (url, index, Name) => {
    const imageFilter = imageUpload.filter((imageUrl) => imageUrl.URL != url);
    setImageUpload(imageFilter);
    console.log("1 => ", imagesList);
    const imageDeleteFilter = imagesList.filter((item) => item.name != Name);
    setImagesList(imageDeleteFilter);
  };
  let LanguageName = localStorage.getItem("language")
  const loadToolTip = () => {
    setToolTip(<RequiredToolTip urduFont={LanguageName == "en" ? null : "font-urdu"} Text={LanguageName == "en" ? "Feild is required" : "فیلڈ درکار ہے"} Direction={LanguageName == "en" ? "right" : "left"} icon="*" className="required-ttoltip " />)
    setToolTipTrue(true)
  }

  function truncate(str, no_words) {
    return str.split("").splice(0, no_words).join(" ");
  }

  return (
    <section className="container">
      <div className="row">
        <div className="col-md-9">
          <div className="ask-question main">
            <form onSubmit={handleSubmit} >
              <h2>{t('Ask Questions')} </h2>
              <div className="main-input-feild">
                <div className="wrap-divs">
                  <h3 className="ask-title">{t('Name')}<p className="toolTip-star" onMouseEnter={loadToolTip} >{toolTipTrue ? toolTip : "*"}</p></h3>
                  <div className="row">
                    <div className="col-md-11">
                      <Input
                        type="text"
                        name="questionTitle"
                        placeholder={t('Enter Your Name')}
                        value={name}
                        onChange={(e) => {
                          if (name != null) {
                            setNameError('');
                            setValidNameError("")
                          }
                          setName(e.target.value);
                        }}
                      />
                      <p className="error">{nameError}{validNameError}</p>

                    </div>
                  </div>
                </div>
                <div className="wrap-divs">
                  <h3 className="ask-title">{t('Email')}<p className="toolTip-star" onMouseEnter={loadToolTip} >{toolTipTrue ? toolTip : "*"}</p></h3>
                  <div className="row">
                    <div className="col-md-11">
                      <Input
                        type="text"
                        placeholder={t('Enter Your Email Address')}
                        value={email}
                        onChange={(e) => {
                          if (validator.isEmail(e.target.value)) {
                            setEmailError('Valid Email :)');
                            setvalidEmail(true)
                            setEmailValid(true)
                          } else {
                            setEmailError('Enter valid Email!');
                            setvalidEmail(false)
                            setEmailValid(false)
                          }
                          setEmail(e.target.value);
                        }}
                      />
                      <p className={validEmail ? "email-valid" : "error"}>{emailError}</p>
                    </div>
                  </div>
                </div>
                <div className="wrap-divs">
                  <h3 className="ask-title">{t('Title')}<p className="toolTip-star" onMouseEnter={loadToolTip} >{toolTipTrue ? toolTip : "*"}</p></h3>

                  <div className="row">
                    <div className="col-md-11">
                      <Input
                        type="text"
                        name="questionTitle"
                        placeholder={t('Enter Your Question Title')}
                        value={questionTitle}
                        onChange={(e) => {

                          setQuestionTitle(e.target.value);
                        }}
                      />
                      <p className="error">{titleError}</p>
                    </div>
                  </div>
                </div>

                <div className="wrap-divs">
                  <h3 className="ask-title">{t('Topics')}<p className="toolTip-star" onMouseEnter={loadToolTip} >{toolTipTrue ? toolTip : "*"}</p></h3>
                  <div className="row">
                    <div className="col-md-11">
                      <select
                        onChange={(e) => {
                          if (topicId != null) {
                            setTopicError('');
                          }
                          setTopicId(e.target.value);
                        }}
                        className="topic-dropdown"
                      >
                        <option>{t('Select the topic')}</option>
                        {topics &&
                          topics.map((topic) =>
                            topic.children.map((child, index) => (
                              <option key={index} value={child.id}>
                                {localStorage.getItem("language") == "en" ? child.name_en : child.name_ur}
                              </option>
                            ))
                          )}
                      </select>
                      {console.log('topics', topics)}
                      <p className="error">{topicError}</p>
                    </div>
                  </div>
                </div>
                <div className="wrap-divs " style={notdisplay}>
                  <h3 className="ask-title">{t('City of Residence')}<p className="toolTip-star" onMouseEnter={loadToolTip} >{toolTipTrue ? toolTip : "*"}</p></h3>

                  <div className="row">
                    <div className="col-md-11">
                      <Input
                        type="text"
                        name="city"
                        placeholder={t('City of Residence')}
                        value={questionCity}
                        onChange={(e) => {

                          setQuestionCity(e.target.value);
                        }}
                      />
                      <p className="error">{cityError}</p>
                    </div>

                  </div>
                </div>
                <div className="wrap-divs " style={notdisplay}>
                  <h3 className="ask-title">{t('Country of Residence')}<p className="toolTip-star" onMouseEnter={loadToolTip} >{toolTipTrue ? toolTip : "*"}</p></h3>

                  <div className="row">
                    <div className="col-md-11">
                      <Input
                        type="text"
                        name="country"
                        placeholder={t('Country of Residence')}
                        value={questionCountry}
                        onChange={(e) => {

                          setQuestionCountry(e.target.value);
                        }}
                      />
                      <p className="error">{countryError}</p>
                    </div>

                  </div>
                </div>
              </div>

              <h3 className="ask-title">
                {t('Write your question in detail')} <p className="toolTip-star" onMouseEnter={loadToolTip} >{toolTipTrue ? toolTip : "*"}</p>
              </h3>
              <TextEditor sendToEditor={setEditorContent} validationForEmpty={setEditQuestionEnglish} isEdit={setEditable} />
              {!editQuestionEnglish && descriptionError ?
                <p className="error">{descriptionError}</p>
                : null
              }
              <h3 className="ask-title">{t('Upload Files and Images')} <RequiredToolTip Text={LanguageName == "en" ? "Upload your image in PNG,JPEG,TXT,DOC and  PDF formalte only" : "اپنی تصویر صرف PNG ، JPEG ، TXT ، DOC اور PDF رسمی شکل میں اپ لوڈ کریں"} Direction={LanguageName == "en" ? "right" : "left"} icon={<AiOutlineInfo />} className="information-ttoltip" /></h3>
              <h4>{t('Please upload the image here')} </h4>
              <div className="upload-file">
                <label>
                  <BiCloudUpload />
                  <label className="file-upload-btn">
                    <BiUpload />
                    {t('Upload')}
                    <Input
                      type="file"
                      name="fileUpload"
                      placeholder="Upload"
                      multiple="multiple"
                      onChange={uploadMultipleFiles}
                      value={content}
                    />
                  </label>
                </label>
              </div>
              <div className="uploadedImage">
                <div className="prev-img-wrapper">
                  {(imageUpload || []).map((item, index) => {
                    return item.Type == "image/png" ||
                      item.Type == "image/jpeg" ? (
                      <div key={index} className="previewImageBox">
                        <div className="previewImage">
                          <img
                            onClick={() => {
                              setOpenImage(true);
                              setPopURL(item.URL);
                            }}
                            src={item.URL}
                            alt="..."
                            className="img-fluid"
                          />
                          <ImagePopUpModul
                            show={openImage}
                            onHide={() => setOpenImage(false)}
                            Url={popURL}
                          />
                        </div>
                        <div
                          className="del-btn"
                          onClick={() =>
                            deleteImage(item.URL, index, item.Name)
                          }
                        >
                          <MdDeleteForever />
                        </div>
                      </div>
                    ) : (
                      <div key={index} className="previewImageBox">
                        <div className="previewImage">
                          <img src={Pdf} />
                          <p>{`${truncate(item.Name, 5)}...`}</p>
                        </div>
                        <div
                          className="del-btn"
                          onClick={() =>
                            deleteImage(item.URL, index, item.Name)
                          }
                        >
                          <MdDeleteForever />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {invalidImageUpload ? <p className="error">{invalidImageUpload}</p> : null}
              <h3 className="ask-title">{t('Record Your Question')}</h3>

              <AudioRecorder
                setRecordUrl={setRecordUrl}
                recordUrl={recordUrl}
              />
              <div className="checkBox">
                <div className="checkBox-wrap">
                  <input type="checkbox" onClick={() => setCheckPrivate(!checkPrivate)} />
                  <p>{t('Post question privately')} </p>
                </div>
                <span className="danger">
                  {t('If you leave the checkbox your question will be public')}
                </span>
              </div>
              <Button
                type="submit"
                className="blue-btn"
                title={t('Submit')}
                id="SubmitQuestion"
              />
              <SubmitAskQuestionModul
                questionid={questionID}
                show={askQuestionSubmit}
                onHide={() => setaskQuestionSubmit(false)}
              />
            </form>
          </div>
        </div>

        <div className="col-md-3">
          <div className="main">
            <div className="transcript">
              <h3>{t('How to upload transcript')}</h3>
              <p>
                {t(
                  'Do not crop out any part of the transcript image. Avoid unclear or blurred image of your prescription. and visit date.will only be dispensed against a valid transcript.'
                )}
              </p>
              <img src={Transcript} alt="Transcript" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AskQuestion;
