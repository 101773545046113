import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Button from "../utils/reuseableComponent/Button";
import RowDashTable from "./components/RowDashTable";
import "./assests/MuftiUserProfile.css";
import PopUpMessage from "./components/PopUpMessage";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import BreadCrumbs from "./components/BreadCrumbs";
import {
  getUsersDetailApi,
  deleteUsersDetailApi,
} from "./../../services/api/UsersApi";
const MuftiUserProfile = () => {


  const [userStatus, setuserStatus] = useState("");
  const [username, setUsername] = useState("");
  const [tableData, setTableData] = useState([]);
  const [message, setMessage] = useState("");
  const [messageAlert, setMessageAlert] = useState(false);
  const [chooseButtonCondtion, setChooseButtonCondtion] = useState("");
  const [tracker, setTracker] = useState(true);
  const params = useParams();
  const userId = params.id;
  console.log("userId => ", userId);
  async function getUser() {
    try {
      const response = await getUsersDetailApi({ id: userId });
      console.log("response =>", response);
      setChooseButtonCondtion(response?.data?.is_active);
      setTableData([...tableData, response.data]);
      setUsername(response.data.username);
      setTracker(false);
    } catch (error) {
      console.log("Error", error);
    }
  }
  useEffect(() => {
    getUser();
  }, []);
  const [header, setHeader] = useState([
    "Email Address",
    "Name",
    "Status",
    "Staff status",
    "Date",
    "Admin",
  ]);

  const [tableHead, setTableHead] = useState([
    {
      title: "Email Address",
      render: (rowData) => {
        return <span>{rowData.email}</span>;
      },
      className: "dash-ques-title",
    },
    {
      title: "Name",
      render: (rowData) => {
        return <span>{rowData.username}</span>;
      },
    },
    {
      title: "Status",
      render: (rowData) => {
        return <span>{rowData.is_active ? "Active" : "Inactive"}</span>;
      },
    },
    {
      title: "Staff Member",
      render: (rowData) => {
        return <span>{rowData.is_staff ? "Yes" : "No"}</span>;
      },
    },
    {
      title: "Administrator",
      render: (rowData) => {
        return <span>{rowData.is_staff ? "No" : "Yes"}</span>;
      },
    },
    {
      title: "Created Date",
      render: (rowData) => {
        return <span>{moment(rowData.date_joined).format("MM/DD/YYYY")}</span>;
      },
    },
  ]);
  const QuestionTitle = "User Profile";
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (userStatus == "Delete") {
        const response = await deleteUsersDetailApi(userId);
        setMessageAlert(true);
        setMessage("User is deleted");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const breadcrum =
    [
      {
        name: "Dashboard",
        path: "/admin"
      },
      {
        name: "User",
        path: "/admin/user"
      },
      {
        name: "User Profile",
        path: `/admin/user-dashtable/${userId}`
      },
    ]

  const pageName = "User Profile"
  return (
    <>
      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>
          <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
          <div className="mufti-profile-user">
            <RowDashTable
              header={header}
              tableData={tableData}
              QuestionTitle={QuestionTitle}
              tableHead={tableHead}
            />
            <div className="row-dash-table-button">
              <form onSubmit={onSubmit}>
                <Link to={`/admin/user-edit/${userId}`}>
                  <Button title="Edit" />
                </Link>
                <Button
                  title="Delete"
                  className="delete-user-button"
                  onClick={() => setuserStatus("Delete")}
                />
              </form>
              <PopUpMessage
                LinkURL="/admin/user"
                message={message}
                show={messageAlert}
                onHide={() => setMessageAlert(false)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default MuftiUserProfile;
