import TextEditor from '../utils/reuseableComponent/TextEditor/TextEditor';
import React, { useState, useEffect } from 'react';
import ImagePopUpModul from '../utils/AskQuestion/ImagePopUpModul';
import '../Dashboard/assests/DashboardAnswerDetail.css';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { GrDocumentTxt } from 'react-icons/gr';
import { AiOutlineFileWord } from 'react-icons/ai';
import ConformationPopUpPage from './components/ConformationPopUpPage';
import Button from '../utils/reuseableComponent/Button';
import { deleteQuestionsApi } from '../../services/api/QuestionsApi';
import { useParams, useHistory, Link, useLocation } from 'react-router-dom';
import { MdDeleteForever } from 'react-icons/md';
import { RiEdit2Line } from 'react-icons/ri';


const ReviewAnswer = ({
  Question,
  Answer,
  imageCondition,
  audioCondition,
  refrences,
  reAgain,
  onlyAdminEdit,
  setNavigationLoad
}) => {
  let QuestionDescription = Question.description_en;
  let QuestionDescriptionUrdu = Question.description_ur;
  let AnswerLenghtEnglish = Answer.length
  let AnswerDescription = Answer && Answer[AnswerLenghtEnglish - 1] && Answer[AnswerLenghtEnglish - 1].description_en;
  let AnswerLenghtUrdu = Answer.length
  let AnswerDescriptionUrdu = Answer && Answer[AnswerLenghtUrdu - 1] && Answer[AnswerLenghtUrdu - 1].description_ur;
  let refrencesLength = refrences?.data?.results?.length
  let refrencesDescription = refrences && refrences?.data?.results[refrencesLength - 1]?.description
  const [imageUpload, setImageUpload] = useState([]);
  const [openImage, setOpenImage] = useState(false);
  const [conformationAlert, setConformationAlert] = useState(false);
  const [popURL, setPopURL] = useState('');
  const [pageTableLink, setPageTableLink] = useState();
  const [pageTableName, setPageTableName] = useState();
  const [previousPage, setPreviousPage] = useState();
  useEffect(() => {
    setImageUpload(Question.media);
    setPageTableLink(pageBreadCurms?.state?.pageTableLink);
    setPageTableName(pageBreadCurms?.state?.pageTableName);
    setPreviousPage(pageBreadCurms?.state?.previousPage)

    console.log("  pageTableLink  ", pageBreadCurms?.state?.pageTableLink);
    console.log("  pageTableName  ", pageBreadCurms?.state?.pageTableName);
    console.log("  previousPage   ", pageBreadCurms?.state?.previousPage);
  }, []);

  let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  const pageBreadCurms = useLocation();
  console.log("Location ", pageBreadCurms)
  let history = useHistory();
  let questionId = useParams();
  let ID = questionId.id;

  let Breadcurms = localStorage.getItem("Breadcurms")
  let breadcurmsPath = localStorage.getItem("breadcurmsPath")
  // console.log("Breadcurms", Breadcurms)
  localStorage.setItem("pathId", Question.id)
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    {
      name: Breadcurms,
      path: breadcurmsPath,
    },
    { name: "Questions Details", },
  ])
  const pageName = "Questions Details"
  return (
    <>
      <ConformationPopUpPage
        show={conformationAlert}
        onHide={() => setConformationAlert(false)}
        isDeletedID={ID}
        deletedApi={deleteQuestionsApi}
        setNavigationLoad={setNavigationLoad}
      />
      <section className="detail-question-bar main">
        <div className="row">
          <div className="col-md-12">
            <>
              <div className="question-answer-top">
                <p className="question-id">
                  Ledger Number : <span>{Question.ledger_number}</span>
                </p>

                <div className="more-feild-question">

                  <>
                    {isAdmin ?
                      <div className="delete-object" onClick={() => setConformationAlert(true)}><Button title="Delete" /><MdDeleteForever /></div>
                      : null}
                    {onlyAdminEdit ? null :
                      <Link to={{ pathname: `/admin/admin-edit-questions/${ID}`, state: { pageTableLink: pageTableLink, pageTableName: pageTableName, previousPage: previousPage }, }}>
                        <div className="edit-question"><Button title="Edit Question" /><RiEdit2Line /></div>
                      </Link>
                    }
                    {isAdmin ?
                      onlyAdminEdit
                        ?
                        <Link to={{ pathname: `/admin/admin-edit-questions/${ID}`, state: { pageTableLink: pageTableLink, pageTableName: pageTableName, previousPage: previousPage }, }}>
                          <div className="edit-question"><Button title="Edit Question" /><RiEdit2Line /></div>
                        </Link>
                        :
                        null

                      : null
                    }
                  </>
                </div>
              </div>

              <div className="div-sepration"></div>

              <div className="question-title ">
                <h2 className="title bold-haeding">Title : </h2>
                <p className="para">{Question.title}</p>
              </div>
              <div className="question-question">
                <h2 className="title bold-haeding">Question: </h2>
                <TextEditor description={QuestionDescription} />
              </div>
              {AnswerDescription === undefined ? (
                <p className="para">Not yet answered</p>
              ) : (
                <div className="question-question">
                  <h2 className="title bold-haeding">Answer: </h2>
                  <div className="answer-description">
                    <TextEditor description={AnswerDescription} />
                  </div>
                </div>
              )}
              <div className="div-sepration"></div>

              <div className="question-title urdu-language font-urdu">
                <h2 className="title bold-haeding font-urdu">: عنوان</h2>
                <p className="para urdu-font">{Question.title_ur}</p>
              </div>

              <div className="question-question urdu-language">
                <h2 className="title bold-haeding font-urdu">: سوال</h2>
                <div class="urdu-font">
                  <TextEditor description={QuestionDescriptionUrdu} />
                </div>
              </div>
              {AnswerDescriptionUrdu === undefined ? (
                <p className="para">Not yet answered</p>
              ) : (
                <div className="question-question urdu-language">
                  <h2 className="title bold-haeding font-urdu">: جواب</h2>
                  <div class="urdu-font">
                    <TextEditor description={AnswerDescriptionUrdu} />
                  </div>
                </div>
              )}
              <div className="div-sepration"></div>
              {AnswerDescription === undefined ? (
                <p className="para">Not yet answered</p>
              ) : (
                <div className="question-question">
                  <h2 className="title bold-haeding">Refrences : </h2>
                  <div className="answer-description refrences-section">
                    <TextEditor description={refrencesDescription} />
                  </div>
                </div>
              )}
              {reAgain ? <div className="div-sepration"></div> : null}
            </>
          </div>
        </div>
        {imageCondition && audioCondition ?
          <div className="wrap-image-record">
            {imageCondition ? (
              <div className="wrap-image-attatchments">
                <div className="attatchments-heading">
                  <h4>Attatchments</h4>
                </div>
                <div className="image-attatchments">
                  {imageUpload.map((item, index) => {
                    return item.file.split('.').pop() == 'jpg' ||
                      item.file.split('.').pop() == 'png' ? (
                      <div key={index} className="previewImageBox">
                        <div className="previewImage">
                          <img
                            onClick={() => {
                              setOpenImage(true);
                              setPopURL(item.file);
                            }}
                            src={item.file}
                            alt="..."
                            className="img-fluid"
                          />
                          <ImagePopUpModul
                            show={openImage}
                            onHide={() => setOpenImage(false)}
                            Url={popURL}
                          />
                        </div>
                      </div>
                    ) : item.file.split('.').pop() == 'pdf' ||
                      item.file.split('.').pop() == 'docx' ||
                      item.file.split('.').pop() == 'txt' ? (
                      <>
                        <div key={index} className="previewImageBox">
                          <div className="previewImage-Documents">
                            {item.file.split('.').pop() == 'pdf' ? (
                              <AiOutlineFilePdf />
                            ) : item.file.split('.').pop() == 'txt' ? (
                              <GrDocumentTxt />
                            ) : (
                              <AiOutlineFileWord />
                            )}
                            <p>{item.file.split('.').pop()} Document</p>
                            <a
                              href={item.file}
                              className="attach-download-btn"
                              download
                              className="Document-button"
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </>
                    ) : null;
                  })}
                </div>
              </div>
            ) : null}
            {audioCondition ? (
              <div className="record-attatchments">
                <div className="attatchments-heading">
                  <h4>Recording</h4>
                </div>
                <div className="audio-attatchments">
                  {imageUpload.map((audio, index) => {
                    return (
                      audio.type == 'AUDIO' && (
                        <audio key={index} src={audio.file} controls />
                      )
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
          : null
        }
      </section>
    </>
  );
};
export default ReviewAnswer;
