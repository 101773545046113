import React, { useState } from "react";
import Input from "../../utils/reuseableComponent/Input";
import "../assests/RowDashTable.css";
const RowDashTable = ({ tableHead, tableData, QuestionTitle }) => {
  const [searchDashQues, setSearchDashQues] = useState("");
  return (
    <div className="table-wrapper box-shadow">
      <div className="dash-table-top-bar">
        <div className="search-side">
          <h4>{QuestionTitle}</h4>
        </div>
      </div>
      <div className="row-dash-table table-responsive">
        <table>
          <tr>
            {tableHead.map((headerItem, index) => (
              <th key={index}>{headerItem.title}</th>
            ))}
          </tr>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={index}>
                {tableHead.map((col, key) => (
                  <td className={col.className} key={key}>
                    {col.render(item)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RowDashTable;
