import React, { useState } from "react";
import "../assests/DashTable.css";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";
import { emailPasswordChange } from "../../../services/api/PasswordChangeApi";
import ConformationPopUp from "./ConformationPopUp";
import { useHistory } from "react-router-dom";
import Input from "../../utils/reuseableComponent/Input";
import { patchQuestionsApi } from "../../../services/api/QuestionsApi";
const DashTable = ({
  showAcctionCommponent,
  tableHead,
  tableData,
  setMessageAlert,
  noQuestionsFound,
  noUserFound,
  viewSection,
  setMessage,
  setViewClick,
  deletedApi,
  messageData,
  questionPath,
  setCallingComponent,
  topicChildClass,
  topicchildTable,
  topicsChildNone,
  pageLink,
  pageBreadcrum,
  EditQuestionPage,
  pageTableName,
  pageTableLink,
  previousPage,
  setNavigationLoad,
  setStatsCalling,
  updatingFunction,
  allStatus,
}) => {
  const [showAction, setShowAction] = useState(false);
  const [countNumber, setCountNumber] = useState(null);
  const [conformationAlert, setConformationAlert] = useState(false);
  const [conformationMessage, setConformationMessage] = useState(messageData);
  const [isDeletedID, setIsDeletedID] = useState(null);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isVisible, setIsVisible] = useState(false);


  function handleStatusChange(e, index) {


    console.log(updatingFunction(e, index));
    /*
     let selected = e.target.value;
let selectedIndex = 0;
Localoptions.filter((value, index) => {
  if (value == selected) {
    selectedIndex = index;
  }
})
setQuestionProgress(selectedIndex + 1);
*/

  }
  const changePasswordEmail = async (id) => {
    let userId = id;
    console.log("User Id", userId);
    const response = await emailPasswordChange(userId);
    console.log("reset", response);
    if (response.status == "201") {
      setMessageAlert(true);
      setMessage("Reset password email has been sent successfully");
    }
  };

  const toglefeature = async (questionId) => {
    setIsFeatured(!isFeatured);
    const data = {
      is_featured: !isFeatured,
    };
    const response = await patchQuestionsApi(data, questionId);
    console.log("response ", response);
  };

  const togleVisiable = async (questionId) => {
    setIsVisible(!isVisible);
    const data = {
      is_visible: !isVisible,
    };
    console.log("setIsVisible ", isVisible);
    const response = await patchQuestionsApi(data, questionId);
    console.log("response ", response);
  };

  const history = useHistory();
  const setDelete = async (id) => {
    try {
      await setIsDeletedID(id);
      setConformationAlert(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <ConformationPopUp
        message={conformationMessage}
        show={conformationAlert}
        onHide={() => setConformationAlert(false)}
        isDeletedID={isDeletedID}
        deletedApi={deletedApi}
        setCallingComponent={setCallingComponent}
        setShowAction={setShowAction}
        setNavigationLoad={setNavigationLoad}
      />
      {showAction ? (
        <div className="overlay" onClick={() => setShowAction(false)}></div>
      ) : null}
      <div className="table-wrapper ">
        <div className="dash-table table-responsive">
          <table className="table">
            <thead>
              <tr>
                {tableHead.map((headerItem, index) => {
                  return <th key={index}>{headerItem.title}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {noQuestionsFound ? (
                <div className="not-found">No Questions found </div>
              ) : noUserFound ? (
                <div className="not-found">No User found</div>
              ) : (
                tableData &&
                tableData.map((item, index) => {
                  item.handleStatusChange = handleStatusChange;
                  item.localOptions = allStatus;
                  return (
                    <tr key={index}>
                      {tableHead.map((col, key) => (
                        <>
                          {showAction ?
                            <td
                              className={`${col.className} ${topicChildClass}`}
                              key={key}

                              onClick={() => {
                                history.push({
                                  pathname: `${questionPath}${item.id}`,
                                  state: {
                                    linkPage: pageLink,
                                    Breadcrum: pageBreadcrum,
                                    editPageLink: EditQuestionPage,
                                    pageTableName: pageTableName,
                                    pageTableLink: pageTableLink,
                                    previousPage: previousPage,
                                  },
                                });
                              }}
                            >
                              {col.render(item, index)}
                            </td> : <td
                              className={`${col.className} ${topicChildClass}`}
                              key={key}
                            >
                              {

                                col.render(item, index)
                              }

                            </td>}
                          {topicchildTable ? (
                            <td className={`${col.className} `} key={key}>
                              {col.render(item)}
                            </td>
                          ) : null}
                        </>
                      ))}
                      {showAcctionCommponent != false &&
                        <span className={`view-button ${topicsChildNone}`}>
                          <BiDotsHorizontalRounded
                            className="Action-menu"
                            onClick={() => {
                              setShowAction(!showAction);
                              setCountNumber(index);
                              setViewClick(true);
                              setIsFeatured(item.is_featured);
                              setIsVisible(item.is_visible);
                              setStatsCalling(true);
                              console.log("updated feacture", item.is_featured);
                            }}
                          />
                        </span>
                      }
                      {showAction && countNumber == index ? (
                        <>
                          <div className="Action-Section">
                            {viewSection.map((viewItem, index) => {
                              return (
                                <>
                                  {viewItem.isActive ? (
                                    viewItem.isResetPassword ? (
                                      <div
                                        className="Action-Div"
                                        onClick={() =>
                                          changePasswordEmail(item.id)
                                        }
                                      >
                                        <Link>
                                          <p className={viewItem.ColorClass}>
                                            {viewItem.Icon}
                                          </p>{" "}
                                          <p className={viewItem.ColorClass}>
                                            {viewItem.buttonTitle}
                                          </p>
                                        </Link>
                                      </div>
                                    ) : viewItem.isFeature ? (
                                      <div
                                        className="Action-Div checkBox-section"
                                        onClick={() => toglefeature(item.id)}
                                      >
                                        <p className={viewItem.ColorClass}></p>
                                        &nbsp;{" "}
                                        {isFeatured ? (
                                          <Input type="checkbox" checked />
                                        ) : (
                                          <Input type="checkbox" />
                                        )}{" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <p className={viewItem.ColorClass}>
                                          {viewItem.buttonTitle}
                                        </p>
                                      </div>
                                    ) : viewItem.isVisiable ? (
                                      <div
                                        className="Action-Div checkBox-section"
                                        onClick={() => togleVisiable(item.id)}
                                      >
                                        <p className={viewItem.ColorClass}></p>
                                        &nbsp;{" "}
                                        {isVisible ? (
                                          <Input type="checkbox" checked />
                                        ) : (
                                          <Input type="checkbox" />
                                        )}{" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <p className={viewItem.ColorClass}>
                                          {viewItem.buttonTitle}
                                        </p>
                                      </div>
                                    ) : viewItem.isDeleted ? (
                                      <div
                                        className="Action-Div"
                                        onClick={() => setDelete(item.id)}
                                      >
                                        <Link>
                                          <p className={viewItem.ColorClass}>
                                            {viewItem.Icon}
                                          </p>
                                          <p className={viewItem.ColorClass}>
                                            {viewItem.buttonTitle}
                                          </p>
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className="Action-Div">
                                        <Link
                                          to={{
                                            pathname: `${viewItem.path}/${item.id}`,
                                            state: {
                                              linkPage: pageLink,
                                              Breadcrum: pageBreadcrum,
                                              editPageLink: EditQuestionPage,
                                              pageTableName: pageTableName,
                                              pageTableLink: pageTableLink,
                                              previousPage: previousPage,
                                            },
                                          }}
                                        >
                                          <p className={viewItem.ColorClass}>
                                            {viewItem.Icon}
                                          </p>{" "}
                                          <p className={viewItem.ColorClass}>
                                            {viewItem.buttonTitle}
                                          </p>
                                        </Link>
                                      </div>
                                    )
                                  ) : null}
                                </>
                              );
                            })}
                          </div>
                        </>
                      ) : null}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DashTable;
