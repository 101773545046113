import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardQuestionDetail from "./DashboardQuestionDetail";
import DashboardAnswerDetail from "./DashboardAnswerDetail";
import "./assests/DashboardQuestionDetailPage.css";
import { getQuestionsDetailsApi } from "../../services/api/QuestionsApi";
import { trackPromise } from "react-promise-tracker";
import { BeatLoader } from "react-spinners";
import { useLocation } from 'react-router-dom';
const DashboardQuestionDetailPage = ({ isAssigned, pageBreadcrum, pageLink, previousLink, setNavigationLoad }) => {
  const params = useParams();
  const questionID = params.id;

  const Link = useLocation();
  const [Question, setQuestion] = useState({});
  const [Answer, setAnswer] = useState([]);
  const [tracker, setTracker] = useState(true);
  const [questionDetailID, setQuestionDetailID] = useState(true);
  const [imageCondition, setImageCondition] = useState(false);
  const [audioCondition, setAudioCondition] = useState(false);
  const [checkCondition, setCheckCondition] = useState(false);
  const [editQuestion, setEditQuestion] = useState(false);
  const [editQuestionUrdu, setEditQuestionUrdu] = useState(false);
  const [englishEditTitle, setEnglishEditTitle] = useState("");
  const [urduEditTitle, setUrduEditTitle] = useState("");
  const [callUseEffact, setCallUseEffact] = useState(false);
  const [urduValidation, setUrduValidation] = useState("");
  const [englishValidation, setEnglishValidation] = useState("");

  const [errorEditQuestion, setErrorEditQuestion] = useState("");
  const [errorEditQuestionUrdu, setErrorEditQuestionUrdu] = useState("");
  const [errorEnglishEditTitle, setErrorEnglishEditTitle] = useState("");
  const [errorUrduEditTitle, setErrorUrduEditTitle] = useState("");
  const [editLink, setEditLink] = useState("");

  const [isAccepted, setIsAccepted] = useState(localStorage.getItem('assignedQuestion'));

  async function getQuestionsDetails() {
    try {
      const response = await getQuestionsDetailsApi({ id: questionID });
      console.log("Response Data", response)
      setQuestion(response.data);
      setEnglishEditTitle(response.data.title_en)
      setUrduEditTitle(response.data.title_ur)
      setUrduValidation(response.data.description_ur)
      setEnglishValidation(response.data.description_en)


      console.log(" urduValidation ", urduValidation)
      console.log(" englishValidation ", englishValidation)



      setCheckCondition(response.data.media);
      console.log("media ", response.data.media)
      for (let i = 0; i < response.data.media.length; ++i) {
        if (response.data.media[i].type == "AUDIO") {
          setAudioCondition(true);
        } else if (response.data.media[i].type == "IMAGE") {
          setImageCondition(true);
        }
      }
      setAnswer(response.data.answer);
      setQuestionDetailID(questionID);
      setTracker(false)
    } catch (error) {
      console.log("Error", error);
    }
  }

  useEffect(() => {
    trackPromise(getQuestionsDetails());
    setEditLink(Link?.state?.editPageLink)
  }, [callUseEffact]);
  console.log("enter re-again", Link?.state?.editPageLink)
  return (
    <>
      {tracker ? (
        <div className="question-detail-loader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <div>
          {Question && (
            <DashboardQuestionDetail
              Question={Question}
              editQuestion={editQuestion}
              editQuestionUrdu={editQuestionUrdu}
              englishEditTitle={englishEditTitle}
              urduEditTitle={urduEditTitle}
              errorEditQuestionUrdu={errorEditQuestionUrdu}
              errorEditQuestion={errorEditQuestion}
              errorEnglishEditTitle={errorEnglishEditTitle}
              errorUrduEditTitle={errorUrduEditTitle}
              setEnglishEditTitle={setEnglishEditTitle}
              setUrduEditTitle={setUrduEditTitle}
              setCallUseEffact={setCallUseEffact}
              setEditQuestion={setEditQuestion}
              setEditQuestionUrdu={setEditQuestionUrdu}
              setErrorEditQuestionUrdu={setErrorEditQuestionUrdu}
              setErrorEditQuestion={setErrorEditQuestion}
              setErrorEnglishEditTitle={setErrorEnglishEditTitle}
              setErrorUrduEditTitle={setErrorUrduEditTitle}
              questionID={questionID}
              isAccepted={isAccepted}
              pageLink={pageLink}
              pageBreadcrum={pageBreadcrum}
              previousLink={previousLink}
              editPageLink={editLink}
              setNavigationLoad={setNavigationLoad}
            />
          )}
          {Question && (
            <DashboardAnswerDetail
              Question={Question}
              imageCondition={imageCondition}
              audioCondition={audioCondition}
              urduEditTitle={urduEditTitle}
              englishEditTitle={englishEditTitle}
              urduValidation={urduValidation}
              englishValidation={englishValidation}

              setErrorEditQuestionUrdu={setErrorEditQuestionUrdu}
              setErrorEditQuestion={setErrorEditQuestion}
              setErrorEnglishEditTitle={setErrorEnglishEditTitle}
              setErrorUrduEditTitle={setErrorUrduEditTitle}
              setIsAccepted={setIsAccepted}

              isAssigned={isAssigned}
              setNavigationLoad={setNavigationLoad}
            />
          )}
        </div>
      )}
    </>
  );
};
export default DashboardQuestionDetailPage;
