import React, { useState, useEffect } from 'react';
import Button from '../utils/reuseableComponent/Button';
import TextEditor from '../utils/reuseableComponent/TextEditor/TextEditor';
import { patchQuestionsApi } from './../../services/api/QuestionsApi';
import { convertToRaw } from 'draft-js';
import { Link, useParams, useHistory } from 'react-router-dom';
import RequiredToolTip from './components/RequiredToolTip';
import { AiOutlineWarning } from 'react-icons/ai';
import { ImCancelCircle } from 'react-icons/im';
import { MdDeleteForever } from 'react-icons/md';
import { RiEdit2Line } from 'react-icons/ri';
import Input from '../utils/reuseableComponent/Input';
import { deleteQuestionsApi } from '../../services/api/QuestionsApi';
import BreadCrumbs from './components/BreadCrumbs';
import ConformationPopUpPage from './components/ConformationPopUpPage';
const DashboardQuestionDetail = ({
  Question,
  setEditQuestion,
  editQuestion,
  setEditQuestionUrdu,
  editQuestionUrdu,
  englishEditTitle,
  urduEditTitle,
  setEnglishEditTitle,
  setUrduEditTitle,
  setCallUseEffact,
  errorEditQuestionUrdu,
  errorEditQuestion,
  errorEnglishEditTitle,
  errorUrduEditTitle,
  setErrorEditQuestionUrdu,
  setErrorEditQuestion,
  setErrorEnglishEditTitle,
  setErrorUrduEditTitle,
  isAccepted,
  pageBreadcrum,
  pageLink,
  setNavigationLoad,
  editPageLink
}) => {

  const [editorContent, setEditorContent] = useState('');
  const [editorContentUrdu, setEditorContentUrdu] = useState('');
  const [editTitleEnglish, setEditTitleEnglish] = useState(false);
  const [editTitleUrdu, setEditTitleUrdu] = useState(false);
  const [warning, setWarning] = useState(true);
  const [conformationAlert, setConformationAlert] = useState(false);
  let history = useHistory();
  let questionId = useParams();
  let ID = questionId.id;

  useEffect(() => {
  }, []);


  console.log("editPageLink ", editPageLink)

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setCallUseEffact(false);
      let editorData =
        editorContent && JSON.stringify(convertToRaw(editorContent));
      let answeredbBy = localStorage.getItem('UserLoginID');
      const data = {
        title: Question.title,
        topic: Question.topic,
        description_en: editorData,
      };
      const response = await patchQuestionsApi(data, ID);
      if (response.status == '200') {
        setCallUseEffact(true);
        setEditQuestion(false);
        setErrorEditQuestion('');
      }
    } catch (err) {
      console.log('Error is', err);
    }
  }

  async function handleSubmitUrdu(e) {
    e.preventDefault();
    try {
      setCallUseEffact(false);
      console.log('Urdu Question');
      let editorData =
        editorContentUrdu && JSON.stringify(convertToRaw(editorContentUrdu));
      let answeredbBy = localStorage.getItem('UserLoginID');
      console.log('Urdu Question Edit ', editorData);
      const data = {
        title: Question.title,
        topic: Question.topic,
        description_ur: editorData,
      };
      const response = await patchQuestionsApi(data, ID);
      console.log('Urdu Question Edition APi', response);
      if (response.status == '200') {
        setCallUseEffact(true);
        setEditQuestionUrdu(false);
        setErrorEditQuestionUrdu('');
      }
    } catch (err) {
      console.log('Error is', err);
    }
  }
  async function handleSubmitEnglishTitle(e) {
    try {
      setCallUseEffact(false);
      e.preventDefault();
      const data = {
        title_en: englishEditTitle,
      };
      const response = await patchQuestionsApi(data, ID);
      if (response.status == '200') {
        setCallUseEffact(true);
        setEditTitleEnglish(false);
      }
    } catch (err) {
      console.log('Error is', err);
    }
  }
  async function handleSubmitUrduTitle(e) {
    e.preventDefault();
    try {
      setCallUseEffact(false);
      const data = {
        title_ur: urduEditTitle,
      };
      const response = await patchQuestionsApi(data, ID);
      if (response.status == '200') {
        setCallUseEffact(true);
        setEditTitleUrdu(false);
      }
    } catch (err) {
      console.log('Error is', err);
    }
  }

  let QuestionDescription = Question.description_en;
  let QuestionDescriptionUrdu = Question.description_ur;
  const loadingStopOnSubmit = (e) => {
    e.preventDefault();
  };
  let Breadcurms = localStorage.getItem("Breadcurms")
  let breadcurmsPath = localStorage.getItem("breadcurmsPath")
  console.log("Breadcurms", Breadcurms)
  localStorage.setItem("pathId", Question.id)
  console.log("pageBreadcrum ", pageBreadcrum)
  console.log("pageLink ", pageLink)
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    {
      name: pageBreadcrum,
      path: pageLink,
    },
    { name: "Questions Details", },
  ])
  const pageName = "Questions Details"
  localStorage.setItem("isDirect", false)
  console.log('isisAccepted', isAccepted)

  let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  return (
    <>
      <ConformationPopUpPage
        show={conformationAlert}
        onHide={() => setConformationAlert(false)}
        isDeletedID={ID}
        deletedApi={deleteQuestionsApi}
        setConformationAlert={setConformationAlert}
        setNavigationLoad={setNavigationLoad}

      />
      <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
      {warning ?
        <div className="waring-question">
          <div className="warning-text"> <AiOutlineWarning /><p>Fill the question's section in English and Urdu Both to fill other wise the answer will not submited</p></div><div><ImCancelCircle className="cross-question-warning" onClick={() => setWarning(false)} /></div>
        </div>
        : null
      }
      <section className={`detail-question-bar ${isAccepted ? "radius-active" : null}`}>
        <div className="row">
          <div className="col-md-12">
            <>
              <div className="question-answer-top">
                {Question.category && Question.category[0].breadcrum ? (
                  <p className="bread-crumb">{Question.category[0].breadcrum}</p>
                ) : null}
                <p className="question-id">
                  Ledger Number : <span>{Question.ledger_number}</span>
                </p>
                <div className="more-feild-question">
                  {isAdmin ?
                    <div className="delete-object" onClick={() => setConformationAlert(true)}><Button title="Delete" /><MdDeleteForever /></div>
                    : null
                  }
                  <Link to={{ pathname: `${editPageLink}${ID}`, state: { editPageLink: editPageLink }, }}>
                    <div className="edit-question"><Button title="Edit" /><RiEdit2Line /></div>
                  </Link>
                </div>
              </div>

              <div className="div-sepration"></div>


              {///////city country ////
              }
              <div className="">
                <h2 className="title bold-haeding">City of Residence :</h2>  <span className="para">{Question.q_city || Question.q_city != "NULL" || Question.q_city != "" ? Question.q_city : "NA"
                }</span>
              </div>
              <div className="">
                <h2 className="title bold-haeding">Country of Residence :</h2> <span className="para">{Question.q_country ? Question.q_country : "NA"
                }</span>
              </div>
              {/////////
              }
              <div className="question-title">
                <h2 className="title bold-haeding">Title : {Question.title_en ? null :
                  <RequiredToolTip
                    Text="Feild Required"
                    Direction="right"
                    icon="*"
                    className="required-ttoltip"
                  />

                }</h2>
                {editTitleEnglish ? null : Question.title_en ? (
                  <span className="para">{Question.title_en} </span>
                ) : (
                  null)}
                {editTitleEnglish ? (
                  <div className="wrap-text-Title">
                    <form onSubmit={handleSubmitEnglishTitle}>
                      <Input
                        type="text"
                        placeholder={'Enter Your Title'}
                        value={englishEditTitle}
                        onChange={(e) => {
                          setEnglishEditTitle(e.target.value);
                          if (!englishEditTitle) {
                            setErrorEnglishEditTitle('');
                          }
                        }}
                      />
                      <Button title="Edit" type="submit" />
                    </form>
                  </div>
                ) : null}
              </div>
              <p className="error">{errorEnglishEditTitle}</p>
              <div className="question-question">
                <h2 className="title bold-haeding">Question :
                  {QuestionDescription ? null : <RequiredToolTip
                    Text="Feild Required"
                    Direction="right"
                    icon="*"
                    className="required-ttoltip"
                  />}
                </h2>
                {editQuestion ? null : QuestionDescription ? (
                  <div class="answer-description">
                    <TextEditor description={QuestionDescription} />
                  </div>
                ) : (
                  null
                )}
              </div>
              <p className="error">{errorEditQuestion}</p>
              {editQuestion ? (
                <div className="wrap-text-editor">
                  <form onSubmit={handleSubmit}>
                    <TextEditor sendToEditor={setEditorContent} />
                    <div className="text-editor-submit-button">
                      <Button title="Submit" type="submit" />
                    </div>
                  </form>
                </div>
              ) : null}

              <div className="div-sepration"></div>

              <div className="question-title urdu-language font-urdu">
                {editTitleUrdu ? (
                  <div className="wrap-text-Title">
                    <form onSubmit={handleSubmitUrduTitle}>
                      <Button title="ترمیم" type="submit" />
                      <Input
                        type="text"
                        placeholder={'اپنا عنوان درج کریں'}
                        value={urduEditTitle}
                        onChange={(e) => {
                          setUrduEditTitle(e.target.value);
                          if (!urduEditTitle) {
                            setErrorUrduEditTitle('');
                          }
                        }}
                      />
                    </form>
                  </div>
                ) : null}
                <h2 className="title bold-haeding">
                  {Question.title_ur ? null :
                    <RequiredToolTip
                      Text="فیلڈ درکار ہے"
                      Direction="left"
                      icon="*"
                      className="required-ttoltip "
                      urduFont="font-urdu"
                    />
                  }
                  : عنوان
                </h2>
                {editTitleUrdu ? null : Question.title_ur ? (
                  <p className="para urdu-font">{Question.title_ur}</p>
                ) : null}
              </div>
              <p className="error urdu-language font-urdu">
                {errorUrduEditTitle}
              </p>
              <div className="question-question urdu-language font-urdu">
                <h2 className="title bold-haeding">
                  {QuestionDescriptionUrdu ? null :
                    <RequiredToolTip
                      Text="فیلڈ درکار ہے"
                      Direction="left"
                      icon="*"
                      className="required-ttoltip "
                      urduFont="font-urdu"
                    />}
                  : سوال
                </h2>
                <div className="urdu-font">
                  {editQuestionUrdu ? null : QuestionDescriptionUrdu ? (
                    <div className="font-urdu">
                      <TextEditor description={QuestionDescriptionUrdu} />
                    </div>
                  ) : null}
                </div>
              </div>
              <p className="error urdu-language font-urdu">
                {errorEditQuestionUrdu}
              </p>
              {editQuestionUrdu ? (
                <div className="wrap-text-editor font-urdu ">
                  <form onSubmit={handleSubmitUrdu}>
                    <TextEditor sendToEditor={setEditorContentUrdu} />
                    <div className="text-editor-submit-button">
                      <Button title="جمع کرائیں" type="submit" />
                    </div>
                  </form>
                </div>
              ) : null}
            </>
          </div>
        </div>
      </section>
    </>
  );
};
export default DashboardQuestionDetail;
