import React from "react";
import { Link } from 'react-router-dom';
const BreadCrumbs =
    ({
        breadcrum,
        pageName,
        QuestionTitle,
        linkPage,
        pageTableLink,
        pageTableName,
        previousPage,
    }) => {
        let lengthOfBreadcurms = breadcrum.length - 1
        return (
            <div>
                <h4 className="main-heading">{pageName}</h4>
                <div className="wrap-breadcurms">
                    {breadcrum.map((item, index) => {
                        return (
                            <>
                                {lengthOfBreadcurms === index ?
                                    <h6 className={lengthOfBreadcurms == 3 ? "page-breadcurms forth-index" : "page-breadcurms"} ><p>{item.name}{QuestionTitle ? `${QuestionTitle}` : null}</p></h6>
                                    :
                                    <h6 className="page-breadcurms" >
                                        <Link to={{ pathname: `${item.path}`, state: { editPageLink: linkPage, pageTableLink: pageTableLink, pageTableName: pageTableName, previousPage: previousPage }, }}>
                                            <p className="pointer"><span>{item.name}</span></p>
                                        </Link><span className="web-breaker">/</span> {index == 2 ? "" : <span className="mobile-breaker"> / </span>}
                                    </h6>
                                }
                            </>
                        )
                    })
                    }
                </div>
            </div >
        );
    };
export default BreadCrumbs;

