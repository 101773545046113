import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BreadCrumbs from "./components/BreadCrumbs";
import AnswerEditPageAdmin from "./AnswerEditPageAdmin";
import { useParams } from 'react-router-dom';

const EditAnswerDirectPage = () => {

    const [pageTableLink, setPageTableLink] = useState();
    const [pageTableName, setPageTableName] = useState();
    const [previousPage, setPreviousPage] = useState();
    const [reAgain, setReAgain] = useState();

    useEffect(() => {
        setPageTableLink(Link?.state?.pageTableLink);
        setPageTableName(Link?.state?.pageTableName);
        setPreviousPage(Link?.state?.previousPage)
        setReAgain(Link?.state?.reAgain)
    }, []);

    const Link = useLocation();
    const params = useParams();
    const questionID = params.id;
    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: pageTableName,
                path: pageTableLink,
            },
            {
                name: "Answer Deatil ",
                path: `${previousPage}${questionID}`
            },
            { name: "Edit Answer", },
        ]

    const pageName = "Edit Answer"
    return (
        <>
            <BreadCrumbs
                breadcrum={breadcrum}
                pageName={pageName}
                pageTableLink={pageTableLink}
                pageTableName={pageTableName}
                previousPage={previousPage}
            />
            <AnswerEditPageAdmin
                idOfQuestion={questionID}
                reAgain={reAgain}
            />
        </>
    );
};
export default EditAnswerDirectPage;
