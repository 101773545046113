import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import "../../responsive.css";
import logo from "../../images/logo.png";
import noti_icon from "../../images/notification.png";
import ham_icon from "../../images/hamburger-icon.png";
import MegaMenu from "./MegaMenu";
import { BsFillPersonFill } from "react-icons/bs";
import UserProfile from "../utils/userProfile/UserProfile";
import Hamburger from "hamburger-react";
import axios from "axios";
import { getPublicTopicsApi } from "./../../services/api/TopicsApi";
import MobileTopicMenu from "./MobileTopics";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isShown, setIsShown] = useState(false);
  const [topics, setTopics] = useState([]);
  const [mobileNav, setMobileNav] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [mobileTopicsOpen, setMobileTopicsOpen] = useState(false);

  const update = localStorage.getItem("updateTopics");

  const Logout = (e) => {
    e.preventDefault();
    localStorage.setItem("User", false);
    window.location.href = "/login";
  };

  async function getTopics() {
    try {
      const response = await getPublicTopicsApi();
      console.log("Public Topics APi ", response.data)
      setTopics(response.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  console.log("isOpen", isOpen)

  useEffect(() => {
    getTopics();
    localStorage.setItem("updateTopics", false);
  }, [update]);

  return (
    <>
      <header className="header" dir="ltr">
        <div className="container">
          <div className="logo" >
            <a href="https://www.hidayah.edu.pk" target="_blank">
              <img src={logo} alt="logo" />
            </a>
          </div>

          <div className="menu-bar">
            <div className="nav-links">
              <NavLink activeClassName="active" to="/home">
                {t("Questions")}
              </NavLink>
              <Link
                to="#"
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
                className="topic-link"
              >
                {t("Topics")}
                {isShown && topics && <MegaMenu topics={topics} />}
              </Link>
              <NavLink activeClassName="active" to="/about">
                {t("About")}
              </NavLink>
              <NavLink activeClassName="active" to="/contact">
                {t("Contact")}
              </NavLink>
            </div>

            <div className="header-login-noti">
              <div className="noti">
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-header">
        <div className="header-bar">
          <div className="logo">
            <Link to="/home">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div className="ham-icon" onClick={() => {
            setMobileNav(!mobileNav)
            setMobileTopicsOpen(false)
          }}>
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
        </div>
        <div className="mobile-nav-links" id={mobileNav ? "navActive" : ""}>
          <NavLink
            to="#"
            onClick={() => {
              setMobileTopicsOpen(!mobileTopicsOpen)
              setOpen(false)
            }}
          >
            {t("Topics")}
          </NavLink>
          {mobileTopicsOpen ? <MobileTopicMenu topics={topics} setMobileTopicsOpen={setMobileTopicsOpen} /> : null}
          <NavLink
            onClick={() => setMobileNav(!mobileNav)}
            activeClassName="active"
            to="/home"
          >
            {t("Questions")}
          </NavLink>
          <NavLink
            onClick={() => {
              setMobileNav(!mobileNav)
              setOpen(false)
            }}
            activeClassName="active"
            to="/about"
          >
            {t("About")}
          </NavLink>
          <NavLink
            onClick={() => {
              setOpen(false)
              setMobileNav(!mobileNav)
            }}
            activeClassName="active"
            to="/contact"
          >
            {t("Contact")}
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Header;
