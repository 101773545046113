import React, { useState, useEffect } from "react";
import Input from "../utils/reuseableComponent/Input";
import Button from "../utils/reuseableComponent/Button";
import {
  updateUsersDetailApi,
  getUsersDetailApi,
  deleteUsersDetailApi,
} from "./../../services/api/UsersApi";
import { useParams } from "react-router-dom";
import PopUpMessage from "./components/PopUpMessage";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import RequiredToolTip from "./components/RequiredToolTip";
import { AiOutlineInfo } from "react-icons/ai";
const MuftiEditUserProfile = () => {
  const params = useParams();
  const userId = params.id;
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [active, setActive] = useState("");
  const [userRole, setUserRole] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastNameUrdu, setLastNameUrdu] = useState('');
  const [lastName, setLastName] = useState("");
  const [prevPass, setPrevPass] = useState("");
  const [firstNameUrdu, setFirstNameUrdu] = useState('');

  const [emailError, setEmailError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [activeError, setActiveError] = useState("");
  const [userRoleError, setUserRoleError] = useState("");
  const [Message, setMessage] = useState("");
  const [deleteUser, setDeleteUser] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [tracker, setTracker] = useState(true);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [lastNameUrduError, setLastNameUrduError] = useState('');
  const [firstNameUrduError, setFirstNameUrduError] = useState('');

  let history = useHistory();
  const Back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  async function getUser() {
    try {
      const response = await getUsersDetailApi({ id: userId });
      console.log("User Data =>", response?.data);
      setEmail(response?.data?.email);
      setUserName(response?.data?.username);
      setActive(response?.data?.is_active);
      setFirstName(response?.data?.first_name);
      setLastName(response?.data?.last_name);
      if (response?.data?.is_staff) {
        setUserRole("true");
      } else {
        setUserRole("false");
      }
    } catch (error) {
      console.log("Error", error);
    }
    setTracker(false);
  }
  useEffect(() => {
    getUser();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (deleteUser) {
        console.log("enter delete");
        const response = await deleteUsersDetailApi(userId);
        setMessageAlert(true);
        setMessage("Your User Is Deleted");
      } else {
        if (
          email &&
          active &&
          userName &&
          userRole &&
          firstName &&
          lastName &&
          userRole !== "Select the status" &&
          active !== "Select the option"
        ) {
          console.log("user-role => ", userRole);
          if (userRole == "true") {
            var staff = true;
            var superStaff = false;
          } else if (userRole == "false") {
            var staff = false;
            var superStaff = true;
          }
          let userUpdateData = {
            email: email,
            username: userName,
            is_active: active,
            is_staff: staff,
            is_superuser: superStaff,
            first_name: firstName,
            last_name: lastName,
          };
          const response = await updateUsersDetailApi(userUpdateData, userId);
          if (response.status == "201" || response.status == "200") {
            setMessageAlert(true);
            setMessage("User Edited Succesfully");
          }
          console.log("response => ", response);
        } else {
          if (!email) setEmailError("Email is required");
          if (!userName) setUserNameError("Username is required");
          if (!firstName) setFirstNameError("Username is required");
          if (!lastName) setLastNameError("Username is required");
          if (!userRole || userRole == "Select the option")
            setUserRoleError("User role is required");
          if (!active || active == "Select the option")
            setActiveError("User status is required");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }

      }
    } catch (err) {
      const { response } = err;
      console.log("error");
    }
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="wrap-grand-mufti main grand-mufti-user-create">
          <div class="language-dedication">
            <h4>
              Edit User{' '}
              <RequiredToolTip
                Text="Use this form to create the staff member or administrator"
                Direction="right"
                icon={<AiOutlineInfo />}
                className="information-ttoltip"
              />{' '}
            </h4>
          </div>

          <div className="grand-mufti-input-topic">
            <h6>
              First Name
            </h6>
            <Input
              placeholder="First Name"
              value={firstName}
              onChange={(e) => {
                if (firstName != null) {
                  setFirstNameError('');
                }
                setFirstName(e.target.value);
              }}
            />
            {firstNameError ? (
              <p className="email-error">{firstNameError}</p>
            ) : null}
          </div>
          <div className="grand-mufti-input-topic urdu-language font-urdu">
            <h6>
              پہلا نام
            </h6>
            <Input
              placeholder="اپنا نام درج کریں"
              value={firstNameUrdu}
              onChange={(e) => {
                if (firstNameUrdu != null) {
                  setFirstNameUrduError('');
                }
                setFirstNameUrdu(e.target.value);
              }}
              className="urdu-language"
            />
            {firstNameUrduError ? (
              <p className="email-error">{firstNameUrduError}</p>
            ) : null}
          </div>

          <div className="grand-mufti-input-topic">
            <h6>
              Last Name
            </h6>
            <Input
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => {
                if (lastName != null) {
                  setLastNameError('');
                }
                setLastName(e.target.value);
              }}
            />
            {lastNameError ? (
              <p className="email-error">{lastNameError}</p>
            ) : null}
          </div>
          <div className="grand-mufti-input-topic urdu-language font-urdu">
            <h6>
              آخری نام
            </h6>
            <Input
              placeholder="اپنا نام درج کریں"
              value={lastNameUrdu}
              onChange={(e) => {
                if (lastNameUrdu != null) {
                  setLastNameUrduError('');
                }
                setLastNameUrdu(e.target.value);
              }}
              className="urdu-language"
            />
            {lastNameUrduError ? (
              <p className="email-error">{lastNameUrduError}</p>
            ) : null}
          </div>
          <div className="right-side-form">
            <div className="grand-mufti-input-topic">
              <h6>
                User Name{' '}
              </h6>
              <Input
                placeholder="User Name"
                value={userName}
                onChange={(e) => {
                  if (userName != null) {
                    setUserNameError('');
                  }
                  setUserName(e.target.value);
                }}
              />
              {userNameError ? (
                <p className="email-error">{userNameError}</p>
              ) : null}
            </div>
            <div className="grand-mufti-input-topic">
              <h6>
                Email{' '}
              </h6>
              <Input
                placeholder="User Email"
                value={email}
                onChange={(e) => {
                  if (email != null) {
                    setEmailError('');
                  }
                  setEmail(e.target.value);
                }}
              />
              {emailError ? <p className="email-error">{emailError}</p> : null}
            </div>

            <div className="grand-mufti-select-topic">
              <h6>
                User role{' '}
              </h6>
              <select
                className="topic-dropdown"
                onChange={(e) => {
                  if (!userRole) {
                    setUserRoleError('');
                  }
                  setUserRole(e.target.value);
                }}
              >
                <option>Select Role </option>
                <option value="true">Staff Member</option>
                <option value="false">Administrator</option>
              </select>
              {userRoleError ? (
                <p className="email-error">{userRoleError}</p>
              ) : null}
            </div>
            <div className="grand-mufti-select-topic">
              <h6>
                Status{' '}
              </h6>
              <select
                className="topic-dropdown"
                onChange={(e) => {
                  if (active != null) {
                    setActiveError('');
                  }
                  setActive(e.target.value);
                }}
              >
                <option>Select Status </option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              {activeError ? <p className="email-error">{activeError}</p> : null}
            </div>
          </div>
          <div className="grand-mufti-button">
            <Button
              title="Back"
              onClick={Back}
              className="grand-mufti-create-topic"
            />
            <Button title="Save" className="grand-mufti-create-topic" />
            <Button
              title="Delete"
              className="delete-user-button grand-mufti-create-topic"
              onClick={() => setDeleteUser(true)}
            />
          </div>
          <PopUpMessage
            LinkURL="/admin/user"
            message={Message}
            show={messageAlert}
            onHide={() => setMessageAlert(false)}
          />
        </div>
      </form>
    </>
  );
};
export default MuftiEditUserProfile;
