import React, { useState, useEffect } from 'react';
import DashboardCounterBox from './components/DashboardCounterBox';
import DashTable from './components/DashTable';
import GraphBox from './components/GraphBox';
import { Link } from 'react-router-dom';
import {
  deleteQuestionsApi,
} from '../../services/api/QuestionsApi';
import { getUserStatsApi } from './../../services/api/UserStats';
import moment from 'moment';
import { BeatLoader } from 'react-spinners';
import { FaRegEdit } from 'react-icons/fa';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md'
import Button from '../utils/reuseableComponent/Button';
const DashboardHome = ({ setNavigationLoad }) => {
  const [stats, setStats] = useState('');
  const [graphStats, setGraphStats] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tracker, setTracker] = useState(true);

  const [viewClick, setViewClick] = useState(false);
  const [callingComponent, setCallingComponent] = useState(false);
  const [statsCalling, setStatsCalling] = useState(false);

  async function getUserStats() {
    let id = localStorage.getItem('UserLoginID');

    const response = await getUserStatsApi({ id });
    console.log('STATs', response);
    setStats(response?.data?.response);
    setGraphStats(response?.data?.response);
    setTableData(response?.data?.response?.recent_questions);
    setTracker(false);
    if (callingComponent) {
      const responseDashBoardQuestion = await getUserStatsApi({ id });
      let tableLength = tableData.length
      for (let i = 0; i <= tableLength; ++i) {
        tableData.pop()
      }
      console.log('STATs', responseDashBoardQuestion);
      setStats(responseDashBoardQuestion?.data?.response);
      setGraphStats(responseDashBoardQuestion?.data?.response);
      setTableData(responseDashBoardQuestion?.data?.response?.recent_questions);
      setCallingComponent(false)
    }
  }
  console.log("Table Data", tableData)
  useEffect(() => {
    getUserStats();
    localStorage.setItem("Breadcurms", "New Questions")
    setViewClick(false)
  }, [viewClick, callingComponent]);


  function tittleTurncate(description) {
    return description?.split(' ').splice(0, 10).join(' ');
  }


  const [tableHead, setTableHead] = useState([
    {
      title: 'Title',
      render: (rowData) => {
        return <span>{`${tittleTurncate(rowData?.title)}...`}</span>
      },
      className: 'dash-ques-title',
    },
    {
      title: 'Date',
      render: (rowData) => {
        return <span>{moment(rowData.created_at).format('MMMM Do YYYY')}</span>;
      },
      className: 'date',
    },
    {
      title: 'View',

      render: (rowData) => {
        return (
          <>
          </>
        );
      },
      className: 'action-view',
    },
  ]);
  let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  const [viewSection, setViewSection] = useState([
    {
      path: "/admin/new-question-view",
      buttonTitle: "View Details",
      Icon: <BsEye />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      path: "/admin/edit-questions",
      buttonTitle: "Edit",
      Icon: <FaRegEdit />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      isDeleted: true,
      buttonTitle: "Delete",
      Icon: <MdDelete />,
      ColorClass: "delete-question",
      isActive: isAdmin ? true : false
    }
  ]);
  const messageData = "New Question"
  const QuestionTitle = 'Recent Questions';
  const questionPath = "/admin/new-question-view/"
  const pageLink = "/admin/new-question"
  const pageBreadcrum = "New Question"
  return (
    <>
      {tracker ? (
        <>
          <div className="dash-preloader">
            <BeatLoader
              type="ThreeDots"
              color="rgb(195, 156, 51)"
              size="20px"
              margin="3px"
            />
          </div>
        </>
      ) : (
        <>
          <h2 className="dash-heading">Dashboard</h2>

          {stats && <DashboardCounterBox stats={stats} />}
          {graphStats && <GraphBox graphStats={graphStats} />}
          <div className="main-table-cotainer box-shadow">
            <div className="dash-table-top-bar">
              <div className="search-side">
                <h4>Recently Asked Questions</h4>
              </div>
              <div className="SearchBox">
              </div>
            </div>
            <DashTable
              tableData={tableData}
              QuestionTitle={QuestionTitle}
              tableHead={tableHead}

              viewSection={viewSection}
              setViewClick={setViewClick}
              deletedApi={deleteQuestionsApi}
              messageData={messageData}
              questionPath={questionPath}
              setCallingComponent={setCallingComponent}
              pageLink={pageLink}
              pageBreadcrum={pageBreadcrum}
              setStatsCalling={setStatsCalling}
              setNavigationLoad={setNavigationLoad}
            />
          </div>
        </>
      )}
    </>
  );
};
export default DashboardHome;
