import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { getQuestionsDetailsApi, QuestionsRefrencesApi, QuestionsBookApi } from "../../services/api/QuestionsApi";
import Button from '../utils/reuseableComponent/Button';
import TextEditor from '../utils/reuseableComponent/TextEditor/TextEditor';
import { convertToRaw } from 'draft-js';
import "./assests/DashboardQuestionDetailPage.css";
import { putAnswersApi, postRefrences } from '../../services/api/AnswersApi';
import { Link, useParams, useHistory } from 'react-router-dom';

const AnswerEditPageAdmin = () => {
    const params = useParams();
    const questionID = params.id;
    let history = useHistory();

    const location = useLocation();

    const reAgain = location.state?.reAgain


    useEffect(() => {
        getQuestionsDetails();
    }, []);

    async function getQuestionsDetails() {
        try {
            const response = await getQuestionsDetailsApi({ id: questionID });
            setQuestion(response.data);
            setEditorContent(response.data.answer[0].description_en)
            setEditorContentUrdu(response.data.answer[0].description_ur)
            if (response.data.answer[0].description_en) {
                let textInEnglishObject = JSON.parse(response.data.answer[0].description_en).blocks[0].text
                setEditQuestion(textInEnglishObject)
            }
            if (response.data.answer[0].description_ur) {
                let textInUrduObject = JSON.parse(response.data.answer[0].description_ur).blocks[0].text
                setEditQuestionUrdu(textInUrduObject)
            }
            setAnswerID(response.data.answer[0].id);
            setDirectPath(location?.state?.prevPath)

            const responseRefrences = await QuestionsRefrencesApi(questionID);

            setBookID(responseRefrences.data.results[0].book)

            setContentRefrences(responseRefrences.data.results[0].description)
            if (responseRefrences.data.results[0].description) {
                let textInRefrencesObject = JSON.parse(responseRefrences.data.results[0].description).blocks[0].text
                setEditQuestionRefrences(textInRefrencesObject)
            }

            setOnlyReadEnglish(response.data.answer[0].description_en)
            setOnlyReadUrduAnswer(response.data.answer[0].description_ur)
            setOnlyRReadRefrences(responseRefrences.data.results[0].description)

            let offset = 0
            let limit = 20
            const responseBook = await QuestionsBookApi({ offset, limit });
            setBook(responseBook.data.results);
        } catch (error) {
        }
    }
    const Back = () => {
        history.goBack();
        setUrduEdit(false);
        setEnglishEdit(false);
        setRefrencesEdit(false)
    };
    const [Question, setQuestion] = useState({});

    const [editorContent, setEditorContent] = useState('');
    const [editorContentUrdu, setEditorContentUrdu] = useState('');
    const [editorContentRefrences, setContentRefrences] = useState('');
    const [editQuestion, setEditQuestion] = useState("");
    const [editQuestionUrdu, setEditQuestionUrdu] = useState("");
    const [editQuestionRefrences, setEditQuestionRefrences] = useState("");

    const [urduEdit, setUrduEdit] = useState(false);
    const [englishEdit, setEnglishEdit] = useState(false);
    const [refrencesEdit, setRefrencesEdit] = useState(false);

    const [errorEditQuestion, setErrorEditQuestion] = useState("");
    const [errorEditQuestionUrdu, setErrorEditQuestionUrdu] = useState("");
    const [errorEditQuestionrefrences, setErrorEditQuestionrefrences] = useState("");

    const [answerID, setAnswerID] = useState("");
    const [directPath, setDirectPath] = useState("");

    const [onlyReadEnglishAnswer, setOnlyReadEnglish] = useState("");
    const [onlyReadUrduAnswer, setOnlyReadUrduAnswer] = useState("");
    const [onlyReadRefrences, setOnlyRReadRefrences] = useState("");


    const [book, setBook] = useState([]);
    const [bookID, setBookID] = useState('');
    const [bookError, setBookError] = useState("");

    let questionId = useParams();
    let ID = questionId.id;

    async function EditQUestion(e) {
        e.preventDefault();
        try {
            console.log("enter")
            if (editQuestionUrdu && editQuestion && editorContentRefrences) {
                if (englishEdit) {
                    var editorData =
                        editorContent && JSON.stringify(convertToRaw(editorContent));
                } else {
                    var editorData = editorContent;
                }
                if (urduEdit) {
                    var editorDataUrdu =
                        editorContentUrdu && JSON.stringify(convertToRaw(editorContentUrdu));
                } else {
                    var editorDataUrdu = editorContentUrdu;
                }
                if (refrencesEdit) {
                    var editorDataRefrences =
                        editorContentRefrences && JSON.stringify(convertToRaw(editorContentRefrences));
                } else {

                    var editorDataRefrences = editorContentRefrences
                }
                console.log("reAgain ", reAgain)
                if (reAgain) {
                    let UserID = {
                        reviewed_by: "",
                    };
                    const responseReviwedBy = await putAnswersApi(UserID, answerID);
                    console.log(" responseReviwedBy ", responseReviwedBy)
                    if (responseReviwedBy.status == "200") {
                        let answeredbBy = localStorage.getItem('UserLoginID');
                        const data = {
                            accepted: true,
                            description_en: editorData,
                            description_ur: editorDataUrdu,
                            is_visible: true,
                            question: questionID,
                            answered_by: answeredbBy,
                        };
                        debugger;
                        const response = await putAnswersApi(data, answerID);
                        console.log(" response ", response)
                        if (response.status == "200") {
                            let answeredbBy = localStorage.getItem('UserLoginID');
                            const RefrencesData = {
                                accepted: true,
                                question: questionID,
                                answer: answerID,
                                reference_by: answeredbBy,
                                description: editorDataRefrences,
                                book: bookID,
                            }
                            const refrencesResponse = await postRefrences(RefrencesData);
                            console.log(" refrencesResponse ", refrencesResponse)
                            history.push(`/admin/questions/pending-answer-review/${ID}`);
                        }
                    }
                } else {
                    let answeredbBy = localStorage.getItem('UserLoginID');
                    const data = {
                        description_en: editorData,
                        description_ur: editorDataUrdu,
                        is_visible: true,
                        question: questionID,
                        answered_by: answeredbBy,
                        accepted: true
                    };
                    const response = await putAnswersApi(data, answerID);
                    if (response.status == "200") {
                        let answeredbBy = localStorage.getItem('UserLoginID');
                        const RefrencesData = {
                            question: questionID,
                            answer: answerID,
                            reference_by: answeredbBy,
                            description: editorDataRefrences,
                            book: bookID,
                            accepted: true
                        }
                        const refrencesResponse = await postRefrences(RefrencesData);
                        console.log(" refrencesResponse ", refrencesResponse)
                        history.goBack();
                    }

                }

            } else {
                if (!editQuestion) setErrorEditQuestion("Feild is required")
                if (!editQuestionUrdu) setErrorEditQuestionUrdu(" فیلڈ ضروری ہے")
                if (!editQuestionRefrences) setErrorEditQuestionrefrences("Feild is required")
                if (!bookID) setBookError("Book feild is required")
            }
        } catch (error) {
        }
    }
    return (
        <>
            <section className="detail-question-bar edit-page">
                <div className="row">
                    <div className="col-md-12">

                        <>
                            <p className="question-id">
                                Question ID : <span>{questionID}</span>
                            </p>
                            <div className="div-sepration"></div>
                            <div className="question-question">
                                <h2 className="title bold-haeding">Re-write Answer : </h2>
                            </div>
                            <div className="wrap-text-editor">
                                <TextEditor sendToEditor={setEditorContent} Edit={onlyReadEnglishAnswer} validationForEmpty={setEditQuestion} isEdit={setEnglishEdit} />
                                <div className="text-editor-submit-button">
                                </div>
                            </div>
                            {!editQuestion && errorEditQuestion ? <p className="error">{errorEditQuestion}</p> : null}
                            <div className="div-sepration"></div>


                            <div className="question-question urdu-language font-urdu">
                                <h2 className="title bold-haeding">
                                    :جواب  دوبارہ لکھیں
                                </h2>
                            </div>
                            <div className="wrap-text-editor font-urdu urdu">
                                <TextEditor
                                    sendToEditor={setEditorContentUrdu}
                                    Edit={onlyReadUrduAnswer}
                                    validationForEmpty={setEditQuestionUrdu}
                                    isEdit={setUrduEdit}
                                />
                            </div>
                            {!editQuestionUrdu && errorEditQuestionUrdu ?
                                <p className="error urdu-language font-urdu">
                                    {errorEditQuestionUrdu}
                                </p>
                                :
                                null
                            }
                            <div className="div-sepration"></div>
                            <div className="wrap-divs admin-books">
                                <h3 className="ask-title">Books</h3>
                                <div className="row">
                                    <div className="col-md-12">
                                        <select
                                            onChange={(e) => {
                                                if (bookID != null) {
                                                    setBookError('');
                                                }
                                                setBookID(e.target.value);
                                            }}
                                            className="topic-dropdown"
                                            value={bookID}
                                        >
                                            <option>Select the Book</option>
                                            {book.map((bookitem, index) => {
                                                let bookListName = bookitem.name
                                                return (
                                                    <option key={index} value={bookitem.id}>
                                                        {bookListName}
                                                    </option>

                                                )
                                            })}
                                        </select> <p className="error">{bookError}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="question-question">
                                <h2 className="title bold-haeding">Re-write Refrences : </h2>
                            </div>
                            <div className="wrap-text-editor font-urdu refrences-section">
                                <TextEditor
                                    sendToEditor={setContentRefrences}
                                    Edit={onlyReadRefrences}
                                    validationForEmpty={setEditQuestionRefrences}
                                    isEdit={setRefrencesEdit}
                                />
                                {!editQuestionRefrences && errorEditQuestionrefrences
                                    ?
                                    <p className="error">{errorEditQuestionrefrences}</p>
                                    : null
                                }
                                <div className="text-editor-submit-button">
                                </div>
                            </div>
                            <div className="div-sepration"></div>

                            <div className="submited-button">
                                <Button title="Save" className="Save-Button" onClick={EditQUestion} />
                                <Button title="Cancel" className="cancel-button" onClick={Back} />
                            </div>
                        </>
                    </div>
                </div>
            </section>
        </>
    );
};
export default AnswerEditPageAdmin;
