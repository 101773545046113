import React from "react";
import BreadCrumbs from "./components/BreadCrumbs";
import QuestionEditPageAdmin from "./QuestionEditPageAdmin";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const EditPageDirectLink = () => {

    const params = useParams();
    const questionID = params.id;

    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: "New Question",
                path: "/admin/new-question",
            },
            { name: "Edit Questions", },
        ]

    const pageName = "Edit Questions"
    return (
        <>
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
            <QuestionEditPageAdmin idOfQuestion={questionID} />
        </>
    );
};
export default EditPageDirectLink;
