import firebase from 'firebase/app';
import 'firebase/messaging';
var firebaseConfig = {
  apiKey: "AIzaSyBcrxiEytD8nTLh5cojCRNlfV6a2M1MIUk",
  authDomain: "testing-d37a1.firebaseapp.com",
  projectId: "testing-d37a1",
  storageBucket: "testing-d37a1.appspot.com",
  messagingSenderId: "158482342544",
  appId: "1:158482342544:web:da9b11846a8f1f60186e15"
};

// !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()


let messaging = null

if (firebase.messaging.isSupported()) {
  !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()
  messaging = firebase.messaging();
} else {
  console.log('no-support :(')
}

// const messaging = firebase.messaging();

export const getToken = async () => {
  return await messaging.getToken({ vapidKey: 'BE_ceZRxBEK5_rpjcV9RvdPXLBprjjeikmEmg0JmYPjFbYaWPDx1NjwR60LMh6m9FSTL8HrpAN4lk8q2ZUpNK7s' }).then((currentToken) => {
    if (currentToken) {
      return currentToken
    } else {
      return null
    }
  }).catch((err) => {
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });