import axiosApi from "../axios-config/axios";
export const postAnswersApi = async (AnswerData) => {
  try {
    console.log("AnswerData =>", AnswerData)
    const { data, status } = await axiosApi({
      endpoint: `answers/`,
      method: "POST",
      bodyData: AnswerData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const putAnswersApi = async (approvalStatus, answerID) => {
  try {
    console.log("approvalStatus API=>", approvalStatus)
    const { data, status } = await axiosApi({
      endpoint: `answers/${answerID}/`,
      method: "PATCH",
      bodyData: approvalStatus,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};


export const postRefrences = async (RefrencesData) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `reference/`,
      method: "POST",
      bodyData: RefrencesData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const putRefrences = async (RefrencesData) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `reference/`,
      method: "POST",
      bodyData: RefrencesData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};