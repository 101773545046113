import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import Submenu from './Submenu';
import '../assests/SideBarMenu.css';
import {
  getNagivationsUpdatedApi,
} from '../../../services/api/NavigationsApi';
import { BeatLoader } from 'react-spinners';
const SideBarMenu = ({ setNavigationLoad, navigationLoad }) => {
  const [sidebar, setSidebar] = useState(false);

  const [sideBarMobile, setSideBarMobile] = useState(true);
  const showSidebarMobile = () => setSideBarMobile(!sideBarMobile);


  console.log(" sideBarMobile ", sideBarMobile)

  const [navigations, setNavigations] = useState([]);
  const [navigationsStats, setNavigationsStats] = useState([]);
  const [tracker, setTracker] = useState(true);
  const [navigationData, setNavigationData] = useState([]);
  useEffect(() => {
    getNagivations();
    setNavigationLoad(false);
    localStorage.setItem('isAssigned', 'false');
  }, [navigationLoad]);

  async function getNagivations() {
    const updateNavigation = await getNagivationsUpdatedApi();
    setNavigations(updateNavigation?.data?.navigation?.results);
    setNavigationsStats(updateNavigation?.data?.stats);
    setTracker(false);
    setNavigationData(updateNavigation);
  }
  return (
    <>
      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>
          <div className="nav">
            <Link className="nav-icon" to="#">
              <FaIcons.FaBars onClick={showSidebarMobile} />
            </Link>
          </div>
          <div className={sidebar ? `sidebar-nav webNav` : `sidebar-nav-closes webNav`}>

            <div className="sidebar-wrap">
              <Submenu
                navigations={navigations != 0 && navigations}
                navigationsStats={navigationsStats != 0 && navigationsStats}
                navigationData={navigationData != 0 && navigationData}
                setSideBarMobile={setSideBarMobile}
              />
            </div>
          </div>

          <div className={sideBarMobile ? `sidebar-nav sidebar-wrap-mobile` : `sidebar-nav-closes sidebar-wrap-mobile sidebar-wrap-mobile`}>
            <Link className="nav-icon" to="#">
              <AiIcons.AiOutlineClose onClick={showSidebarMobile} />
            </Link>
            <Submenu
              navigationData={navigationData != 0 && navigationData}
              navigations={navigations != 0 && navigations}
              navigationsStats={navigationsStats != 0 && navigationsStats}
              setSideBarMobile={setSideBarMobile}
            />
          </div>

        </>
      )}
    </>
  );
};

export default SideBarMenu;
