import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Chartjs = ({ option }) => (
  <div>
    <HighchartsReact highcharts={Highcharts} options={option} />
  </div>
);

export default Chartjs;
