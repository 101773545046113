import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./TextEditor.css";
import { handleNewLine, insertNewUnstyledBlock } from "draftjs-utils";
import { RichUtils } from "draft-js";

const TextEditor = ({ sendToEditor, description, Edit, validationForEmpty, isEdit }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const handleEditorChange = (state) => {
    setEditorState(state);
    sendToEditor(editorState.getCurrentContent());
  };

  const onChangeEvent = (editorState) => {
    validationForEmpty(editorState?.blocks[0]?.text)
    isEdit(true)
  }


  useEffect(() => {
    setDescription();
  }, [description, Edit]);

  function setDescription() {
    if (description) {
      let data = JSON.parse(description);
      setEditorState(EditorState.createWithContent(convertFromRaw(data)));
    } if (Edit) {
      let textOfeditor = JSON.parse(Edit).blocks[0].text
      validationForEmpty(textOfeditor)
      // validationForEmpty(valueText?.blocks?.text)
      let data = JSON.parse(Edit);
      setEditorState(EditorState.createWithContent(convertFromRaw(data)));
    } else {
      return;
    }
  }
  return (
    <div>
      <Editor
        handleReturn={(event) => {
          // override behavior for enter key
          var newEditorState = null;
          if (event.keyCode === 13 && event.shiftKey) {
            // with shift, make a new block
            newEditorState = insertNewUnstyledBlock(editorState);
          } else if (event.keyCode === 13 && !event.shiftKey) {
            // without shift, just a normal line break
            newEditorState = RichUtils.insertSoftNewline(editorState);
          }
          if (newEditorState) {
            setEditorState(newEditorState);
            return true;
          }
          return false;
        }}
        readOnly={description && !Edit !== "undefined" ? true : false}
        toolbarHidden={description && !Edit ? true : false}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        handlePastedText={() => false}
        wrapperClassName={description ? "" : "wrapper-class"}
        editorClassName={description ? "editor-Description" : "editor-class"}
        toolbarClassName="toolbar-class"
        toolbar={{
          options: [
            "inline",
            // "blockType",
            "fontSize",
            // "fontFamily",
            "colorPicker",
            "list",
            "textAlign",
            "history",
          ],
          textAlign: { inDropdown: true },
        }}
        onChange={onChangeEvent}
      />
    </div>
  );
};

export default TextEditor;
