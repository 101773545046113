import axios from 'axios';

//Do not add "/" at the API_URL end
export const API_URL = `https://api.askhidayah.com/api/v1`;
//export const API_URL = `http://127.0.0.1:8000/api/v1`;
// export const API_URL = `http://192.168.1.106:8000/api/v1`;
async function axiosApi({ endpoint, method = 'GET', bodyData, contentType }) {
  try {
    var loginUserKey = localStorage.getItem('LoginUserKey');
    const response = await axios.request({
      url: `${API_URL}/${endpoint}`,
      method,
      data:
        method === 'PUT' || method === 'POST' || method === 'PATCH'
          ? bodyData
          : null,
      headers: {
        Authorization: `Token ${loginUserKey}`,
        Accept: 'application/json',
        'Content-Type': contentType ? contentType : 'application/json',
      },
    });
    return response;
  } catch (error) {
    const { response } = error;

    return response;
  }
}

//Public Api for Client side routes
export async function axiosPublicApi({
  endpoint,
  method = 'GET',
  bodyData,
  contentType,
}) {
  var loginUserKey = localStorage.getItem('LoginUserKey');
  try {
    const response = await axios.request({
      url: `${API_URL}/${endpoint}`,
      method,
      data:
        method === 'PUT' || method === 'POST' || method === 'PATCH'
          ? bodyData
          : null,
      headers: {
        Accept: 'application/json',
        'Content-Type': contentType ? contentType : 'application/json',
      },
    });

    return response;
  } catch (error) {
    const { response } = error;

    return response;
  }
}

export default axiosApi;
