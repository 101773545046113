import React, { useState, useEffect } from "react";
import Button from "../utils/reuseableComponent/Button";
import TextArea from "./components/TextArea";
import "../Dashboard/assests/GrandMuftiTopic.css";
import Input from "../utils/reuseableComponent/Input";
import { deleteTopicsApi } from "./../../services/api/TopicsApi";
import { useParams } from "react-router-dom";
import PopUpMessage from "./components/PopUpMessage";
import { useHistory } from "react-router-dom";
import {
    patchTopicApi,
} from "./../../services/api/TopicsApi";
import { SpecificQuestionsBookApi, SpecificDeleteBookApi, PatchQuestionsBookApi } from '../../services/api/QuestionsApi';
import RequiredToolTip from "./components/RequiredToolTip";
import { AiOutlineInfo } from "react-icons/ai";
import BreadCrumbs from './components/BreadCrumbs';
const EditBook = () => {
    const params = useParams();
    const BookId = params.id;

    const [tracker, setTracker] = useState(true);

    const [name, setName] = useState("");
    const [nameUrdu, setNameUrdu] = useState("");

    const [nameError, setNameError] = useState("");
    const [nameUrduError, setNameUrduError] = useState("");

    const [deleteBook, setDeleteTopics] = useState(false);
    const [messageAlert, setMessageAlert] = useState(false);
    const [Message, setMessage] = useState("");

    let history = useHistory();

    async function getCurrentBook() {
        try {
            console.log("BookId", BookId)
            const responseBook = await SpecificQuestionsBookApi(BookId);
            console.log("responseBook Specific", responseBook)
            setNameUrdu(responseBook.data.name_ur)
            setName(responseBook.data.name_en)
            setTracker(false);
        } catch (err) {
            console.log("Error is ", err);
        }
    }
    useEffect(() => {

        getCurrentBook();
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (
            name &&
            nameUrdu
        ) {
            try {
                if (deleteBook) {
                    const response = await SpecificDeleteBookApi(BookId);
                    console.log("Topic Delete ", response);
                    setMessageAlert(true);
                    setMessage("Your topic is deleted");
                } else {
                    var TopicData = {
                        name_en: name,
                        name: name,
                        name_ur: nameUrdu,
                    };
                    const response = await PatchQuestionsBookApi(TopicData, BookId);
                    console.log("response Patch Book => ", response);
                    if (response.status == "200") {
                        setMessageAlert(true);
                        setMessage("Your topic is edit");
                    }
                }
            } catch (err) {
                const { response } = err;
                console.log("error");
            }
        } else {
            if (!name) setNameError("Topic feild is required");
            if (!nameUrdu) setNameUrduError("عنوان نام ضروری ہے");
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };
    const [breadcrum, setBreadcrum] = useState([
        {
            name: "Dashboard",
            path: "/admin"
        },
        {
            name: "Manage Book",
            path: "/admin/manage-books"
        },
        { name: "Edit Book ", },
    ])
    const pageName = "Book"
    return (
        <>
            <div className="tables-breadcrums">
                <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
            </div>
            <form onSubmit={onSubmit}>
                <div className="wrap-grand-mufti main book-edit">
                    <div className="language-dedication">
                        <h4>Edit Book <RequiredToolTip Text="Use this form to create the topics of categorize the question" Direction="right" icon={<AiOutlineInfo />} className="information-ttoltip" /></h4>
                    </div>
                    <div className="grand-mufti-input-topic">
                        <h6 >Book Name</h6>
                        <Input
                            placeholder="Write Your Book Name"
                            value={name}
                            onChange={(e) => {
                                if (name != null) {
                                    setNameError("");
                                }
                                setName(e.target.value);
                            }}
                        />
                        {nameError ? <p className="email-error">{nameError}</p> : null}
                    </div>
                    <div className="grand-mufti-input-topic urdu-language font-urdu">
                        <h6> کتاب کا نام</h6>
                        <Input
                            placeholder="اپنا کتاب کا نام درج کریں"
                            value={nameUrdu}
                            onChange={(e) => {
                                if (nameUrdu != null) {
                                    setNameUrduError("");
                                }
                                setNameUrdu(e.target.value);
                            }}
                            className="urdu-language"
                        />
                        {nameUrduError ? <p className="email-error font-urdu">{nameUrduError}</p> : null}
                    </div>

                    <div className="grand-mufti-button">
                        <Button
                            title="Save"
                            className="grand-mufti-create-topic"
                        />
                        <Button
                            title="Delete"
                            className="delete-user-button grand-mufti-create-topic"
                            onClick={() => setDeleteTopics(true)}
                        />
                    </div>
                    <PopUpMessage
                        LinkURL="/admin/topic"
                        message={Message}
                        show={messageAlert}
                        onHide={() => setMessageAlert(false)}
                    />
                </div>
            </form>
        </>
    );
};
export default EditBook;
