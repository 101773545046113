import React, { useState } from "react";
import "./UserProfile.css";
import Input from "../reuseableComponent/Input";
import Button from "../reuseableComponent/Button";
import { Link } from "react-router-dom";

const ChangeAccounPassword = ({
  needed,
  setPrevPass,
  setNewPass,
  setCnfrmPass,
  newPass,
  cnfrmPass,
  prevPass,
  errorNewPassword,
  errorOldPassword,
  errorEmptyPreviousPassword,
  errorEmptyNewPassword,
  errorEmptyConfirmPassword,
  setErrorEmptyNewPassword,
  setErorEmptyPreviousPassword,
  setErorEmptyConfirmPassword
}) => {
  return (
    <>
      <div className="change-pass">
        <div className="edit-profile-heading">
          <h2>Change Your Password</h2>
        </div>
        {needed == "Change Needed" ? (
          <Input
            type="password"
            label="Current Password"
            value={prevPass}
            onChange={(e) => {
              setPrevPass(e.target.value);
              if (prevPass != null) {
                setErorEmptyPreviousPassword("");
              }
            }}
          />
        ) : null}
        {errorOldPassword ? (
          <>
            {errorOldPassword.map((item) => (
              <span className="error">{item}</span>
            ))}
          </>
        ) : null}
        {errorEmptyPreviousPassword ? (
          <p className="error">{errorEmptyPreviousPassword}</p>
        ) : null}
        <Input
          type="password"
          label="New Password"
          onChange={(e) => {
            setNewPass(e.target.value);
            if (newPass != null) {
              setErrorEmptyNewPassword("");
            }
          }}
          value={newPass}
        />
        {errorEmptyNewPassword ? (
          <p className="error">{errorEmptyNewPassword}</p>
        ) : null}
        <Input
          type="password"
          label="Confirm Password"
          onChange={(e) => {
            setCnfrmPass(e.target.value);
            if (cnfrmPass != null) {
              setErorEmptyConfirmPassword("");
            }
          }}
          value={cnfrmPass}
        />
        {errorNewPassword ? (
          <>
            {errorNewPassword.map((item) => (
              <span className="error">{item}</span>
            ))}
          </>
        ) : null}
        {errorEmptyConfirmPassword ? (
          <p className="error">{errorEmptyConfirmPassword}</p>
        ) : null}
        <Button title="Update" type="submit" className="blue-btn" />
      </div>
    </>
  );
};

export default ChangeAccounPassword;
