import React from 'react';
import { Link } from 'react-router-dom';

const MegaMenu = ({ topics }) => {
  return (
    <div className="topic">
      {topics &&
        topics.map((parent, index) => (
          <div className="topic-data" key={index}>
            <h3>{localStorage.getItem("language") == "en" ? parent.name_en : parent.name_ur}</h3>
            <ul>
              {parent.children.map((child, index2) => (
                <Link key={index2} to={`/topic/${child.id}`}>
                  <li> {`${localStorage.getItem("language") == "en" ? child.name_en : child.name_ur} (${child.questions_counts})`} </li>
                </Link>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

export default MegaMenu;
