import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DashTable from './components/DashTable';
import { Link } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md'
import BreadCrumbs from './components/BreadCrumbs';
import {
  getNewQuestionsApi,
  filterQuestionsAdminApi,
  deleteQuestionsApi,
} from '../../services/api/QuestionsApi';
import moment from 'moment';
import { BsSearch } from 'react-icons/bs';
import Input from '../utils/reuseableComponent/Input';
import { BeatLoader } from 'react-spinners';
import { ImCancelCircle } from 'react-icons/im';
const NewQuestions = ({ setNavigationLoad }) => {
  const [tableData, setTableData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [offset2, setOffset2] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const [filterQues, setFilterQues] = useState([]);
  const [searchQues, setSearchQues] = useState('');
  const [endGetRequests, setEndGetRequests] = useState(false);
  const [tracker, setTracker] = useState(true);
  const [noQuestionsFound, setNoQuestionsFound] = useState(false);
  const [viewClick, setViewClick] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [filterEmpty, setFilterEmpty] = useState([]);
  const [requestInProgress, serRequestInProgress] = useState(false);
  const [callingComponent, setCallingComponent] = useState(false);
  const [statsCalling, setStatsCalling] = useState(false);

  const params = useParams();
  const questionID = params.id;
  let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  useEffect(async () => {
    getNewQuestions();
    await localStorage.setItem('assignedQuestion', false);
    setViewClick(false)
    localStorage.setItem("Breadcurms", "New Questions")
    localStorage.setItem("breadcurmsPath", "/admin/new-question")
  }, [offset, viewClick, callingComponent]);
  function tittleTurncate(description) {
    return description?.split(' ').splice(0, 10).join(' ');
  }

  console.log("New Question LocalStroage", localStorage.getItem('assignedQuestion'))
  async function searchQuestions(word) {
    debugger;
    if (requestInProgress) return;
    await serRequestInProgress(true);
    const response = await filterQuestionsAdminApi({ searchOffset, word });
    console.log("new question filter", response)
    if (response?.data?.results?.length === 0) {
      setNoQuestionsFound(true);
    } else {
      setClearSearch(true)
      if (response.data.next || filterQues.length <= response.data.count) {
        for (let i = 0; i <= filterQues.length + 1; ++i) {
          filterQues.pop()
        }
        await setFilterQues([...filterQues, ...response.data.results]);
        //setSearchOffset(searchOffset + 10);
      } else {
        let fliterLenght = filterQues.length
        for (let j = 0; j <= fliterLenght + 1; ++j) {
          filterQues.pop()
        }
        await setFilterQues([...filterQues, ...response.data.results]);
      }
    }
    if (word == "") {
      await setFilterQues(filterEmpty);
      console.log(" filterQues ", filterQues)
      await setClearSearch(false)
      console.log("Clear Search", clearSearch)
      await setNoQuestionsFound(false)
    }
    await setSearchOffset(filterQues.length - 1);
    await serRequestInProgress(false);
  }

  console.log("new filterQues Question", filterQues)

  function searchChange(search) {

    setSearchQues(search);
    setFilterQues([]);
    setSearchOffset(0);
    setNoQuestionsFound(false);
  }

  async function getNewQuestions() {
    if (requestInProgress) return;
    try {
      await serRequestInProgress(true);
      console.log("offset", offset)
      if (!endGetRequests) {
        const response = await getNewQuestionsApi({ offset, offset2 });
        console.log('Get new questions', response);
        if (response?.data?.results?.length == 0) {
          await setTracker(false);
          if (response?.data?.count == tableData.length) {
            await setEndGetRequests(true);
          }
        } else {
          console.log('assigned response ', response);
          await setTableData([...tableData, ...response.data.results]);
          console.log('tableData ', tableData);
          await setTracker(false);
        }
        if (!response.data.next) {
          //setEndGetRequests(true);
        }
      }
      if (callingComponent) {
        console.log("re-enter")
        if (filterEmpty) {
          await setFilterQues(filterEmpty);
        }
        await setOffset(0)
        await setOffset2(0)
        const response = await getNewQuestionsApi({ offset, offset2 });
        console.log("New Question Data", response)
        if (response?.data?.results?.length == 0 && tableData.length != 1) {
          console.log("enter in Else")
        } else {
          let tableLength = tableData.length
          for (let i = 0; i <= tableLength; ++i) {
            await tableData.pop()
          }
          await setTableData([...tableData, ...response.data.results]);
          console.log("tableData =>", tableData)
          console.log("response ", response)
          await setCallingComponent(false)
        }
      }
      await serRequestInProgress(false);
    } catch (err) {
      await serRequestInProgress(false);
      console.log('Error is ', err);
    }

  }

  console.log("New Question tableData", tableData)

  function scrollToEnd() {
    debugger
    if (requestInProgress) return;
    if (!searchQues) {
      setOffset(offset + 30);
      setOffset2(offset2 + 10)
    }

    if (searchOffset >= 60) {
      searchQuestions(searchQues);
    }
  }

  /* window.onscroll = function scroll() {
     //alert(document.documentElement.offsetHeight);
     //alert(window.innerHeight + document.documentElement.scrollTop);
     if (
       window.innerHeight + document.documentElement.scrollTop >=
       document.documentElement.offsetHeight
     ) {
       scrollToEnd();
     }
   };*/
  const handleAssigned = () => {
    localStorage.setItem('assignedQuestion', false);
  };
  const [tableHead, setTableHead] = useState([
    {
      title: 'SN',
      render: (rowData, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'Title',
      render: (rowData) => {
        return (
          rowData?.title_en
            ? <span>{`${tittleTurncate(rowData?.title_en)}...`}</span>
            : <span className="urdu-language font-urdu">{`${tittleTurncate(rowData?.title_ur)}...`}</span>
        );
      },
      className: 'dash-ques-title',
    },

    {
      title: 'Topics',
      render: (rowData) => {
        return <span>{rowData?.category[0].name}</span>;
      },
    },
    {
      title: 'Created By',
      render: (rowData) => {
        return <span>{rowData?.created_by_name}</span>;
      },
    },
    {
      title: 'Created By Email',
      render: (rowData) => {
        return <span>{(rowData?.created_by_email)}</span>;
      },
    },
    {
      title: 'Date',
      render: (rowData) => {
        return <span>{moment(rowData?.created_at).format('MMMM Do YYYY')}</span>;
      },
    },
    {
      title: 'Status',
      render: (rowData) => {
        return <span>{rowData.progress_status}</span>;
      }
    },
    {
      title: 'Action',
      render: (rowData) => {
        return (
          <>

          </>
        );
      },
      className: `action-view none-class `,
    },
  ]);


  console.log("re-render")
  const [viewSection, setViewSection] = useState([
    {
      path: "/admin/new-question-view",
      buttonTitle: "View Details",
      Icon: <BsEye />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      path: "/admin/question-edit",
      buttonTitle: "Edit",
      Icon: <FaRegEdit />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      isDeleted: true,
      buttonTitle: "Delete",
      Icon: <MdDelete />,
      ColorClass: `delete-question `,
      isActive: isAdmin ? true : false
    }
  ]);
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    { name: "New Questions", },
  ])

  const pageName = "New Questions"
  const messageData = "New Question"
  const questionPath = "/admin/new-question-view/"
  const pageLink = "/admin/new-question"
  const pageBreadcrum = "New Question"
  const EditQuestionPage = "/admin/edit-questions/"
  return (
    <>
      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>
          <div className="tables-breadcrums">
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
          </div>
          <div className="main-table-cotainer box-shadow">
            <div className="dash-table-top-bar">
              <div className="search-side">
                <h4>Questions Table</h4>
              </div>
              <div className="SearchBox">
                <Input
                  placeholder="Search By Title"
                  value={searchQues}
                  onKeyPress={(e) =>
                    !searchQues
                      ? null
                      : e.key === 'Enter' && searchQuestions(searchQues)
                  }
                  onChange={(e) => {
                    searchChange(e.target.value)
                    searchQuestions(e.target.value)
                  }}
                />
                <div className="question-search-btn">
                  {clearSearch ? <ImCancelCircle className="cancel" onClick={() => {
                    searchQuestions("")
                    searchChange("")
                  }} /> : null}
                  <button
                    onClick={() => searchQuestions(searchQues)}
                    disabled={!searchQues}
                  >
                    <BsSearch />
                  </button>
                </div>
              </div>
            </div>
            <DashTable
              noQuestionsFound={noQuestionsFound}
              tableData={filterQues.length !== 0 ? filterQues : tableData}
              tableHead={tableHead}
              viewSection={viewSection}
              setViewClick={setViewClick}
              deletedApi={deleteQuestionsApi}
              messageData={messageData}
              questionPath={questionPath}
              setCallingComponent={setCallingComponent}
              pageLink={pageLink}
              pageBreadcrum={pageBreadcrum}
              EditQuestionPage={EditQuestionPage}
              setNavigationLoad={setNavigationLoad}
              setStatsCalling={setStatsCalling}
            />
            <a href='#' className="search-side" onClick={() => { scrollToEnd(); }}><h1>Load More</h1></a>
          </div>

        </>
      )}
    </>
  );
};

export default NewQuestions;
