import React, { useState, useEffect } from 'react';
import DashTable from './components/DashTable';
import { filterUsersApi } from './../../services/api/UsersApi';
import { BeatLoader } from 'react-spinners';
import { FaRegEdit } from 'react-icons/fa';
import PopUpMessage from './components/PopUpMessage';
import { MdDelete } from 'react-icons/md'
import { QuestionsBookApi } from '../../services/api/QuestionsApi';
import {
    SpecificDeleteBookApi,
} from "./../../services/api/QuestionsApi";
import BreadCrumbs from './components/BreadCrumbs';
const ManageBooks = () => {
    const [tableData, setTableData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);
    const [filterQues, setFilterQues] = useState([]);
    const [searchQues, setSearchQues] = useState('');
    const [endGetRequests, setEndGetRequests] = useState(false);
    const [tracker, setTracker] = useState(true);
    const [noUserFound, setNoUserFound] = useState(false);
    const [messageAlert, setMessageAlert] = useState(false);
    const [Message, setMessage] = useState('');
    const [viewClick, setViewClick] = useState(false);
    const [userID, setUserID] = useState(null);
    const [clearSearch, setClearSearch] = useState(false);
    const [filterEmpty, setFilterEmpty] = useState([]);
    const [callingComponent, setCallingComponent] = useState(false);
    const [statsCalling, setStatsCalling] = useState(false);
    const [navigationLoad, setNavigationLoad] = useState(false);

    async function searchQuestions(word) {
        const response = await filterUsersApi({
            searchOffset,
            word,
        });
        console.log('response user search => ', response);
        if (response?.data?.results.length === 0) {
            setNoUserFound(true);
        } else {
            setClearSearch(true)
            if (response.data.next || filterQues.length <= response.data.count) {
                for (let i = 0; i <= filterQues.length; ++i) {
                    filterQues.pop()
                }
                setFilterQues([...filterQues, ...response.data.results]);
                setSearchOffset(searchOffset + 10);
            } else {
                let fliterLenght = filterQues.length
                for (let j = 0; j <= fliterLenght; ++j) {
                    filterQues.pop()
                }
                setFilterQues([...filterQues, ...response.data.results]);
            }
        }
        if (word == "") {
            setFilterQues(filterEmpty);
            console.log(" filterQues ", filterQues)
            setClearSearch(false)
            console.log("Clear Search", clearSearch)

        }
    }

    function searchChange(search) {
        setSearchQues(search);
        setFilterQues([]);
        setSearchOffset(0);
        setNoUserFound(false);
    }

    async function getBook() {
        console.log("Get Book")
        console.log("callingComponent", callingComponent)
        console.log("endGetRequests", endGetRequests)
        try {
            if (!callingComponent) {
                if (!endGetRequests) {
                    const response = await QuestionsBookApi({ offset });
                    console.log('Recal Book Api', response.data.results);
                    console.log("tableData ", tableData)
                    setTableData([...tableData, ...response.data.results]);
                    console.log("tableData #2", tableData)
                    setTracker(false);
                    if (!response.data.next) {
                        setEndGetRequests(true);
                    }
                }
            } else {
                if (callingComponent) {
                    setOffset(0)
                    let limit = 10
                    const response = await QuestionsBookApi({ offset, limit });
                    console.log('Recal Book Api 2.0', response.data.results);
                    console.log("tableData 2.0", tableData)
                    if (response?.data?.results?.length == 0 && tableData.length != 1) {

                    } else {
                        let tableLength = tableData.length
                        for (let i = 0; i <= tableLength; ++i) {
                            tableData.pop()
                        }
                        setTableData([...tableData, ...response.data.results]);
                        console.log("tableData =>", tableData)
                        console.log("response ", response)
                        setEndGetRequests(false);
                        setCallingComponent(false)
                    }
                }
            }
        } catch (err) {
            console.log('Error is ', err);
        }
    }

    function scrollToEnd() {
        if (!searchQues) {
            setOffset(offset + 10);
        }

        if (searchOffset >= 10) {
            searchQuestions(searchQues);
        }
    }

    window.onscroll = function scroll() {
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            document.documentElement.offsetHeight
        ) {
            scrollToEnd();
        }
    };

    useEffect(() => {
        console.log("UseEffect Call For User")
        getBook();
    }, [offset, callingComponent]);

    function tittleTurncate(description) {
        return description.slice(0, 15);
    }

    const [tableHead, setTableHead] = useState([
        {
            title: 'Book Number',
            render: (rowData) => {
                return (
                    <span>
                        {rowData.number}
                    </span>
                );
            },
            className: 'text-center',
        },
        {
            title: 'Name',
            render: (rowData) => {
                return (
                    <span>
                        {rowData.name_en}
                    </span>
                );
            },
        },
        {
            title: 'عنوان',
            render: (rowData) => {
                return (
                    <span >{rowData.name_ur}</span>
                );
            },
            className: 'dash-ques-title urdu-language font-urdu',
        },
        {
            title: 'Action',
            render: (rowData) => {
                return (
                    <>
                    </>
                );
            },
            className: 'action-view',
        },
    ]);

    const [viewSection, setViewSection] = useState([
        {
            path: "/admin/edit-book/",
            buttonTitle: "Edit",
            Icon: <FaRegEdit />,
            ColorClass: "View-detail-page",
            isActive: true
        },
        {
            isDeleted: true,
            buttonTitle: "Delete",
            Icon: <MdDelete />,
            ColorClass: "delete-question",
            isActive: true
        },
    ]);
    const [breadcrum, setBreadcrum] = useState([
        {
            name: "Dashboard",
            path: "/admin"
        },
        { name: "Book ", },
    ])
    const pageName = "Book"
    const QuestionTitle = "Topics Table";
    const messageData = "Book"
    const questionPath = "/admin/edit-book/"
    return (
        <>

            {tracker ? (
                <div className="dash-preloader">
                    <BeatLoader
                        type="ThreeDots"
                        color="rgb(195, 156, 51)"
                        size="20px"
                        margin="3px"
                    />
                </div>
            ) : (
                <>
                    <div className="tables-breadcrums">
                        <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
                    </div>
                    <div className="main-table-cotainer box-shadow">
                        <div className="dash-table-top-bar">
                            <div className="search-side">
                                <h4>Book Table</h4>
                            </div>
                            <div className="SearchBox">
                                {/* <Input
                                    placeholder="Search By Title"
                                    value={searchQues}
                                    onKeyPress={(e) =>
                                        !searchQues
                                            ? null
                                            : e.key === 'Enter' && searchQuestions(searchQues)
                                    }
                                    onChange={(e) => {
                                        searchChange(e.target.value)
                                        searchQuestions(e.target.value)
                                    }}
                                />
                                <div className="question-search-btn">
                                    {clearSearch ? <ImCancelCircle className="cancel" onClick={() => {
                                        searchQuestions("")
                                        searchChange("")
                                    }} /> : null}
                                    <button
                                        onClick={() => searchQuestions(searchQues)}
                                        disabled={!searchQues}
                                    >
                                        <BsSearch />
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <DashTable
                            noUserFound={noUserFound}
                            tableData={filterQues.length !== 0 ? filterQues : tableData}
                            QuestionTitle={QuestionTitle}
                            tableHead={tableHead}
                            setViewClick={setViewClick}
                            viewSection={viewSection}
                            setMessageAlert={setMessageAlert}
                            setMessage={setMessage}
                            deletedApi={SpecificDeleteBookApi}
                            messageData={messageData}
                            questionPath={questionPath}
                            setCallingComponent={setCallingComponent}
                            setStatsCalling={setStatsCalling}
                            setNavigationLoad={setNavigationLoad}
                        />
                    </div>
                    <PopUpMessage
                        message={Message}
                        show={messageAlert}
                        onHide={() => setMessageAlert(false)}
                    />
                </>
            )}
        </>
    );
};
export default ManageBooks;
