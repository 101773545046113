import axiosApi from "../axios-config/axios";
export const updateUserProfileApi = async (id, UpdateUser) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `users/${id}/`,
      method: "PUT",
      bodyData: UpdateUser,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
