import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Button from "../../utils/reuseableComponent/Button";
const PopUpMessage = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="submitedModal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body className="submited-ask-question-modal">
        <AiOutlineCheckCircle />
        <h2>{props.message}</h2>
        <Link to={props.LinkURL}>
          <Button onClick={props.onHide} title="Close">
            Close
          </Button>
        </Link>
      </Modal.Body>
    </Modal>
  );
};

export default PopUpMessage;
