import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../utils/reuseableComponent/Button';
import DashTable from './components/DashTable';
import { getUsersApi } from './../../services/api/UsersApi';
import { emailPasswordChange } from './../../services/api/PasswordChangeApi';
import { filterUsersApi } from './../../services/api/UsersApi';
import moment from 'moment';
import { BsSearch } from 'react-icons/bs';
import Input from '../utils/reuseableComponent/Input';
import { BeatLoader } from 'react-spinners';
import { FaRegEdit } from 'react-icons/fa';
import { RiLockPasswordLine } from 'react-icons/ri';
import PopUpMessage from './components/PopUpMessage';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md'
import {
  deleteUsersDetailApi,
} from "./../../services/api/UsersApi";
import BreadCrumbs from './components/BreadCrumbs';
import { ImCancelCircle } from 'react-icons/im';
const User = () => {
  const [tableData, setTableData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const [filterQues, setFilterQues] = useState([]);
  const [searchQues, setSearchQues] = useState('');
  const [endGetRequests, setEndGetRequests] = useState(false);
  const [tracker, setTracker] = useState(true);
  const [noUserFound, setNoUserFound] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [Message, setMessage] = useState('');
  const [viewClick, setViewClick] = useState(false);
  const [userID, setUserID] = useState(null);
  const [clearSearch, setClearSearch] = useState(false);
  const [filterEmpty, setFilterEmpty] = useState([]);
  const [callingComponent, setCallingComponent] = useState(false);
  const [statsCalling, setStatsCalling] = useState(false);
  const [navigationLoad, setNavigationLoad] = useState(false);

  async function searchQuestions(word) {
    const response = await filterUsersApi({
      searchOffset,
      word,
    });
    console.log('response user search => ', response);
    if (response?.data?.results.length === 0) {
      setNoUserFound(true);
    } else {
      setClearSearch(true)
      if (response.data.next || filterQues.length <= response.data.count) {
        for (let i = 0; i <= filterQues.length + 1; ++i) {
          filterQues.pop()
        }
        setFilterQues([...filterQues, ...response.data.results]);
        setSearchOffset(searchOffset + 10);
      } else {
        let fliterLenght = filterQues.length
        for (let j = 0; j <= fliterLenght + 1; ++j) {
          filterQues.pop()
        }
        setFilterQues([...filterQues, ...response.data.results]);
      }
    }
    if (word == "") {
      setFilterQues(filterEmpty);
      console.log(" filterQues ", filterQues)
      setClearSearch(false)
      console.log("Clear Search", clearSearch)

    }
  }

  function searchChange(search) {
    setSearchQues(search);
    setFilterQues([]);
    setSearchOffset(0);
    setNoUserFound(false);
  }

  async function getUsers() {
    try {
      if (!endGetRequests) {
        const response = await getUsersApi();
        // setTracker(true)
        console.log('Recal User Api', response.data.results);
        console.log("tableData #1", tableData)
        setTableData([...tableData, ...response.data.results]);
        console.log("tableData #2", tableData)
        setTracker(false);
        if (!response.data.next) {
          setEndGetRequests(true);
        }
      }
      else if (callingComponent) {
        if (filterEmpty) {
          setFilterQues(filterEmpty);
        }
        const response = await getUsersApi();
        console.log("length of table", tableData.length)
        let tableLength = tableData.length
        for (let i = 0; i <= tableLength; ++i) {
          tableData.pop()
        }
        setTableData([...tableData, ...response.data.results]);
        console.log("tableData =>", tableData)
        console.log("response ", response)
        setCallingComponent(false)
      }
    } catch (err) {
      console.log('Error is ', err);
    }
  }

  const changePasswordEmail = async (id) => {
    let userId = id;
    console.log('User Id', userId);
    const response = await emailPasswordChange(userId);
    console.log('reset', response);
    if (response.status == '201') {
      setMessageAlert(true);
      setMessage('Reset password email has been sent successfully');
    }
  };

  function scrollToEnd() {
    if (!searchQues) {
      setOffset(offset + 10);
    }

    if (searchOffset >= 10) {
      searchQuestions(searchQues);
    }
  }

  window.onscroll = function scroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };

  useEffect(() => {
    console.log("UseEffect Call For User")
    getUsers();
  }, [offset, callingComponent]);

  function tittleTurncate(description) {
    return description.slice(0, 15);
  }

  const [tableHead, setTableHead] = useState([
    {
      title: 'Name',
      render: (rowData) => {
        return (
          <span style={{ textTransform: 'capitalize' }}>
            {rowData.username}
          </span>
        );
      },
    },
    {
      title: 'Email Address',
      render: (rowData) => {
        return (
          // <Link to={`/admin/user-dashtable/${rowData.id}`}>
          <span>{`${tittleTurncate(rowData.email)}...`}</span>
          // </Link>
        );
      },
      className: 'dash-ques-title',
    },
    {
      title: 'Status',
      render: (rowData) => {
        return (
          <span
            className={`status ${rowData.is_active ? 'status-active' : 'status-inactive'
              } `}
          >
            {rowData.is_active ? 'Active' : 'Inactive'}
          </span>
        );
      },
      className: 'text-center',
    },
    {
      title: 'Staff',
      render: (rowData) => {
        return <span>{rowData.is_staff ? 'Yes' : 'No'}</span>;
      },
      className: 'assigned-mufti',
    },
    {
      title: 'Admin',
      render: (rowData) => {
        return (
          <span
          // className={`status ${
          //   rowData.is_staff ? "status-inactive" : "status-active"
          // } `}
          >
            {rowData.is_superuser ? 'Yes' : 'No'}
          </span>
        );
      },
      className: 'assigned-mufti',
    },
    {
      title: 'Date',
      render: (rowData) => {
        return <span>{moment(rowData.date_joined).format('MM/DD/YYYY')}</span>;
      },
    },
    {
      title: 'Action',
      render: (rowData) => {
        return (
          <>
            {/* <Link to={`/admin/user-dashtable/${rowData.id}`}>
              <Button title="View" />
            </Link>

            <Link to={`/admin/grand-mufti-user-edit/${rowData.id}`}>
              <Button icon={<FaRegEdit />} className="Edit-Button" />
            </Link>
            <Button
              onClick={() => changePasswordEmail(rowData.id)}
              icon={<RiLockPasswordLine />}
              className="Edit-Button"
            /> */}
          </>
        );
      },
      className: 'action-view',
    },
    // {
    //   title: "Edit",
    //   render: (rowData) => {
    //     return (
    //       <Link to={`/admin/grand-mufti-user-edit/${rowData.id}`}>
    //         <Button title="Edit" />
    //       </Link>
    //     );
    //   },
    //   className: "mufti-edit",
    // },
  ]);

  const [viewSection, setViewSection] = useState([
    {
      path: "/admin/user-dashtable",
      buttonTitle: "View Details",
      Icon: <BsEye />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      path: "/admin/user-profile-edit",
      buttonTitle: "Edit",
      Icon: <FaRegEdit />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      isDeleted: true,
      buttonTitle: "Delete",
      Icon: <MdDelete />,
      ColorClass: "delete-question",
      isActive: true
    },
    {
      isResetPassword: true,
      buttonTitle: "Reset Password",
      Icon: <RiLockPasswordLine />,
      ColorClass: "reset-password-email",
      isActive: true
    }
  ]);
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    { name: "User ", },
  ])
  const pageName = "User"
  const QuestionTitle = "Topics Table";
  const messageData = "User"
  const questionPath = "/admin/user-dashtable/"
  return (
    <>

      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>
          <div className="tables-breadcrums">
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
          </div>
          <div className="main-table-cotainer box-shadow">
            <div className="dash-table-top-bar">
              <div className="search-side">
                <h4>User Table</h4>
              </div>
              <div className="SearchBox">
                <Input
                  placeholder="Search By Title"
                  value={searchQues}
                  onKeyPress={(e) =>
                    !searchQues
                      ? null
                      : e.key === 'Enter' && searchQuestions(searchQues)
                  }
                  onChange={(e) => {
                    searchChange(e.target.value)
                    searchQuestions(e.target.value)
                  }}
                />
                <div className="question-search-btn">
                  {clearSearch ? <ImCancelCircle className="cancel" onClick={() => {
                    searchQuestions("")
                    searchChange("")
                  }} /> : null}
                  <button
                    onClick={() => searchQuestions(searchQues)}
                    disabled={!searchQues}
                  >
                    <BsSearch />
                  </button>
                </div>
              </div>
            </div>
            <DashTable
              noUserFound={noUserFound}
              tableData={filterQues.length !== 0 ? filterQues : tableData}
              QuestionTitle={QuestionTitle}
              tableHead={tableHead}
              setViewClick={setViewClick}
              viewSection={viewSection}
              setMessageAlert={setMessageAlert}
              setMessage={setMessage}
              deletedApi={deleteUsersDetailApi}
              messageData={messageData}
              questionPath={questionPath}
              setCallingComponent={setCallingComponent}
              setStatsCalling={setStatsCalling}
              setNavigationLoad={setNavigationLoad}
            />
          </div>
          <PopUpMessage
            message={Message}
            show={messageAlert}
            onHide={() => setMessageAlert(false)}
          />
        </>
      )}
    </>
  );
};
export default User;
