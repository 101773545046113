import axiosApi from "../axios-config/axios";
import { axiosPublicApi } from "../axios-config/axios";
export const getRecentQuestionsApi = async ({ pageNum }) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `questions/?limit=10&offset=${pageNum}&ordering=-answer__approved_date`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getFeaturedQuestionsApi = async ({ pageNum }) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `is-featured-questions/?limit=10&offset=${pageNum}&ordering=-views_count`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getMostViewedQuestionsApi = async ({ pageNum }) => {
  try {
    console.log("Api pageNum", pageNum);
    const { data, status } = await axiosPublicApi({
      endpoint: `most-Viewed-Questions/?limit=10&offset=${pageNum}&ordering=-views_count`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const filterQuestionsApi = async ({ searchOffset, word }) => {
  try {
    const { data, status } = await axiosPublicApi({
      // endpoint: `questions/?limit=10&offset=${searchOffset}&search=${word}`,
      endpoint: `questions/?limit=10&offset=${0}&search=${word}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const filterQuestionsAdminApi = async ({ searchOffset, word }) => {
  try {
    const { data, status } = await axiosApi({
      // endpoint: `questions/?limit=10&offset=${searchOffset}&search=${word}`,
      endpoint: `admin/new-questions/?limit=10&offset=${0}&search=${word}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getNewQuestionsApi = async ({ offset, offset2 }) => {
  debugger;
  let exceptions = false;
  try {
    const { data, status } = await axiosApi({
      endpoint: `admin/new-questions/?limit=30&offset=${offset}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
    exceptions = true;
  }
  if (exceptions) {
    try {
      const { data, status } = await axiosApi({
        endpoint: `admin/new-questions/?limit=10&offset=${offset2}`,
        bodyData: null,
      });
      return { data, status };
    } catch (error) {
      console.log(error);
      exceptions = true;
    }
  }
};

export const getAllOpenQuestions = async (directlink) => {
  //debugger;
  let exceptions = false;
  try {

    const { data, status } = directlink == "" ? await axiosApi({
      endpoint: `admin/all-open-questions/?limit=20&offset=0`,
      bodyData: null,
    }) : await axiosApi({
      endpoint: `admin/all-open-questions/?` + directlink,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
    exceptions = true;
  }

};
export const getAllStatus = async () => {
  //debugger;
  let exceptions = false;
  try {
    const { data, status } = await axiosApi({
      endpoint: `admin/all-status/`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
    exceptions = true;
  }

};


export const getAllQuestionsApi = async (sd, ed, directLink) => {
  //debugger;
  let exceptions = false;
  try {
    const { data, status } = directLink == "" ? await axiosApi({
      endpoint: `admin/all-questions/?sd=${sd}&ed=${ed}&limit=20&offset=0`,
      bodyData: null,
    }) : await axiosApi({
      endpoint: `admin/all-questions/?` + directLink,
      bodyData: null,
    });;
    return { data, status };
  } catch (error) {
    console.log(error);
    exceptions = true;
  }

};
export const getPendingQuestionsApi = async ({ offset }) => {
  let pendingQuestions = localStorage.getItem("UserLoginID");
  try {
    console.log("calling pending Api");
    const { data, status } = await axiosApi({
      endpoint: `admin/pending-questions/?assigned_to=${pendingQuestions}&limit=10&offset=${offset}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getAssignedQuestionsApi = async ({ offset }) => {
  let AssignedQuestionID = localStorage.getItem("UserLoginID");
  console.log("AssignedQuestionIDApi", AssignedQuestionID);
  try {
    const { data, status } = await axiosApi({
      endpoint: `admin/assigned-questions/?assigned_to=${AssignedQuestionID}&limit=10&offset=${offset}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
export const filterPendingQuestionsApi = async ({ id, searchOffset, word }) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `admin/pending-questions/?assigned_to=${id}&limit=10&offset=${0}&search=${word}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
export const filterAssignedQuestionsApi = async ({
  id,
  searchOffset,
  word,
}) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `admin/assigned-questions/?assigned_to=${id}&limit=10&offset=${0}&search=${word}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getApprovedQuestionsApi = async ({ id, offset }) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `admin/approved-questions/?assigned_to=${id}&limit=10&offset=${offset}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const filterApprovedQuestionsApi = async ({
  id,
  searchOffset,
  word,
}) => {
  console.log("searchOffset", searchOffset);
  try {
    let id = localStorage.getItem("UserLoginID");
    const { data, status } = await axiosApi({
      endpoint: `questions/?accepted=True&limit=10&offset=${0}&user=${id} &search=${word}`,
      // endpoint: `questions/?accepted=True&limit=10&user=${id} &search=${word}`,
      // endpoint: `admin/approved-questions/?assigned_to=${id}&search=${word}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getUserRejectedQuestionsApi = async ({ offset }) => {
  let pendingQuestions = localStorage.getItem("UserLoginID");
  try {
    const { data, status } = await axiosApi({
      endpoint: `admin/rejected-questions/?assigned_to=${pendingQuestions}&limit=10&offset=${offset}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const filterUserRejectedQuestionsApi = async ({
  id,
  searchOffset,
  word,
}) => {
  // let pendingQuestions = localStorage.getItem("UserLoginID");
  try {
    const { data, status } = await axiosApi({
      endpoint: `admin/rejected-questions/?assigned_to=${id}&limit=10&offset=${0}&search=${word}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getUserStatsQuestionsApi = async () => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `user/stats/`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getTopicQuestionsApi = async ({ offset, topicID }) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `questions/?limit=10&offset=${offset}&topic=${topicID}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getQuestionsDetailsApi = async ({ id }) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `temp-questions/${id}/`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
export const putQuestionsDetailsApi = async (questionId, QuestionData) => {
  debugger;
  QuestionData.progress_status = 2;
  try {
    const { data, status } = await axiosApi({
      endpoint: `temp-questions/${questionId}/`,
      method: "PATCH",
      bodyData: QuestionData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const patchPublicQuestionsDetailsApi = async (
  questionId,
  QuestionData
) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `temp-questions/${questionId}/`,
      method: "PATCH",
      bodyData: QuestionData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const postQuestionsApi = async (QuestionData) => {
  try {
    console.log("Question Submitted", QuestionData);
    const { data, status } = await axiosPublicApi({
      endpoint: `questions/`,
      method: "POST",
      bodyData: QuestionData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const postImagesApi = async (images) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `attacehements/`,
      method: "POST",
      bodyData: images,
      contentType: "multipart/form-data",
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const postAudioApi = async (audio) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `attacehements/`,
      method: "POST",
      bodyData: audio,
      contentType: "multipart/form-data",
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const patchQuestionsApi = async (QuestionData, id) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `temp-questions/${id}/`,
      method: "PATCH",
      bodyData: QuestionData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const deleteQuestionsApi = async (id) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `temp-questions/${id}/`,
      method: "DELETE",
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const QuestionsRefrencesApi = async (id) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `reference/?question_id=${id}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const QuestionsBookApi = async ({ offset }) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `books/?limit=10&offset=${offset}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const SelectQuestionsBookApi = async ({ offset }) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `books/?limit=10&offset=${offset}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const SpecificQuestionsBookApi = async (id) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `books/${id}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const PatchQuestionsBookApi = async (BookData, id) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `books/${id}/`,
      method: "PUT",
      bodyData: BookData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const SpecificDeleteBookApi = async (id) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `books/${id}`,
      method: "DELETE",
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const SpecificPostBookApi = async (BookData) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `books/`,
      method: "POST",
      bodyData: BookData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const postFatwa = async (FatwaData) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `fatwa/`,
      method: "POST",
      bodyData: FatwaData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getFatwa = async (id) => {
  try {
    const { data, status } = await axiosPublicApi({
      endpoint: `fatwa/?question=${id}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
