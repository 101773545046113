import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Button from "../reuseableComponent/Button";
import { AiOutlineCheckCircle } from "react-icons/ai";
const SubmitAskQuestionModul = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="submitedModal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body className="submited-ask-question-modal">
        <AiOutlineCheckCircle />
        <h2>
          Thank you for submiting your question <br /> You will be updated once
          the question is answered by the mufti
        </h2>
        <Link to={`/question-details/${props.questionid}`}>
          <Button onClick={props.onHide} title="Close">
            Close
          </Button>
        </Link>
      </Modal.Body>
    </Modal>
  );
};

export default SubmitAskQuestionModul;
