import React, { useState, useEffect } from "react";
import "./assests/DashTable.css";
import { useParams } from "react-router-dom";
import DashTable from "./components/DashTable";
import { getChilderTopics } from "./../../services/api/TopicsApi";
import { BeatLoader } from "react-spinners";
import BreadCrumbs from './components/BreadCrumbs';
const ChildTopicMutfi = () => {
    const [tableData, setTableData] = useState([]);
    const [parent, setParent] = useState("");
    const params = useParams();
    const TopicsID = params.id;
    const [tracker, setTracker] = useState(true);
    async function getTopics() {
        try {
            const response = await getChilderTopics(TopicsID);
            setTableData(response.data.children);
            setParent(response.data.name);
            setTracker(false);
        } catch (err) {
            console.log("Error is ", err);
        }
    }
    useEffect(() => {
        getTopics();
    }, []);

    function tittleTurncate(description) {
        return description.split(" ").splice(0, 2).join(" ");
    }
    const [tableHead, setTableHead] = useState([
        {
            title: "Name",
            render: (rowData) => {
                return (
                    <span>{`${tittleTurncate(rowData.name, 3)}...`}</span>
                );
            },
            className: "dash-ques-title",
        },
        {
            title: "عنوان",
            render: (rowData) => {
                return (
                    <span>{`... ${tittleTurncate(rowData.name_ur, 3)}`}</span>
                );
            },
            className: "dash-ques-title urdu-language font-urdu",
        },
        {
            title: "Description",
            render: (rowData) => {
                return <span>{`${tittleTurncate(rowData.description)}...`}</span>
            },
            className: "description",
        },

        {
            title: "Display",
            render: (rowData) => {
                return <span>{rowData.is_visible_frontend ? "Yes" : "No"}</span>;
            },
            className: "text-fornt-display",
        },
        {
            title: "Status",
            render: (rowData) => {
                return (
                    <span
                        className={`status ${rowData.is_active ? "status-active" : "status-inactive"
                            } `}
                    >
                        {rowData.is_active ? "Active" : "Inactive"}
                    </span>
                );
            },
            className: "text-center",
        },
    ]);

    let QuestionTitle = parent;
    const [breadcrum, setBreadcrum] = useState([
        {
            name: "Dashboard",
            path: "/admin"
        },
        {
            name: "Topics",
            path: "/admin/topic"
        },
        {
            name: `${QuestionTitle}`,
        },
    ])

    const pageName = "Topics Childerns"
    const topicsChildNone = "none-div-child"
    return (
        <>
            {tracker ? (
                <div className="dash-preloader">
                    <BeatLoader
                        type="ThreeDots"
                        color="rgb(195, 156, 51)"
                        size="20px"
                        margin="3px"
                    />
                </div>
            ) : (
                <>
                    <div className="tables-breadcrums">
                        <BreadCrumbs breadcrum={breadcrum} pageName={pageName} QuestionTitle={QuestionTitle} />
                    </div>
                    <div className="main-table-cotainer box-shadow">
                        <DashTable
                            tableData={tableData ? tableData : []}
                            QuestionTitle={QuestionTitle}
                            tableHead={tableHead}
                            topicsChildNone={topicsChildNone}
                        />
                    </div>
                </>
            )}

        </>
    );
};

export default ChildTopicMutfi;
