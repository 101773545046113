import axiosApi from "../axios-config/axios";

export const postPasswordApi = async (passwordData) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `auth/password/change/`,
      method: "POST",
      bodyData: passwordData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const emailPasswordChange = async (id) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `user-password-reset/?user_id=${id}`,
      method: "POST",
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};