import React, { useState } from 'react';
import DashTable from './components/DashTable';
const UpdateQuestion = () => {
  const [header, setHeader] = useState([
    'Title',
    'Topics',
    'Status',
    'Assigned',
    'Date',
  ]);
  const [tableData, setTableData] = useState([
    {
      Title: 'Where we must wear Ihram while going',
      Categorie: 'Modern Issues',
      Status: 'Active',
      Assigned: 'Mufti Hadeed',
      Data: '21 Feb 21',
    },
    {
      Title: 'Where we must wear Ihram while going',
      Categorie: 'Modern Issues',
      Status: 'Active',
      Assigned: 'Mufti Kashan',
      Data: '22 jan 20',
    },
  ]);
  const QuestionTitle = 'Updated Question';
  return (
    <>
      <div>
        <DashTable
          header={header}
          tableData={tableData}
          QuestionTitle={QuestionTitle}
        />
      </div>
    </>
  );
};

export default UpdateQuestion;
