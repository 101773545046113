import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { patchPublicQuestionsDetailsApi } from "../../../services/api/QuestionsApi";
import TextEditor from "./../reuseableComponent/TextEditor/TextEditor";
import { useTranslation } from "react-i18next";
const AnswerDescription = ({
  Answer,
  questionId,
  countNumber,
  refrences,
  fatwa,
}) => {
  console.log("answer => ", Answer);
  let AnswerDescription = Answer && Answer[0] && Answer[0].description_en;
  let AnswerDescriptionUrdu = Answer && Answer[0] && Answer[0].description_ur;
  let refrencesDescription =
    refrences && refrences?.data?.results[0]?.description;
  const { t, i18n } = useTranslation();

  let history = useHistory();
  let location = history.location.pathname;

  useEffect(async () => {
    let path = location;
    if (path.includes("/question-details") && Answer.length != 0) {
      let Count = countNumber;
      Count = Count + 1;
      console.log("Let Count ", Count);
      let viewCount = {
        views_count: Count,
      };
      const responseViewCount = await patchPublicQuestionsDetailsApi(
        questionId,
        viewCount
      );
      console.log("View Count ", responseViewCount);
    }
  }, []);

  return (
    <section className="detail-answer-bar">
      <div className="row">
        <div className="col-md-12">
          <div>
            <ul className="ans-id-by ">
              <li>
                <p>{t("Answer ID")} </p>&nbsp; :{" "}
                <span>{Answer[0] && Answer[0].id}</span>
              </li>
              <li>
                <p>{t("Fatwa ‫‪Number‬‬")}&nbsp; </p> :
                <span>
                  &nbsp;
                  {fatwa}
                </span>
              </li>
            </ul>
            <div className="bismillahh-fatwah-bar ">
              <h2 className="bismillah title  bold-haeding">
                {t("Bismillah hir-Rahman nir-Rahim")}!
              </h2>
            </div>

            <div className="main-answer">
              <h2 className="title  bold-haeding">{t("Answer")} :</h2>
              {AnswerDescription === undefined ? (
                <p className="para">Not yet answered</p>
              ) : localStorage.getItem("language") == "en" ? (
                <TextEditor description={AnswerDescription} />
              ) : (
                <TextEditor description={AnswerDescriptionUrdu} />
              )}
            </div>

            <div className="main-answer">
              <h2 className="title  bold-haeding">{t("Refrences")} :</h2>
              {refrencesDescription === undefined ? (
                <p className="para">Not yet answered</p>
              ) : (
                <div className="refrences-section">
                  <TextEditor description={refrencesDescription} />
                </div>
              )}
            </div>
          </div>
          {/* ); */}
          {/* })} */}
        </div>
      </div>
      <div className="div-sepration"></div>
    </section>
  );
};

export default AnswerDescription;
