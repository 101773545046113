import React, { useState, useEffect } from 'react';
import TextEditor from '../utils/reuseableComponent/TextEditor/TextEditor';
import Button from '../utils/reuseableComponent/Button';
import RequiredToolTip from './components/RequiredToolTip';
import { putAnswersApi } from './../../services/api/AnswersApi';
import { useParams, useHistory, Link, useLocation } from 'react-router-dom';
import { convertToRaw } from 'draft-js';
import { postCommentsAPi } from './../../services/api/CommentsApi';
import PopUpMessage from './components/PopUpMessage';
import {
  postPrivateQuestionApi,
  postPublicQuestionApi
} from './../../services/api/privateQuestionApi';
import {
  patchQuestionsApi
} from './../../services/api/QuestionsApi';
import { postFatwa, getFatwa } from './../../services/api/QuestionsApi';
import { ImCross } from 'react-icons/im';
import { TiTick } from 'react-icons/ti';
import Input from '../utils/reuseableComponent/Input';

const ReviewAnswer = ({
  Question,
  Answer,
  answerID,
  comments,
  refrencesId,
  commentsBy,
  Accept,
  isRequiredComment,
  isRejected,
  marginComments,
  reAgain,
  nonClass,
  setNavigationLoad
}) => {

  useEffect(() => {
    setPageTableLink(pageBreadCurms?.state?.pageTableLink);
    setPageTableName(pageBreadCurms?.state?.pageTableName);
    setPreviousPage(pageBreadCurms?.state?.previousPage)
  }, []);
  let history = useHistory();
  const pageBreadCurms = useLocation();
  const Back = () => {
    history.goBack();
  };
  console.log('Question ', Question);
  let { id: questionId } = useParams();

  const [editorContent, setEditorContent] = useState('');
  const [assined, setAssined] = useState('');
  const [commment, setComment] = useState(false);

  const [messageAlert, setMessageAlert] = useState(false);
  const [rejecToApproved, setRejecToApproved] = useState(false);
  const [commentMessageAlert, setCommentMessageAlert] = useState(false);

  const [editorError, setEditorError] = useState('');
  const [editQuestionEnglish, setEditQuestionEnglish] = useState("");
  const [editable, setEditable] = useState(true);

  const [fatwa, setFatwa] = useState("");

  const [rejectAnswer, setRejectAnswer] = useState(false);
  const [reAgainComment, setReAgainComment] = useState(false);
  const [isFeatured, setIsFeatured] = useState(Question.is_featured);
  const [isVisible, setIsVisible] = useState(Question.is_visible);

  const [pageTableLink, setPageTableLink] = useState();
  const [pageTableName, setPageTableName] = useState();
  const [previousPage, setPreviousPage] = useState();


  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (editQuestionEnglish) {

        var UserID = localStorage.getItem('UserLoginID');
        console.log('reviwed_By =>', UserID);
        let approvalStatus = {
          accepted: false,
          reviewed_by: UserID,
        };
        const rejectResponse = await putAnswersApi(approvalStatus, answerID);
        console.log('Rejection =>', rejectResponse);

        let editorData =
          editorContent && JSON.stringify(convertToRaw(editorContent));
        // console.log(editorData);
        let ID = localStorage.getItem('UserLoginID');
        const data = {
          description: editorData,
          answer: answerID,
          reviewed_by: ID,
        };
        const response = await postCommentsAPi(data);
        console.log('response comments => ', response);
        if (response.status == '201') {
          setNavigationLoad(true)
          setCommentMessageAlert(true);
        }
      } else {
        if (!editQuestionEnglish) setEditorError('Comment is Requird');
      }
    } catch (err) {
      console.log('Error is', err);
    }
  }

  console.log('assined =>', assined);
  const handleAssigned = async (e) => {
    e.preventDefault();
    try {
      setAssined(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAssignedAgain = async (e) => {
    e?.preventDefault();
    try {
      var ID = localStorage.getItem('UserLoginID');
      let UserID = {
        reviewed_by: ID,
      };
      const response = await putAnswersApi(UserID, answerID);
      console.log('assigned response =>', response);
      if (response.status == '200') {
        setNavigationLoad(true)
        setReAgainComment(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("re-again-comment", reAgainComment)

  console.log("nonClass", nonClass)
  const accpectedBYMe = async (e) => {
    e.preventDefault();
    try {
      for (var i = 0; i < Answer.length; ++i) {
        localStorage.setItem('ANswerID', Answer[i].id);
      }
      var UserID = localStorage.getItem('UserLoginID');
      console.log('reviwed_By =>', UserID);
      let approvalStatus = {
        accepted: true,
        reviewed_by: UserID,
      };
      const response = await putAnswersApi(approvalStatus, answerID);
      console.log('answer approval => ', response);

      if (response.status == '200') {
        var reviewBy = localStorage.getItem('UserLoginID');
        const RefrencesData = {
          question: Question.id,
          answer: Question.answer[0].id,
          sub_topic: Question.topic,
          reference: refrencesId,
          answered_by: Question.answer[0].answered_by,
          reviewed_by: reviewBy
        }
        const fatwaResponse = await postFatwa(RefrencesData);
        console.log("fatwaResponse ", fatwaResponse)
        if (!Question.is_visible) {
          console.log('enter');
          const privateAnswerResponse = await postPrivateQuestionApi(answerID);
          console.log('private ', privateAnswerResponse);
        } else {
          const privateAnswerResponse = await postPublicQuestionApi(answerID);
          console.log('public ', privateAnswerResponse);
        }
        console.log("Reviwed By Accept", response)
        setNavigationLoad(true)
        setRejecToApproved(true)
        setMessageAlert(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const reagainAccpected = async (e) => {
    e.preventDefault();
    try {
      for (var i = 0; i < Answer.length; ++i) {
        localStorage.setItem('ANswerID', Answer[i].id);
      }
      var UserID = localStorage.getItem('UserLoginID');
      console.log('reviwed_By =>', UserID);
      let approvalStatus = {
        accepted: true,
      };
      const response = await putAnswersApi(approvalStatus, answerID);
      console.log('answer approval => ', response);

      if (response.status == '200') {
        if (!Question.is_visible) {
          console.log('enter');
          const privateAnswerResponse = await postPrivateQuestionApi(answerID);
          console.log('private ', privateAnswerResponse);
        } else {
          const privateAnswerResponse = await postPublicQuestionApi(answerID);
          console.log('public ', privateAnswerResponse);
        }
        var ID = localStorage.getItem('UserLoginID');
        let UserID = {
          reviewed_by: ID,
        };
        const response = await putAnswersApi(UserID, answerID);
        console.log("Reviwed By Accept", response)
        setNavigationLoad(true)
        setRejecToApproved(true)
        setMessageAlert(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("messageAlert", messageAlert)
  const RejectedBYMe = async (e) => {
    e.preventDefault();
    try {
      setComment(true);
    } catch (error) {
      console.log(error);
    }
  };
  let QuestionDescription = Question.description;

  let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  console.log("Accept # 2", Accept)

  const featureToogle = async () => {
    setIsFeatured(!isFeatured)
    const data = {
      is_featured: !isFeatured
    };
    const response = await patchQuestionsApi(data, questionId);
  }
  const visibleToogle = async () => {
    setIsVisible(!isVisible)
    // let featured = !isFeatured
    const data = {
      is_visible: !isVisible
    };
    const response = await patchQuestionsApi(data, questionId);

  }
  return (
    <>
      {isAdmin
        ?
        isRejected ?
          <div className={`assigned-button reject-section ${rejectAnswer ? "no-padding" : null}`}>
            <div className="assigned-bUtton-note feature-section">
              <p>
                <span>Note : </span>Click on the Reject button to comments
                on answer or Accept button to accept the answer
              </p>
            </div>
            <form>
              <div className="checkBox-tab">
                <div className="wrap-checkBox-section" onClick={featureToogle}>
                  {isFeatured ? <Input type="checkbox" checked /> : <Input type="checkbox" />} <p>Feature</p>
                </div>
                <div className="wrap-checkBox-section" onClick={visibleToogle}>
                  {isVisible ? <Input type="checkbox" checked /> : <Input type="checkbox" />} <p>Is visible</p>
                </div>
              </div>
              <div className="wrap-button">
                <div className="class-accept-reject">
                  <div className="image-div">
                    <img src='/dash-images/Reject.png' alt="logo" />
                  </div>
                  <Button
                    className="Rejected-Button btn-danger"
                    title="Reject"
                    onClick={(e) => {
                      e.preventDefault();
                      setRejectAnswer(true)
                    }}
                  />
                </div>
                <Link to={{ pathname: `/admin/edit-answer/${Question.id}`, state: { pageTableLink: pageTableLink, pageTableName: pageTableName, previousPage: previousPage }, }}>
                  <div className="class-accept-reject edit-answer">
                    <div className="image-div">
                      <img src='/dash-images/Edit.svg' alt="logo" />
                    </div>
                    <Button
                      title="Edit Answer"
                      className="btn-success accept-btn"
                    />
                  </div>
                </Link>
              </div>
            </form>
          </div>

          : null
        : null}

      {rejectAnswer ?
        <>
          <div className="assigned-button re-reject-assigned">
            <div className="assigned-bUtton-note">
              <p>
                <span>Note : </span>Click on the Assigned Button to accept or reject the answer
              </p>
            </div>
            <form onSubmit={handleAssignedAgain}>
              <Button title="Assign Myself" />
            </form>
            <Button
              className="cancel-button"
              title="Cancel"
              onClick={(e) => {
                setRejectAnswer(false)
                setRejectAnswer(false)
                setReAgainComment(false)
                e.preventDefault();
              }}
            />
          </div>
        </>
        : null}
      {
        reAgain ?
          <div className="accept-re-again-answer">
            <div className="assigned-bUtton-note">

              <p>
                <span>Note : </span>Click on the Assigned Button to accept or reject the answer
              </p>
            </div>
            <form>
              <div className="wrap-button mobile-responsive">
                {isAdmin ?
                  <div className="class-accept-reject">
                    <div className="image-div">
                      <img src='/dash-images/approved.png' alt="logo" />
                    </div>
                    <Button
                      title="Accept"
                      className="btn-success accept-btn"
                      onClick={reagainAccpected}
                    />
                  </div>
                  :
                  null}
                <Link to={{ pathname: `/admin/edit-answer/${Question.id}`, state: { reAgain: reAgain, pageTableLink: pageTableLink, pageTableName: pageTableName, previousPage: previousPage }, }}>
                  <div className="class-accept-reject edit-answer">
                    <div className="image-div">
                      <img src='/dash-images/Edit.svg' alt="logo" />
                    </div>
                    <Button
                      title="Re-again Answer"
                      className="btn-success accept-btn"
                    />
                  </div>
                </Link>
              </div>
            </form>
          </div>
          : null
      }
      <div className={`comment-box main ${nonClass} ${marginComments} ${commment ? "background-transprents" : null}`}>
        {isRequiredComment ?
          Accept ? (
            <div className="box-comments" >
              <div className="question-question">
                <h2 className="title">Grand Mufti Comments </h2>
                <div className="div-sepration comments-divider"></div>
                {comments ? (
                  <>
                    <div className="wrap-comments">
                      <div className="first-name-alphabate">
                        <div className="name-section">
                          <span>{commentsBy.slice(0, 1)}</span></div>
                      </div>
                      <div className="commen-text">
                        <p>{commentsBy}</p>
                        <div className="comments-text">
                          <TextEditor description={comments} />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <p>No comments</p>
                )}
              </div>
            </div>
          ) : (
            <>
              {assined ? (
                <>
                  {commment ? null : (
                    <>
                      <div className="div-sepration"></div>
                      <div className="assigned-button">
                        <div className="assigned-bUtton-note">
                          <p>
                            <span>Note : </span>Click on the Reject button to comments
                            on answer or Accept button to accept the answer
                          </p>
                        </div>
                        <form>
                          <div className="wrap-button">
                            <div className="class-accept-reject">
                              <div className="image-div">
                                <img src='/dash-images/approved.png' alt="logo" />
                              </div>
                              <Button
                                title="Accept"
                                className="btn-success accept-btn"
                                onClick={accpectedBYMe}
                              />
                            </div>

                            <div className="class-accept-reject">
                              <div className="image-div">
                                <img src='/dash-images/Reject.png' alt="logo" />
                              </div>
                              <Button
                                title="Reject"
                                className="btn-success accept-btn"
                                onClick={RejectedBYMe}
                              />
                            </div>

                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="div-sepration"></div>
                  <div className="assigned-button">
                    <div className="assigned-bUtton-note">

                      <p>
                        <span>Note : </span>Click on the Assigned Button to accept or reject the answer
                      </p>
                    </div>
                    <form onSubmit={handleAssigned}>
                      <Button title="Assign Myself" />
                    </form>
                    <Button className="cancel-button" title="Cancel" onClick={Back} />
                  </div>
                </>
              )}
            </>
          )
          : null}
      </div>
      <PopUpMessage
        LinkURL="/admin/questions/grand-mufti-pending-questions"
        message="Answer has been accepted"
        show={messageAlert}
        onHide={() => setMessageAlert(false)}
      />

      <PopUpMessage
        LinkURL="/admin/questions/grand-mufti-pending-questions"
        message="Answer has been accepted"
        show={rejecToApproved}
        onHide={() => setRejecToApproved(false)}
      />

      {commment || reAgainComment ? (
        <div className="div-add-comments">
          <div className="wrap-text-editor">
            <h4>
              Write Comments
              <RequiredToolTip
                Text="Feild Required"
                Direction="right"
                icon="*"
                className="required-ttoltip"
              />{' '}
            </h4>
            <h5>Write your comments in detail</h5>
            <form onSubmit={handleSubmit}>
              <TextEditor sendToEditor={setEditorContent} validationForEmpty={setEditQuestionEnglish} isEdit={setEditable} />
              {editorError && editQuestionEnglish ? <p className="error">{editorError}</p> : null}
              <div className="text-editor-submit-button">
                <Button title="Submit" type="submit" />
              </div>
              <PopUpMessage
                LinkURL="/admin/questions/grand-mufti-pending-questions"
                message="Comment has been submitted"
                show={commentMessageAlert}
                onHide={() => setCommentMessageAlert(false)}
              />
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default ReviewAnswer;
