import React, { useState, useEffect } from 'react';
// import { useParams } from "react-router-dom";
import DashTable from './components/DashTable';
import { Link } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md'
import BreadCrumbs from './components/BreadCrumbs';
import {
  getAssignedQuestionsApi,
  filterAssignedQuestionsApi,
  deleteQuestionsApi,
} from '../../services/api/QuestionsApi';
import moment from 'moment';
import { BsSearch } from 'react-icons/bs';
import Input from '../utils/reuseableComponent/Input';
import { BeatLoader } from 'react-spinners';
import Button from '../utils/reuseableComponent/Button';
import { ImCancelCircle } from 'react-icons/im';
const AssignedQuestion = ({ setNavigationLoad }) => {
  const [tableData, setTableData] = useState([]);
  // const [loginUser, setLoginUser] = useState([]);
  const [offset, setOffset] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const [filterQues, setFilterQues] = useState([]);
  const [searchQues, setSearchQues] = useState('');
  const [endGetRequests, setEndGetRequests] = useState(false);
  const [noQuestionsFound, setNoQuestionsFound] = useState(false);
  const [tracker, setTracker] = useState(true);
  const [viewClick, setViewClick] = useState(false);

  const [filterEmpty, setFilterEmpty] = useState([]);
  const [clearSearch, setClearSearch] = useState(false);

  const [callingComponent, setCallingComponent] = useState(false);
  const [statsCalling, setStatsCalling] = useState(false);
  useEffect(async () => {
    getAssignedQuestion();
    await localStorage.setItem('assignedQuestion', true);
    localStorage.setItem("Breadcurms", "Assigned Questions")
    localStorage.setItem("breadcurmsPath", "/admin/questions/assigned-question")
    setViewClick(false)
  }, [offset, viewClick, callingComponent]);

  function tittleTurncate(description) {
    return description?.split(' ').splice(0, 7).join(' ');
  }
  async function searchQuestions(word) {
    let id = localStorage.getItem('UserLoginID');
    const response = await filterAssignedQuestionsApi({ id, searchOffset, word });
    if (response?.data?.results?.length === 0) {
      setNoQuestionsFound(true);
    } else {
      setClearSearch(true)
      if (response.data.next || filterQues.length <= response.data.count) {
        for (let i = 0; i <= filterQues.length + 1; ++i) {
          filterQues.pop()
        }
        setFilterQues([...filterQues, ...response.data.results]);
        setSearchOffset(searchOffset + 10);
      } else {
        let fliterLenght = filterQues.length
        for (let j = 0; j <= fliterLenght + 1; ++j) {
          filterQues.pop()
        }
        setFilterQues([...filterQues, ...response.data.results]);
      }
    }
    if (word == "") {
      setFilterQues(filterEmpty);
      setClearSearch(false)
      setNoQuestionsFound(false)
    }
  }

  function searchChange(search) {
    setSearchQues(search);
    setFilterQues([]);
    setSearchOffset(0);
    setNoQuestionsFound(false);
  }

  async function getAssignedQuestion() {
    try {
      if (!endGetRequests) {
        const response = await getAssignedQuestionsApi({ offset });
        if (response?.data?.results?.length == 0) {
          setTracker(false);
        } else {
          setTableData([...tableData, ...response.data.results]);
          setTracker(false);
        }
        if (!response.data.next) {
          setEndGetRequests(true);
        }
      }
      if (callingComponent) {
        if (filterEmpty) {
          setFilterQues(filterEmpty);
        }
        setOffset(0)
        const response = await getAssignedQuestionsApi({ offset });
        if (response?.data?.results?.length == 0 && tableData.length != 1) {

        } else {
          let tableLength = tableData.length
          for (let i = 0; i <= tableLength; ++i) {
            tableData.pop()
          }
          setTableData([...tableData, ...response.data.results]);
          setCallingComponent(false)
        }
      }
    } catch (err) {
      console.log('Error is ', err);
    }
  }

  function scrollToEnd() {
    if (!searchQues) {
      setOffset(offset + 10);
    }

    if (searchOffset >= 10) {
      searchQuestions(searchQues);
    }
  }

  window.onscroll = function scroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };

  const handleAssigned = () => {
    localStorage.setItem('assignedQuestion', true);
  };
  const [tableHead, setTableHead] = useState([
    {
      title: 'Title',
      render: (rowData) => {
        return (
          // <Link
          //   to={`/admin/view-question/${rowData.id}`}
          //   onClick={handleAssigned}
          // >
          rowData?.title_en
            ? <span>{`${tittleTurncate(rowData?.title_en)}...`}</span>
            : <span className="urdu-language font-urdu">{`${tittleTurncate(rowData?.title_ur)}...`}</span>
          // </Link>
        );
      },
      className: 'dash-ques-title',
    },
    {
      title: 'Topics',
      render: (rowData) => {
        return <span>{rowData?.category[0]?.name}</span>;
      },
    },
    {
      title: 'Created By',
      render: (rowData) => {
        return <span>{rowData?.created_by_name}</span>;
      },
    },
    {
      title: 'Created By Email',
      render: (rowData) => {
        return <span>{(rowData?.created_by_email)}</span>;
      },
    },
    {
      title: 'Date',
      render: (rowData) => {
        return <span>{moment(rowData?.updated_at).format('MMMM Do YYYY')}</span>;
      },
    },
    {
      title: 'Action',
      render: (rowData) => {
        return (
          <>
          </>
        );
      },
      className: "action-view",
    },
  ]);


  let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  const [viewSection, setViewSection] = useState([
    {
      path: "/admin/assigned-question-view",
      buttonTitle: "View Details",
      Icon: <BsEye />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      path: "/admin/assigned-edit-question",
      buttonTitle: "Edit",
      Icon: <FaRegEdit />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      isDeleted: true,
      buttonTitle: "Delete",
      Icon: <MdDelete />,
      ColorClass: "delete-question",
      isActive: isAdmin ? true : false
    }
  ]);
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    { name: "Assigned Questions", },
  ])
  const pageName = "Assigned Questions"
  const QuestionTitle = 'Dashboard > Assigned Questions';
  const messageData = "Assigned Question"
  const questionPath = "/admin/assigned-question-view/"
  const pageLink = "/admin/questions/assigned-question"
  const pageBreadcrum = "Assigned Question"
  const EditQuestionPage = "/admin/assigned-edit-question/"
  return (
    <>
      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>
          <div className="tables-breadcrums">
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
          </div>
          <div className="main-table-cotainer box-shadow">
            <div className="dash-table-top-bar">
              <div className="search-side">
                <h4>Questions Tables</h4>
              </div>
              <div className="SearchBox">
                <Input
                  placeholder="Search By Title"
                  value={searchQues}
                  onKeyPress={(e) =>
                    !searchQues
                      ? null
                      : e.key === 'Enter' && searchQuestions(searchQues)
                  }
                  onChange={(e) => {
                    searchChange(e.target.value)
                    searchQuestions(e.target.value)
                  }}
                />
                <div className="question-search-btn">
                  {clearSearch ? <ImCancelCircle className="cancel" onClick={() => {
                    searchQuestions("")
                    searchChange("")
                  }} /> : null}
                  <button
                    onClick={() => searchQuestions(searchQues)}
                    disabled={!searchQues}
                  >
                    <BsSearch />
                  </button>
                </div>
              </div>
            </div>
            <DashTable
              noQuestionsFound={noQuestionsFound}
              tableData={filterQues.length !== 0 ? filterQues : tableData}
              QuestionTitle={QuestionTitle}
              tableHead={tableHead}

              viewSection={viewSection}
              setViewClick={setViewClick}
              deletedApi={deleteQuestionsApi}
              messageData={messageData}
              questionPath={questionPath}
              setCallingComponent={setCallingComponent}
              pageLink={pageLink}
              pageBreadcrum={pageBreadcrum}
              EditQuestionPage={EditQuestionPage}
              setNavigationLoad={setNavigationLoad}
              setStatsCalling={setStatsCalling}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AssignedQuestion;
