import axiosApi from "../axios-config/axios";

export const postCommentsAPi = async (CommentsData) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `comments/`,
      method: "POST",
      bodyData: CommentsData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
