import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import "./App.css";
import "./responsive.css";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import QuestionDetailsPage from "./components/utils/QuestionDetails/QuestionDetailsPage";
import Login from "./components/Login/Login";
import AskQuestion from "./components/utils/AskQuestion/AskQuestion";
import About from "./components/utils/About/About";
import Contact from "./components/utils/Contact/Contact";
import Dashboard from "./components/Dashboard/Dashboard";
import TopicHome from "./components/Home/TopicHome";
import ForgetPassword from "./components/utils/userProfile/ForgetPassword";
import EmailForForget from "./components/utils/userProfile/EmailForForget";
import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";
import { ProtectedRoutes } from "./components/Protected-Routes/ProtectedRoutes";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../src/images/arbic-pattern.jpg";
const App = () => {
  const { t, i18n } = useTranslation();

  const [languageDashbaord, setLanguageDashbaord] = useState(false);
  const [englishActive, setEnglishActive] = useState(true);

  let language = localStorage.getItem("language");
  const handleLanguageChange = (lang) => {
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
    document.body.dir = i18n.dir();
    // document.getElementsByClassName('App').dir = i18n.dir();
  };

  const [admin, setAdmin] = useState(false);
  let history = useHistory();
  let location = history.location.pathname;
  if (localStorage.getItem("i18nextLng") == "en-US") {
    localStorage.setItem("language", "en");
  }
  function adminRoutes() {
    let path = location;
    return path.includes("/admin");
  }
  useEffect(() => {
    document.body.dir = i18n.dir();
    setLanguageDashbaord(false);
  }, [localStorage.getItem("language"), languageDashbaord]);
  const styleObj = {
    fontFamily: "Nafees Regular",
  };
  return (
    <>
      <div
        className={
          language === "ur" && !languageDashbaord ? "font-urdu" : "App"
        }
        style={language === "ur" && !languageDashbaord ? { styleObj } : null}
      // style={{ backgroundImage: `url(${BackgroundImage})` }}
      >

        {location !== "/login" && location !== "/signup" && !adminRoutes() && (
          <>
            <div className="lang-bar" dir="ltr">
              <div className="wrap-container">
                <div className="ayat-section">
                  <p className="font-urdu arbic-ayat">
                    فَسـئَلُوا اَهلَ الذِّكرِ اِن كُنتُم لَا تَعلَمُونَ‏
                  </p>
                  {language === "ur" ? (
                    <p className="ayat-translation font-urdu urdu-ayat-translation">
                      اب اگر تمہیں اس بات کا علم نہیں ہے تو جو علم والے ہیں ان
                      سے پوچھ لو
                    </p>
                  ) : (
                    <p className="ayat-translation">
                      So, ask the people (having the knowledge) of the Reminder
                      (the earlier Scriptures), if you do not know.
                    </p>
                  )}
                </div>
                <div className="language-button">
                  <button
                    className={
                      language === "en"
                        ? "lang-active"
                        : "english-toggle-button"
                    }
                    onClick={() => handleLanguageChange("en")}
                  >
                    English
                  </button>
                  <button
                    className={
                      language === "ur" ? "lang-active urdu-toggle-button" : ""
                    }
                    onClick={() => handleLanguageChange("ur")}
                  >
                    اردو
                  </button>
                </div>
              </div>
            </div>
            <Header />
          </>
        )}
        <Switch>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/topic/:topicID">
            <TopicHome />
          </Route>
          <Route path="/login">
            <Login />
          </Route>

          <ProtectedRoutes path="/admin">
            <Dashboard setLanguageDashbaord={setLanguageDashbaord} />
          </ProtectedRoutes>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/question-details/:id">
            <QuestionDetailsPage />
          </Route>
          <Route path="/ask-question">
            <AskQuestion />
          </Route>
          {/* <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route> */}
          <Route exact path="/forget-password">
            <ForgetPassword />
          </Route>
          <Route exact path="/email-verification">
            <EmailForForget />
          </Route>
          <Redirect exact from="/" to="/home" />
        </Switch>
      </div>
    </>
  );
};

export default App;
