import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import BreadCrumbs from "./components/BreadCrumbs";
import QuestionEditPageAdmin from "./QuestionEditPageAdmin";
import { useParams } from 'react-router-dom';
const EditPageQuestion = () => {

    const Link = useLocation();
    console.log("LinkDirection", Link?.state?.editPageLink)

    const params = useParams();
    const questionID = params.id;
    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: "New Questions",
                path: "/admin/new-question",
            },
            {
                name: "Question Deatil Page",
                path: `/admin/new-question-view/${questionID}`
            },
            { name: "Edit Questions", },
        ]

    const pageName = "Edit Questions"
    return (
        <>
            <BreadCrumbs
                breadcrum={breadcrum}
                pageName={pageName}
                linkPage={Link?.state?.editPageLink}
            />
            <QuestionEditPageAdmin idOfQuestion={questionID} />
        </>
    );
};
export default EditPageQuestion;
