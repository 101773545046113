import React, { useEffect, useState } from "react";
import BreadCrumbs from "./components/BreadCrumbs";
import MuftiEditUserProfile from "./MuftiEditUserProfile";
const DirectEditMiftiProfile = () => {
    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: "User",
                path: "/admin/user"
            },
            {
                name: "Edit User",
            },
        ]

    const pageName = "Edit User Profile"
    return (
        <>
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
            <MuftiEditUserProfile />
        </>
    );
};
export default DirectEditMiftiProfile;
