import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { patchQuestionsApi } from "./../../services/api/QuestionsApi";
import { getQuestionsDetailsApi } from "../../services/api/QuestionsApi";
import RequiredToolTip from "./components/RequiredToolTip";
import Button from "../utils/reuseableComponent/Button";
import TextEditor from "../utils/reuseableComponent/TextEditor/TextEditor";
import { BsPencilSquare } from "react-icons/bs";
import { convertToRaw } from "draft-js";
import Input from "../utils/reuseableComponent/Input";
import "./assests/DashboardQuestionDetailPage.css";
import { getPublicTopicsApi } from "./../../services/api/TopicsApi";
import { Link, useParams, useHistory } from "react-router-dom";

const QuestionEditPageAdmin = () => {
  // console.log("previous path", props.location)
  const params = useParams();
  const questionID = params.id;

  const linkParams = useLocation();
  const linkPage = linkParams.state?.linkPage;
  const Breadcrum = linkParams.state?.Breadcrum;

  let history = useHistory();

  const location = useLocation();

  useEffect(() => {
    getQuestionsDetails();
  }, []);

  async function getQuestionsDetails() {
    try {
      const responseTopics = await getPublicTopicsApi();
      setTopics(responseTopics.data);
      const response = await getQuestionsDetailsApi({ id: questionID });
      setQuestion(response.data);
      console.log(" Edit Question ", response.data);
      setTopicId(response.data.topic);
      setEnglishEditTitle(response.data.title_en);
      setUrduEditTitle(response.data.title_ur);
      setEditorContent(response.data.description_en);
      setEditorContentUrdu(response.data.description_ur);
      await setQuestionProgress(response.data.progress_status);
      console.log("editorContentUrdu", response.data.description_ur);
      if (response.data.description_en) {
        let textInEnglishObject = JSON.parse(response.data.description_en)
          .blocks[0].text;
        setEditQuestion(textInEnglishObject);
      }
      if (response.data.description_ur) {
        let textInUrduObject = JSON.parse().blocks[0].text;
        setEditQuestionUrdu(textInUrduObject);
      }
      setQuestionDetailID(questionID);
      setDirectPath(location?.state?.prevPath);
      console.log("-------" + Question.data.progress_status)

    } catch (error) {
      console.log("Error", error);
    }
  }
  // console.log("History ", history)
  const Back = () => {
    history.goBack();
    setUrduEdit(false);
    setEnglishEdit(false);
  };
  const [Question, setQuestion] = useState({});

  const [editorContent, setEditorContent] = useState("");
  const [editorContentUrdu, setEditorContentUrdu] = useState("");
  const [editQuestion, setEditQuestion] = useState("");
  const [editQuestionUrdu, setEditQuestionUrdu] = useState("");
  const [englishEditTitle, setEnglishEditTitle] = useState("");
  const [urduEditTitle, setUrduEditTitle] = useState("");
  const [questionDetailID, setQuestionDetailID] = useState(true);
  const [questionProgress, setQuestionProgress] = useState(4);
  const [topics, setTopics] = useState([]);
  const [topicId, setTopicId] = useState("");
  const [topicError, setTopicError] = useState("");

  const [urduEdit, setUrduEdit] = useState(false);
  const [englishEdit, setEnglishEdit] = useState(false);

  const [errorEditQuestion, setErrorEditQuestion] = useState("");
  const [errorEditQuestionUrdu, setErrorEditQuestionUrdu] = useState("");
  const [errorEnglishEditTitle, setErrorEnglishEditTitle] = useState("");
  const [errorUrduEditTitle, setErrorUrduEditTitle] = useState("");

  const [directPath, setDirectPath] = useState("");

  let questionId = useParams();
  let ID = questionId.id;

  async function EditQUestion(e) {
    e.preventDefault();
    console.log("Enter Submit Button");
    try {
      console.log("English Description", editorContent);
      console.log("Urdu Description", editorContentUrdu);
      console.log("English Title", englishEditTitle);
      console.log("Urdu Title", urduEditTitle);
      console.log("englishEdit ", englishEdit);
      console.log("englishEdit ", urduEdit);
      if (
        englishEditTitle &&
        urduEditTitle &&
        editQuestionUrdu &&
        editQuestion &&
        topicId &&
        topicId != "Select the topic"
      ) {
        console.log("enter in response");
        if (englishEdit) {
          var editorData =
            editorContent && JSON.stringify(convertToRaw(editorContent));
        } else {
          var editorData = editorContent;
        }
        if (urduEdit) {
          var editorDataUrdu =
            editorContentUrdu &&
            JSON.stringify(convertToRaw(editorContentUrdu));
        } else {
          var editorDataUrdu = editorContentUrdu;
        }
        const data = {
          title: Question.title,
          topic: Question.topic,
          description_en: editorData,
          description_ur: editorDataUrdu,
          title_en: englishEditTitle,
          title_ur: urduEditTitle,
          topic: topicId,
          progress_status: questionProgress
        };
        const response = await patchQuestionsApi(data, ID);
        console.log("Question Edit ", response);
        if (response.status == "200") {
          // setCallUseEffact(true);
          setEditQuestion(false);
          setErrorEditQuestion("");
          history.goBack();
          // history.replace(`/admin/view-question/${questionID}`);
        }
      } else {
        if (!englishEditTitle) setErrorEnglishEditTitle("Feild is required");
        if (!urduEditTitle) setErrorUrduEditTitle(" فیلڈ ضروری ہے");
        if (!editQuestion) setErrorEditQuestion("Feild is required");
        if (!editQuestionUrdu) setErrorEditQuestionUrdu(" فیلڈ ضروری ہے");
        if (!topicId || topicId == "Select the topic")
          setTopicError("Please select Topic");
      }
    } catch (error) {
      console.log("Error", error);
      console.log("English Description", editorContent);
      console.log("Urdu Description", editorContentUrdu);
      console.log("English Title", englishEditTitle);
      console.log("Urdu Title", urduEditTitle);
    }
  }

  console.log(" topicId ", topicId);
  let Localoptions = ["مجیب", "نائب مفتی", "مفتی", "ٹرانسلیٹر"]
  let QuestionDescription = Question.description_en;
  let QuestionDescriptionUrdu = Question.description_ur;
  function handleStatusChange(e) {
    let selected = e.target.value;
    let selectedIndex = 0;
    Localoptions.filter((value, index) => {
      if (value == selected) {
        selectedIndex = index;
      }
    })
    setQuestionProgress(selectedIndex + 1);
  }
  return (
    <>
      <section className="detail-question-bar edit-page">
        <div className="row">
          <div className="col-md-12">
            <>
              <p className="question-id">
                Ledger Number : <span>{Question.ledger_number}</span>
              </p>

              <div className="div-sepration"></div>

              <div className="select-topics">
                <h2 className="title bold-haeding">Topic :</h2>
                <select
                  onChange={(e) => {
                    if (topicId != null) {
                      setTopicError("");
                    }
                    setTopicId(e.target.value);
                  }}
                  className="topic-dropdown"
                  value={topicId}
                >
                  <option>Select the topic</option>
                  {topics &&
                    topics.map((topic) =>
                      topic.children.map((child, index) => (
                        <option key={index} value={child.id}>
                          {child.name_en}
                        </option>
                      ))
                    )}
                </select>
              </div>

              <p className="error">{topicError}</p>

              <div className="question-title">
                <h2 className="title bold-haeding">Title :</h2>
                <div className="wrap-text-Title">
                  <Input
                    type="text"
                    placeholder={"Enter Your Title"}
                    value={englishEditTitle}
                    onChange={(e) => {
                      setEnglishEditTitle(e.target.value);
                      if (!englishEditTitle) {
                        setErrorEnglishEditTitle("");
                      }
                    }}
                  />
                </div>
              </div>
              <p className="error">{errorEnglishEditTitle}</p>
              <div className="question-question">
                <h2 className="title bold-haeding">Re-write Question : </h2>
              </div>
              <p className="error">{errorEditQuestion}</p>
              <div className="wrap-text-editor answer-description">
                <TextEditor
                  sendToEditor={setEditorContent}
                  Edit={QuestionDescription}
                  validationForEmpty={setEditQuestion}
                  isEdit={setEnglishEdit}
                />
                <div className="text-editor-submit-button"></div>
              </div>
              <div className="div-sepration"></div>

              <div className="question-title urdu-language font-urdu">
                <h2 className="title bold-haeding">:عنوان</h2>

                <div className="wrap-text-Title">
                  <Input
                    type="text"
                    placeholder={"اپنا عنوان درج کریں"}
                    value={urduEditTitle}
                    onChange={(e) => {
                      setUrduEditTitle(e.target.value);
                      if (!urduEditTitle) {
                        setErrorUrduEditTitle("");
                      }
                    }}
                    className="urdu-language"
                  />
                </div>
              </div>
              <p className="error urdu-language font-urdu">
                {errorUrduEditTitle}
              </p>

              <div className="question-question urdu-language font-urdu">
                <h2 className="title bold-haeding">:سوال دوبارہ لکھیں</h2>
              </div>
              <p className="error urdu-language font-urdu">
                {errorEditQuestionUrdu}
              </p>
              <div className="wrap-text-editor urdu-font">
                <TextEditor
                  sendToEditor={setEditorContentUrdu}
                  Edit={QuestionDescriptionUrdu}
                  validationForEmpty={setEditQuestionUrdu}
                  isEdit={setUrduEdit}
                />
              </div>
              <div className="div-sepration"></div>
              <div className="submited-button">
                <Button
                  title="Save"
                  className="Save-Button"
                  onClick={EditQUestion}
                />
                <Button
                  title="Cancel"
                  className="cancel-button"
                  onClick={Back}
                />
              </div>
            </>
          </div>
        </div>
      </section>
    </>
  );
};
export default QuestionEditPageAdmin;
