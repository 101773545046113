import React from "react";
import DashboardQuestionDetailPage from "./DashboardQuestionDetailPage";
const NewQuestionDetailPage = ({ setNavigationLoad }) => {
    let isAssigned = false
    let pageBreadcrum = "New Question"
    let pageLink = "/admin/new-question"
    let previousLink = "/admin/new-question-view/"
    return (
        <>
            <DashboardQuestionDetailPage
                isAssigned={isAssigned}
                pageBreadcrum={pageBreadcrum}
                pageLink={pageLink}
                previousLink={previousLink}
                setNavigationLoad={setNavigationLoad}
            />
        </>
    );
};
export default NewQuestionDetailPage;
