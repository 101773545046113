import React, { useState, useEffect } from "react";
import {
  useRouteMatch,
  Link,
  Route,
  Switch,
  NavLink,
  useParams,
} from "react-router-dom";
import Input from "../utils/reuseableComponent/Input";
import {
  getTopicQuestionsApi,
  filterQuestionsApi,
} from "../../services/api/QuestionsApi";
import QuestionsTable from "../utils/QuestionTable/QuestionsTable";
import { trackPromise } from "react-promise-tracker";
import { BeatLoader } from "react-spinners";
import { BsSearch } from "react-icons/bs";
import axios from "axios";

const TopicHome = () => {
  const params = useParams();
  const topicID = params.topicID;
  const topicName = params.topicName;

  const [topicQuestions, setTopicQuestions] = useState([]);
  const [searchQues, setSearchQues] = useState("");
  const [tracker, setTracker] = useState(true);
  const [offset, setOffset] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const [filterQues, setFilterQues] = useState([]);
  const [activeTopicId, setActiveTopicId] = useState(null);

  useEffect(() => {
    console.log("outside topicID", topicID);
    console.log("outside ACtive", activeTopicId);
    if (topicID != activeTopicId) {
      console.log("topicID", topicID);
      console.log("ACtive", activeTopicId);
      setOffset(0);
      setTopicQuestions([]);
      trackPromise(getQuestions());
    } else {
      trackPromise(getQuestions());
    }
  }, [topicID, offset]);

  function scrollToEnd() {
    if (!searchQues) {
      setOffset(offset + 10);
    }
    if (searchOffset >= 10) {
      searchQuestions(searchQues);
    }
  }

  window.onscroll = function scroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };

  function searchChange(search) {
    setSearchQues(search);
    setFilterQues([]);
    setSearchOffset(0);
  }

  async function searchQuestions(word) {
    const response = await filterQuestionsApi({ searchOffset, word });
    console.log("response search", response);
    if (response.data.next || filterQues.length <= response.data.count) {
      setFilterQues([...filterQues, ...response.data.results]);
      setSearchOffset(searchOffset + 10);
    } else {
      setFilterQues([...filterQues, response.data.results]);
    }
  }
  console.log("filterQues", filterQues);

  async function getQuestions() {
    setActiveTopicId(topicID);
    const response = await getTopicQuestionsApi({ offset, topicID });
    console.log("topicQues", response.data.results);
    if (topicID == activeTopicId) {
      setTopicQuestions([...topicQuestions, ...response.data.results]);
    } else {
      setTopicQuestions(response.data.results);
    }
    setTimeout(() => setTracker(false), 500);
  }

  return (
    <section className="container min-h-100">
      <div className="main">
        <div className="tabs-search-bar">
          <div className="SearchBox">
            <Input
              placeholder="Search"
              value={searchQues}
              onChange={(e) => searchChange(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && searchQuestions(searchQues)}
            ></Input>
            <button
              onClick={() => searchQuestions(searchQues)}
              className="question-search-btn"
            >
              <BsSearch />
            </button>
          </div>
        </div>
        <div>
          {tracker ? (
            <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
              <BeatLoader
                type="ThreeDots"
                color="rgb(195, 156, 51)"
                size="20px"
                margin="3px"
              />
              {/* </div>) : (<QuestionsTable data={topicQuestions} />)} */}
            </div>
          ) : searchQues ? (
            <QuestionsTable data={filterQues} />
          ) : (
            <QuestionsTable data={topicQuestions} />
          )}
        </div>
      </div>
    </section>
  );
};

export default TopicHome;
