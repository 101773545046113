import { useState } from 'react';
import { getToken, onMessageListener } from './firebase';
import { Toast } from 'react-bootstrap';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assests/Notification.css";

const Notification = ({ setNavigationLoad }) => {
    const [show, setShow] = useState(false);
    const [notificationSubtitle, setNotificationSubtitle] = useState("");
    const [notificationLink, setNotificationLink] = useState("");
    const [notificationTime, setNotificationTime] = useState("");
    const [notificationTittle, setNotificationTittle] = useState("");
    const [browserNotificationStop, setBrowserNotificationStop] = useState(false);
    onMessageListener().then(payload => {
        setShow(true);
        setBrowserNotificationStop(false)
        if (payload?.notification) {
            setBrowserNotificationStop(true)
        }
        setNavigationLoad(true)
        if (localStorage.getItem("isAssigned") == "true") {
            setNavigationLoad(true)
        }
        let timeVariable = payload?.data?.time
        setNotificationTittle(payload.notification.title)
        setNotificationTime(payload?.data?.time.slice(10, 20))
        setNotificationLink(payload?.data?.link)
        setNotificationSubtitle(payload?.data?.subtitle)
    }).catch(err => console.log('failed: ', err));

    return (
        <>
            {browserNotificationStop ? <div className="Notification-Bar">
                <Toast onClose={() => setShow(false)} show={show} delay={8000} autohide style={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    minWidth: 200,
                }}>
                    <Toast.Header>
                        <Link to={notificationLink}>
                            <strong className="mr-auto">{notificationTittle}</strong>
                        </Link >
                    </Toast.Header>
                    <Toast.Body>
                        {notificationSubtitle}
                        <small>{notificationTime}</small>
                    </Toast.Body>
                </Toast>
            </div> : null}
        </>
    );

}

export default Notification;