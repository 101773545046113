import React, { useState, useEffect } from "react";
import Button from "../utils/reuseableComponent/Button";
import TextArea from "./components/TextArea";
import "../Dashboard/assests/GrandMuftiTopic.css";
import Input from "../utils/reuseableComponent/Input";
import { getTopicsApi, deleteTopicsApi } from "./../../services/api/TopicsApi";
import { useParams } from "react-router-dom";
import PopUpMessage from "./components/PopUpMessage";
import { useHistory } from "react-router-dom";
import {
  getChilderTopics,
  patchTopicApi,
} from "./../../services/api/TopicsApi";
import { BeatLoader } from "react-spinners";
import RequiredToolTip from "./components/RequiredToolTip";
import { AiOutlineInfo } from "react-icons/ai";
const EditTopic = () => {
  const params = useParams();
  const TopicsID = params.id;
  const [topics, setTopics] = useState([]);
  const [tracker, setTracker] = useState(true);
  const [name, setName] = useState("");
  const [nameUrdu, setNameUrdu] = useState("");
  const [topicId, setTopicId] = useState("");
  const [visible, setVisible] = useState("");
  const [active, setActive] = useState("");
  const [textArea, setTextArea] = useState("");
  const [textAreaUrdu, setTextAreaUrdu] = useState("");

  const [topicError, setTopicError] = useState("");
  const [nameError, setNameError] = useState("");
  const [nameUrduError, setNameUrduError] = useState("");
  const [visibleError, setVisibleError] = useState("");
  const [activeError, setActiveError] = useState("");
  const [textAreaError, setTextAreaError] = useState("");
  const [textAreaUrduError, setTextAreaUrduError] = useState("");

  const [deleteTopics, setDeleteTopics] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [Message, setMessage] = useState("");
  const [language, setLanguage] = useState(true);

  let history = useHistory();
  const Back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  async function getCurrentTopicData() {
    try {
      const response = await getChilderTopics(TopicsID);
      console.log("topics object => ", response.data);
      setNameUrdu(response.data.name_ur)
      setName(response.data.name)
      setActive(response.data.is_active);
      setVisible(response.data.is_visible_frontend);
      setTextArea(response.data.description)
      setTextAreaUrdu(response.data.description_ur)
      setTopicId(response.data.parent);
      setTracker(false);
    } catch (err) {
      console.log("Error is ", err);
    }
  }
  useEffect(() => {
    getTopics();
    getCurrentTopicData();
  }, [language]);

  async function getTopics() {
    const response = await getTopicsApi();
    console.log("response => ", response);
    setTopics(response?.data);
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      name &&
      textArea &&
      topicId != "Select the topic" &&
      visible != "Select the option" &&
      active != "Select the option" &&
      nameUrdu
      && textAreaUrdu
    ) {
      console.log("active => ", active);
      try {
        if (deleteTopics) {
          const response = await deleteTopicsApi(TopicsID);
          console.log("Topic Delete ", response);
          setMessageAlert(true);
          setMessage("Your topic is deleted");
        } else {
          var TopicData = {
            name: name,
            name_ur: nameUrdu,
            is_active: active,
            is_visible_frontend: visible,
            description: textArea,
            description_ur: textAreaUrdu,
            parent: topicId,
          };
          const response = await patchTopicApi(TopicData, TopicsID);
          console.log("response => ", response);
          if (response.status == "200") {
            setMessageAlert(true);
            setMessage("Your topic is edit");
          }
        }
      } catch (err) {
        const { response } = err;
        console.log("error");
      }
    } else {
      if (topicId == "Select the topic")
        setTopicError("You must selected correct topic type");
      if (!name) setNameError("Topic feild is required");
      if (!nameUrdu) setNameUrduError("عنوان نام ضروری ہے");
      if (!visible || visible == "Select the option")
        setVisibleError("Topic feild is required");
      if (!active || active == "Select the option")
        setActiveError("Topic feild is required");
      if (!textArea) setTextAreaError("Topic feild is required");
      if (!textAreaUrdu) setTextAreaUrduError("عنوان کی وضاحت ضروری ہے");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const languageChange = (e) => {
    e.preventDefault()
    setLanguage(!language)
  }
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="wrap-grand-mufti main">
          <div className="language-dedication">
            <h4>Edit Topic <RequiredToolTip Text="Use this form to create the topics of categorize the question" Direction="right" icon={<AiOutlineInfo />} className="information-ttoltip" /></h4>
          </div>
          <div className="grand-mufti-input-topic">
            <h6 >Name</h6>
            <Input
              placeholder="Topic name "
              value={name}
              onChange={(e) => {
                if (name != null) {
                  setNameError("");
                }
                setName(e.target.value);
              }}
            />
            {nameError ? <p className="email-error">{nameError}</p> : null}
          </div>
          <div className="grand-mufti-input-topic urdu-language font-urdu">
            <h6> نام</h6>
            <Input
              placeholder="اپنا نام درج کریں"
              value={nameUrdu}
              onChange={(e) => {
                if (nameUrdu != null) {
                  setNameUrduError("");
                }
                setNameUrdu(e.target.value);
              }}
              className="urdu-language"
            />
            {nameUrduError ? <p className="email-error font-urdu">{nameUrduError}</p> : null}
          </div>

          <div className="grand-mufti-description">
            <h6>Description </h6>
            <TextArea
              rows="6"
              cols="60"
              placeholder="Enter Your Description"
              value={textArea}
              onChange={(e) => {
                if (textArea != null) {
                  setTextAreaError("");
                }
                setTextArea(e.target.value);
              }}
            />
            {textAreaError ? (
              <p className="email-error">{textAreaError}</p>
            ) : null}
          </div>
          <div className="grand-mufti-description urdu-language font-urdu">
            <h6> تفصیل</h6>
            <TextArea
              rows="6"
              cols="60"
              placeholder="اپنی تفصیل درج کریں"
              value={textAreaUrdu}
              className="urdu-language"
              onChange={(e) => {
                if (textAreaUrdu != null) {
                  setTextAreaUrduError("");
                }
                setTextAreaUrdu(e.target.value);
              }}
            />
            {textAreaUrduError ? (
              <p className="email-error font-urdu">{textAreaUrduError}</p>
            ) : null}
          </div>

          <div className="right-side-form">
            <div className="grand-mufti-select-topic">
              <h6>Type </h6>
              <select
                onChange={(e) => {
                  if (topicId != null) {
                    setTopicError("");
                  }
                  setTopicId(e.target.value);
                }}
                value={topicId}
                className="topic-dropdown"
              >
                <option value="">Select the topic </option>
                {topics &&
                  topics.map((topic) => (
                    <>
                      <option key={topic.id} value={topic.id}>
                        {topic.name_en} , {topic.name_ur}
                      </option>
                    </>
                  ))}
              </select>
              {topicError ? <p className="email-error">{topicError}</p> : null}
            </div>
            <div className="grand-mufti-select-topic">
              <h6>Status</h6>
              <select
                className="topic-dropdown"
                onChange={(e) => {
                  if (active != null) {
                    setActiveError("");
                  }
                  setActive(e.target.value);
                }}
                value={active}
              >
                <option>Select the option</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              {activeError ? <p className="email-error">{activeError}</p> : null}
            </div>

            <div className="grand-mufti-select-topic">
              <h6>Visiblity</h6>
              <select
                className="topic-dropdown"
                onChange={(e) => {
                  if (visible != null) {
                    setVisibleError("");
                  }
                  setVisible(e.target.value);
                }}
                value={visible}
              >
                <option>Select the option </option>
                <option value="true">Visible</option>
                <option value="false">Hidden</option>
              </select>
              {visibleError ? (
                <p className="email-error">{visibleError}</p>
              ) : null}
            </div>
          </div>
          <div className="grand-mufti-button">
            <Button
              title="Back"
              onClick={Back}
              className="grand-mufti-create-topic"
            />
            <Button title="Edit" className="grand-mufti-create-topic" />
            <Button
              title="Delete"
              className="delete-user-button grand-mufti-create-topic"
              onClick={() => setDeleteTopics(true)}
            />
          </div>
          <PopUpMessage
            LinkURL="/admin/topic"
            message={Message}
            show={messageAlert}
            onHide={() => setMessageAlert(false)}
          />
        </div>
      </form>
    </>
  );
};
export default EditTopic;
