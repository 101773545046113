import React from "react";
import { BsPersonFill } from "react-icons/bs";
import Button from "../reuseableComponent/Button";
import "./UserProfile.css";
import DefaultUserImg from "../../../images/defaultuserimg.png";
import { Link } from "react-router-dom";
const UserProfileDetail = ({ profileDetail, stats }) => {
  return (
    <div className="user-data">
      <div className="user-details">
        {profileDetail.map((profileDetail, index) => {
          return (
            <div key={index}>
              <ul>
                <li>
                  <span>User Name </span>
                  <br />
                  {profileDetail.username}
                </li>
                <li>
                  <span>Name </span>
                  <br />
                  {profileDetail.first_name} {profileDetail.last_name}
                </li>
                <li>
                  <span>Email</span>
                  <br />
                  {profileDetail.email}
                </li>
              </ul>
            </div>
          );
        })}
      </div>

      {stats.map((stats, index) => {
        return (
          <div key={index} className="user-questions">
            <ul>
              <li>
                Total approved <br />
                Question
                <br />
                <span>{stats.response.total_approved_ansers}</span>
              </li>
              <li>
                Replied <br />
                Question
                <br />
                <span>{stats.response.total_answers}</span>
              </li>
              <li>
                Reject <br />
                Question
                <br />
                <span>{stats.response.total_rejected_answers}</span>
              </li>
            </ul>
            <Link
              className="blue-btn edit-profile-btn"
              to="/admin/editprofile"
              id="Edit"
            >
              Edit Profile
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default UserProfileDetail;
