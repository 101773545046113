import axiosApi from '../axios-config/axios';
export const getUserStatsApi = async ({ id }) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `stats/?user=${id}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
