import React from "react";

const Button = ({ onClick, type, title, id, className, name, onSubmit, href, icon }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      id={id}
      className={className}
      name={name}
      onSubmit={onSubmit}
      href={href}
    >
      {icon}
      {title}
    </button>
  );
};

export default Button;
