import React, { useEffect, useState } from 'react';
import reactDom from 'react-dom';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import LoginLogo from '../../images/login-logo.png';
import Button from '../utils/reuseableComponent/Button';
import Input from '../utils/reuseableComponent/Input';
import { BsX } from 'react-icons/bs';
import { BsCheck } from 'react-icons/bs';
import { FaRegSmile } from 'react-icons/fa';
import Footer from '../Footer/Footer';
import validator from 'validator';
import { API_URL } from '../../services/axios-config/axios';
import axios from 'axios';
import { getLoginUserApi } from './../../services/api/UsersApi';
import './Login.css';
import { useTranslation } from 'react-i18next';
const Login = () => {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };

  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");
  const [errorUserPass, setErrorUserPass] = useState("");

  const [checkUser, setCheckUser] = useState(false);
  const [emailError, setEmailError] = useState('');

  const Demo = { Email: 'demo', Password: '12345' };
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (userEmail && userPass) {
        let data = { email: userEmail, password: userPass };
        const response = await axios.post(`${API_URL}/auth/login/`, data);
        localStorage.setItem('LoginUserKey', response?.data?.key);
        if (response.status == 200) {
          const response = await getLoginUserApi();
          localStorage.setItem('UserLoginID', response?.data?.pk);
          localStorage.setItem('loggedin', true);
          history.replace('/admin');
        }
      } else {
        if (!userPass) setErrorUserPass('Password is required');
        if (!userEmail) setEmailError('Email is required');
      }
    } catch (err) {
      setCheckUser(true);
      if (validator.isEmail(userEmail)) {
      } else if (userEmail === '') {
        setEmailError('Enter Email Address!');
      } else {
        setEmailError('Enter Valid Email!');
      }
      console.log('Error is', err);
    }
  };
  const onSubmitValidation = () => { };
  useEffect(() => {
    i18n.changeLanguage('en');
    localStorage.setItem('language', 'en');
    console.log('Called login');
  }, [localStorage.getItem("language")]);
  return (
    <div className="jumbo-box">
      <div className="login-logo">
        <Link to="/home">
          <img src={LoginLogo} />
        </Link>
      </div>

      <div className="login-box">
        <div className="login-inner-box">
          <h3>Login</h3>

          <form onSubmit={onSubmit}>
            {checkUser ? (
              <p className="red-alert">
                <BsX /> Invalid Username Or Password!
              </p>
            ) : null}

            <Input
              type="text"
              className="login-email"
              placeholder="Email Address"
              value={userEmail}
              onChange={(e) => {
                if (userEmail != null) {
                  setEmailError('');
                }

                setUserEmail(e.target.value);
              }}
            />

            {emailError ? (
              <span className="error">
                <BsX /> {emailError}
              </span>
            ) : emailError ? (
              <span className="error">
                <BsX /> {emailError}
              </span>
            ) : null}

            <Input
              type="password"
              className="login-password"
              placeholder="Password"
              value={userPass}
              onChange={(e) => {
                if (userPass != null) {
                  setErrorUserPass('');
                }
                setUserPass(e.target.value);
              }}
              Required
            />
            {errorUserPass ? (
              <span className="error">
                <BsX /> {errorUserPass}
              </span>
            ) : null}
            <br />
            <Button
              type="submit"
              onClick={onSubmitValidation}
              className="login"
              title="Login"
            />
          </form>
          <p>
            <Link to="/email-verification">Forget Password</Link>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
