import React, { useState, useEffect } from 'react';
import DashTable from './components/DashTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md'
import { ImCancelCircle } from 'react-icons/im';
import BreadCrumbs from './components/BreadCrumbs';
import {
  getUserRejectedQuestionsApi,
  deleteQuestionsApi,
  filterUserRejectedQuestionsApi,
} from '../../services/api/QuestionsApi';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import axios from 'axios';
import { BsSearch } from 'react-icons/bs';
import Input from '../utils/reuseableComponent/Input';
import Button from '../utils/reuseableComponent/Button';
import { BeatLoader } from 'react-spinners';
const RejectedQuestion = ({ setNavigationLoad }) => {
  const [tableData, setTableData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const [filterQues, setFilterQues] = useState([]);
  const [searchQues, setSearchQues] = useState('');
  const [endGetRequests, setEndGetRequests] = useState(false);
  const [tracker, setTracker] = useState(true);
  const [viewClick, setViewClick] = useState(false);
  const [noQuestionsFound, setNoQuestionsFound] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [filterEmpty, setFilterEmpty] = useState([]);
  const [callingComponent, setCallingComponent] = useState(false);
  const [statsCalling, setStatsCalling] = useState(false);
  useEffect(() => {
    getRejectedQuestions();
    console.log('Reject => ', 'enter');
    localStorage.setItem('QuestionCondition', true);
    localStorage.setItem('Appect', true);
    console.log('Sub Questins', localStorage.getItem('QuestionCondition'));
    console.log('Comments', localStorage.getItem('Appect'));
    localStorage.setItem("Breadcurms", "Reject Questions")
    localStorage.setItem("breadcurmsPath", "/admin/questions/rejected-questions")
    localStorage.setItem("reAgain", "true")
    setViewClick(false)
  }, [offset, viewClick, callingComponent]);

  function tittleTurncate(description) {
    return description.split(' ').splice(0, 7).join(' ');
  }

  async function searchQuestions(word) {
    let id = localStorage.getItem('UserLoginID');
    const response = await filterUserRejectedQuestionsApi({
      searchOffset,
      word,
      id,
    });

    if (response?.data?.results.length === 0) {
      setNoQuestionsFound(true);
    } else {
      setClearSearch(true)
      if (response.data.next || filterQues.length <= response.data.count) {
        for (let i = 0; i <= filterQues.length + 1; ++i) {
          filterQues.pop()
        }
        setFilterQues([...filterQues, ...response.data.results]);
        setSearchOffset(searchOffset + 10);
      } else {
        let fliterLenght = filterQues.length
        for (let j = 0; j <= fliterLenght + 1; ++j) {
          filterQues.pop()
        }
        setFilterQues([...filterQues, ...response.data.results]);
      }
    }
    if (word == "") {
      setFilterQues(filterEmpty);
      console.log(" filterQues ", filterQues)
      setClearSearch(false)
      console.log("Clear Search", clearSearch)
      setNoQuestionsFound(false)
    }
  }

  function searchChange(search) {
    setSearchQues(search);
    setFilterQues([]);
    setSearchOffset(0);
    setNoQuestionsFound(false);
  }

  async function getRejectedQuestions() {
    try {
      let id = localStorage.getItem('UserLoginID');
      console.log('user id => ', id);
      if (!endGetRequests) {
        const response = await getUserRejectedQuestionsApi({ id, offset });
        if (response?.data?.results?.length == 0) {
          setTracker(false);
        } else {
          console.log('assigned response ', response);
          setTableData([...tableData, ...response.data.results]);
          console.log('tableData ', tableData);
          setTracker(false);
        }
        if (!response.data.next) {
          setEndGetRequests(true);
        }
      }
      if (callingComponent) {
        if (filterEmpty) {
          setFilterQues(filterEmpty);
        }
        setOffset(0)
        const response = await getUserRejectedQuestionsApi({ offset });
        if (response?.data?.results?.length == 0 && tableData.length != 1) {

        } else {
          let tableLength = tableData.length
          for (let i = 0; i <= tableLength; ++i) {
            tableData.pop()
          }
          setTableData([...tableData, ...response.data.results]);
          console.log("tableData =>", tableData)
          console.log("response ", response)
          setCallingComponent(false)
        }
      }
    } catch (err) {
      console.log('Error is ', err);
    }
  }

  function scrollToEnd() {
    if (!searchQues) {
      setOffset(offset + 10);
    }

    if (searchOffset >= 10) {
      searchQuestions(searchQues);
    }
  }

  window.onscroll = function scroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };
  const handleRejected = () => {
    console.log('Reject => ', 'enter');
    localStorage.setItem('QuestionCondition', true);
    localStorage.setItem('Appect', true);
    console.log('Sub Questins', localStorage.getItem('QuestionCondition'));
    console.log('Comments', localStorage.getItem('Appect'));
  };
  const [tableHead, setTableHead] = useState([
    {
      title: 'Title',
      render: (rowData) => {
        return (
          // <Link
          //   to={`/admin/questions/answer-review/${rowData.id}`}
          //   onClick={handleRejected}
          // >
          <>
            <span>{`${tittleTurncate(rowData?.title_en)}...`}</span>
          </>
          // </Link>
        );
      },
      className: 'dash-ques-title',
    },
    {
      title: 'Topics',
      render: (rowData) => {
        return <span>{rowData.category[0].name}</span>;
      },
    },
    {
      title: 'Created By',
      render: (rowData) => {
        return <span>{rowData?.created_by_name}</span>;
      },
    },
    {
      title: 'Created By Email',
      render: (rowData) => {
        return <span>{(rowData?.created_by_email)}</span>;
      },
      className: 'email-view',
    },
    {
      title: 'Date',
      render: (rowData) => {
        return <span>{moment(rowData.updated_at).format('MMMM Do YYYY')}</span>;
      },
    },
    {
      title: 'View',
      render: (rowData) => {
        return (
          <>
          </>
        );
      },
      className: 'action-view',
    },
  ]);
  let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  const [viewSection, setViewSection] = useState([
    {
      path: "/admin/questions/reject-answer-review",
      buttonTitle: "View Details",
      Icon: <BsEye />,
      ColorClass: "View-detail-page",
      isActive: true
    },
    {
      isDeleted: true,
      buttonTitle: "Delete",
      Icon: <MdDelete />,
      ColorClass: "delete-question",
      isActive: isAdmin ? true : false
    }
  ]);

  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    { name: "Reject Questions", },
  ])
  const pageName = "Reject Questions"
  const QuestionTitle = 'Dashboard > Rejected Questions';
  const messageData = "Rejected Question"
  const questionPath = "/admin/questions/reject-answer-review/"

  const pageTableName = "Rejected Question"
  const pageTableLink = "/admin/questions/rejected-questions"
  const previousPage = "/admin/questions/reject-answer-review/"

  return (
    <>
      {tracker ? (
        <div className="dash-preloader">
          <BeatLoader
            type="ThreeDots"
            color="rgb(195, 156, 51)"
            size="20px"
            margin="3px"
          />
        </div>
      ) : (
        <>
          <div className="tables-breadcrums">
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
          </div>
          <div className="main-table-cotainer box-shadow">
            <div className="dash-table-top-bar">
              <div className="search-side">
                <h4>Questions Tables</h4>
              </div>
              <div className="SearchBox">
                <Input
                  placeholder="Search By Title"
                  value={searchQues}
                  onKeyPress={(e) =>
                    !searchQues
                      ? null
                      : e.key === 'Enter' && searchQuestions(searchQues)
                  }
                  onChange={(e) => {
                    searchChange(e.target.value)
                    searchQuestions(e.target.value)
                  }}
                />
                <div className="question-search-btn">
                  {clearSearch ? <ImCancelCircle className="cancel" onClick={() => {
                    searchQuestions("")
                    searchChange("")
                  }} /> : null}
                  <button
                    onClick={() => searchQuestions(searchQues)}
                    disabled={!searchQues}
                  >
                    <BsSearch />
                  </button>
                </div>
              </div>
            </div>
            <DashTable
              noQuestionsFound={noQuestionsFound}
              tableData={filterQues.length !== 0 ? filterQues : tableData}
              QuestionTitle={QuestionTitle}
              tableHead={tableHead}

              setViewClick={setViewClick}
              viewSection={viewSection}

              deletedApi={deleteQuestionsApi}
              messageData={messageData}
              questionPath={questionPath}
              setCallingComponent={setCallingComponent}

              pageTableName={pageTableName}
              pageTableLink={pageTableLink}
              previousPage={previousPage}
              setNavigationLoad={setNavigationLoad}

              setStatsCalling={setStatsCalling}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RejectedQuestion;
