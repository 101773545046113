import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assests/AdminWidget.css";
import adminImage from "../../../images/dashboard-images/admin-img.png";
import { getLoginUserApi } from "../../../services/api/UsersApi";
import { postDeviceDeactiveApi } from "../../../services/api/DeviceApi";
import { FaUserAlt } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
const AdminWidget = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    getLoginUser();
  }, []);
  let history = useHistory();
  async function getLoginUser() {
    try {
      const response = await getLoginUserApi();
      setUserEmail(response?.data?.email);
      setUserName(response?.data?.username);
    } catch (err) {
      console.log("Error is ", err);
    }
  }
  const [admin, setAdmin] = useState([
    {
      img: adminImage,
    },
  ]);
  const [logoutBox, setLogoutBox] = useState(false);

  async function logOut() {
    let UserIdData = localStorage.getItem('UserLoginID');
    console.log("UserIdData", UserIdData)
    const deviceData = await postDeviceDeactiveApi(UserIdData);
    console.log("DeviceData", deviceData)
    if (deviceData?.status == "201") {
      localStorage.clear();
      history.replace('/login');
      console.log("logout called");
    }
  }
  return (
    <>
      {logoutBox ? (
        <div
          className="backdrop"
          onClick={() => setLogoutBox(!logoutBox)}
        ></div>
      ) : null}
      {admin.map((item, index) => (
        <div className="admin-widget" key={index}>
          <div className="admin-img">
            <div className="img-fluid" onClick={() => setLogoutBox(!logoutBox)}>
              <FaUserAlt />
            </div>
            {logoutBox ? (
              <div className="admin-info">
                <h4>{userName}</h4>
                <p>{userEmail}</p>
                <div className="log-edit-btns">
                  <Link className="btn blue-btn" onClick={logOut}>
                    Logout
                  </Link>
                  <Link to="/admin/user-profile" className="edit-profile-link">
                    View Profile
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </>
  );
};

export default AdminWidget;
