import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";

const MobileTopicMenu = ({ topics, setMobileTopicsOpen }) => {
  const [topicChildOpen, setTopicChildOpen] = useState(false);
  const [selectedDropDownIndex, setSelectedDropIndex] = useState(null);

  const setTopic = (index) => {
    setTopicChildOpen(!topicChildOpen);
    if (selectedDropDownIndex == index) {
      setSelectedDropIndex(null);
    } else {
      setSelectedDropIndex(index);
    }
  };
  console.log("Topics Mobile", topics)
  return (
    <div className="mobile-topics">
      {topics &&
        topics.map((parent, index) => (
          <div className="" key={index}>
            <div className="topic-title" onClick={() => setTopic(index)}>
              {localStorage.getItem("language") == "en" ? parent.name_en : parent.name_ur}
              {selectedDropDownIndex === index ? (
                <FaAngleUp />
              ) : (
                <FaAngleDown />
              )}
            </div>
            <ul>
              {selectedDropDownIndex == index &&
                parent.children.map((child, index2) => (
                  <Link key={index2} to={`/topic/${child.id}`}>
                    <li className="topic-item" onClick={() => setMobileTopicsOpen(false)}>
                      {`${localStorage.getItem("language") == "en" ? child.name_en : child.name_ur} (${child.questions_counts})`}
                    </li>
                  </Link>
                ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

export default MobileTopicMenu;
