import React, { useState } from "react";
import "./UserProfile.css";
import { BsX } from "react-icons/bs";
import Input from "../reuseableComponent/Input";
import Button from "../reuseableComponent/Button";
import { postEmailAPi } from "../../../services/api/EmailApi";
import { useHistory } from "react-router-dom";
const EmailForForget = () => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [checkUser, setCheckUser] = useState(false);

  const onAccoutInfoSubmit = async (e) => {
    e.preventDefault();
    try {
      let emailData = {
        email: email,
      };
      const response = await postEmailAPi(emailData);
      console.log("email response => ", response);
      if (response.status == 200) {
        history.replace("/login");
      } else {
        setCheckUser(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <section className="edit-profile">
        <div className="container">
          <div className="send-email">
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 ">
              <div className="main">
                <div className="change-info">
                  <div className="edit-profile-heading">
                    <h2>Enter Your Email</h2>
                  </div>
                  <form onSubmit={onAccoutInfoSubmit}>
                    {checkUser ? (
                      <p className="red-alert">
                        <BsX /> Invalid Email
                      </p>
                    ) : null}
                    <Input
                      type="email"
                      label="Email Address"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <Button
                      type="Submit"
                      className="blue-btn"
                      title="Continue"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmailForForget;
