import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import DashboardLogo from '../../../images/dashboard-images/dashboard-logo.png';
const Submenu = ({ navigations, navigationData, setSideBarMobile }) => {
  const [subnav, setSubnav] = useState(false);
  const [indexNumber, setIndexNumber] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const showSubnav = (index) => {
    setSubnav(!subnav);
    if (indexNumber == index) {
      setIndexNumber(null);
    } else {
      setIndexNumber(index);
    }
  };
  const ShowNavBar = (index) => {
    setSubnav(!subnav);
    setActiveSubMenu(null);
    if (indexNumber == index) {
      setIndexNumber(null);
    } else {
      setIndexNumber(index);
    }
  };
  let countArray = [];
  for (let i = 0; i < navigationData?.data?.stats?.length; ++i) {
    countArray.push(i);
  }
  return (
    <>
      <div className="dash-logo">
        <Link to="/admin">
          <div className="dasboard-image">
            <img src={DashboardLogo} alt="" className="img-fluid" />
          </div>
        </Link>
      </div>
      {navigations &&
        navigations.map((navigationItem, index) => {
          return navigationItem.children.length == 0 &&
            navigationItem.parent == null ? (
            <div className="parents-body" key={index}>
              <Link
                className={
                  indexNumber == index ? 'active-navBar' : 'sidebar-link'
                }
                to={navigationItem.path}
                onClick={() => {
                  ShowNavBar(index)
                  setSideBarMobile(true)
                }}
              >
                <div className="sidebar-menu-icon">
                  <img src={navigationItem.icon} className="img-fluid" />
                </div>
                <div className="name-count">
                  <span className="parents-navigation">
                    {navigationItem.name}{' '}
                  </span>
                  {countArray.map((id) => {
                    return navigationData?.data?.stats[id].path ==
                      navigationItem.path &&
                      navigationData?.data?.stats[id].count != 0 ? (
                      <span
                        className={
                          activeSubMenu == index
                            ? 'active-count-number'
                            : 'count-number'
                        }
                      >
                        ( {navigationData?.data?.stats[id].count} )
                      </span>
                    ) : null;
                  })}
                </div>
              </Link>
            </div>
          ) : navigationItem.children.length > 0 &&
            navigationItem.parent == null ? (
            <>
              <Link
                className={
                  indexNumber == index ? 'active-navBar' : 'sidebar-link'
                }
                onClick={() => showSubnav(index) && navigationItem.subNav}
              >
                <div className="sidebar-menu-icon">
                  <img src={navigationItem.icon} className="img-fluid" />
                </div>
                <div>
                  <span className="parents-navigation">
                    {navigationItem.name}
                  </span>
                </div>
                <div className="side-bar-dropdown-icon">
                  {indexNumber == index &&
                    navigationItem.children.length > 0 ? (
                    <BsChevronUp />
                  ) : navigationItem.children ? (
                    <BsChevronDown />
                  ) : null}
                </div>
              </Link>

              {indexNumber == index &&
                indexNumber >= 0 &&
                navigationItem.children.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      className={
                        activeSubMenu == index
                          ? 'active-dropdown-link'
                          : 'dropdown-link'
                      }
                      to={item.path}
                      onClick={() => {
                        setActiveSubMenu(index)
                        setSideBarMobile(true)
                      }}
                    >
                      <span>{item.name} </span>
                      {countArray.map((id) => {
                        let colorClass = navigationData?.data?.stats[
                          id
                        ].path.slice(17, 100);
                        return navigationData?.data?.stats[id].path ==
                          item.path &&
                          navigationData?.data?.stats[id].count != 0 ? (
                          <span
                            className={
                              activeSubMenu == index
                                ? `active-count-number ${colorClass}`
                                : `count-number ${colorClass}`
                            }
                          >
                            ( {navigationData?.data?.stats[id].count} )
                          </span>
                        ) : null;
                      })}
                    </Link>
                  );
                })}
            </>
          ) : null;
        })}
    </>
  );
};

export default Submenu;
