import React, { useState, useEffect } from "react";
import Button from "../utils/reuseableComponent/Button";
import TextArea from "./components/TextArea";
import "../Dashboard/assests/GrandMuftiTopic.css";
import Input from "../utils/reuseableComponent/Input";
import { deleteTopicsApi } from "./../../services/api/TopicsApi";
import { useParams } from "react-router-dom";
import PopUpMessage from "./components/PopUpMessage";
import { useHistory } from "react-router-dom";
import {
    getChilderTopics,
    patchTopicApi,
} from "./../../services/api/TopicsApi";
import { SpecificPostBookApi } from '../../services/api/QuestionsApi';
import RequiredToolTip from "./components/RequiredToolTip";
import { AiOutlineInfo } from "react-icons/ai";
import BreadCrumbs from './components/BreadCrumbs';
const CreateBook = () => {
    const params = useParams();
    const BookId = params.id;

    const [tracker, setTracker] = useState(true);

    const [name, setName] = useState("");
    const [nameUrdu, setNameUrdu] = useState("");

    const [nameError, setNameError] = useState("");
    const [nameUrduError, setNameUrduError] = useState("");

    const [messageAlert, setMessageAlert] = useState(false);
    const [Message, setMessage] = useState("");
    const [bookNumber, setBookNumber] = useState('');
    const [validBookNumberError, setValidBookNumberError] = useState("");

    useEffect(() => {
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (
            name &&
            nameUrdu &&
            bookNumber
        ) {
            try {
                if (isNaN(bookNumber)) {
                    setValidBookNumberError("You Should Enter Number")
                } else {

                    var BookData = {
                        name: name,
                        name_ur: nameUrdu,
                        number: bookNumber
                    };
                    const responseBookPost = await SpecificPostBookApi(BookData);
                    console.log("responseBookPost", responseBookPost)
                    if (responseBookPost.status == "201") {
                        setMessageAlert(true);
                        setMessage("Your Book is edit");
                    }

                }
            } catch (err) {
                const { responseBookPost } = err;
                console.log("error");
            }
        } else {
            if (!name) setNameError("Topic feild is required");
            if (!nameUrdu) setNameUrduError("عنوان نام ضروری ہے");
            if (!bookNumber) setValidBookNumberError("Number feild is required");
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };
    const [breadcrum, setBreadcrum] = useState([
        {
            name: "Dashboard",
            path: "/admin"
        },
        {
            name: "Manage Book",
            path: "/admin/manage-books"
        },
        { name: "Edit Book ", },
    ])
    const pageName = "Book"
    return (
        <>
            <div className="tables-breadcrums">
                <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
            </div>
            <form onSubmit={onSubmit}>
                <div className="wrap-grand-mufti main book-edit">
                    <div className="language-dedication">
                        <h4>Create Book <RequiredToolTip Text="Use this form to create the topics of categorize the question" Direction="right" icon={<AiOutlineInfo />} className="information-ttoltip" /></h4>
                    </div>
                    <div className="grand-mufti-input-topic">
                        <h6 >Book Name</h6>
                        <Input
                            placeholder="Write Your Book Name"
                            value={name}
                            onChange={(e) => {
                                if (name != null) {
                                    setNameError("");
                                }
                                setName(e.target.value);
                            }}
                        />
                        {nameError ? <p className="email-error">{nameError}</p> : null}
                    </div>
                    <div className="grand-mufti-input-topic urdu-language font-urdu">
                        <h6> کتاب کا نام</h6>
                        <Input
                            placeholder="اپنا کتاب کا نام درج کریں"
                            value={nameUrdu}
                            onChange={(e) => {
                                if (nameUrdu != null) {
                                    setNameUrduError("");
                                }
                                setNameUrdu(e.target.value);
                            }}
                            className="urdu-language"
                        />
                        {nameUrduError ? <p className="email-error font-urdu">{nameUrduError}</p> : null}
                    </div>

                    <div className="grand-mufti-input-topic">
                        <h6 >Book Number</h6>
                        <Input
                            placeholder="Write Your Book Number"
                            value={bookNumber}
                            onChange={(e) => {
                                if (bookNumber != null) {
                                    setValidBookNumberError("");
                                }
                                setBookNumber(e.target.value);
                            }}
                        />
                        {validBookNumberError ? <p className="email-error">{validBookNumberError}</p> : null}
                    </div>


                    <div className="grand-mufti-button">
                        <Button
                            title="Create Book"
                            className="grand-mufti-create-topic"
                        />
                    </div>
                    <PopUpMessage
                        LinkURL="/admin/manage-books"
                        message={Message}
                        show={messageAlert}
                        onHide={() => setMessageAlert(false)}
                    />
                </div>
            </form>
        </>
    );
};
export default CreateBook;
