import React, { useState } from 'react';
import '../assests/DashboardCounterBox.css';
import '../assests/Dashboard.css';
import RequiredToolTip from './RequiredToolTip';
import { BsQuestion } from 'react-icons/bs';

const DashboardCounterBox = ({ stats }) => {
  const [countBox, setCountBox] = useState([
    {
      className: 'total-no-question',
      title: 'Total Questions',
      questionCount: stats && stats.total_questions,
    },
    {
      className: 'total-question',
      title: 'Total Answers',
      questionCount: stats && stats.total_answers,
    },
    {
      className: 'approved-question',
      title: 'Approved Answers',
      questionCount: stats && stats.total_approved_answers,
    },
    {
      className: 'pending-question',
      title: 'Rejected Answers',
      questionCount: stats && stats.total_rejected_answers,
    },
  ]);
  let Label = [
    'Total questions asked by users this week',
    'Total answers submitted by mufti this week',
    'Total approved Answers submitted by grand mufti this week',
    'Rejected questions submitted by grand mufti this week',
  ];
  return (
    <div className="count-cards-wrapper">
      {countBox.map((item, index) => (
        <div
          className={`question-count-box box-shadow ${item.className}`}
          key={index}
        >
          <h4>
            <RequiredToolTip
              title={item.title}
              Text={Label[index]}
              Direction="right"
              icon={<BsQuestion />}
              className="information-ttoltip"
            />
          </h4>
          <p>This week</p>
          <h2>{item.questionCount}</h2>
        </div>
      ))}
    </div>
  );
};

export default DashboardCounterBox;
