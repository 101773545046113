import React, { useState, useEffect } from "react";
import "./UserProfile.css";
import Input from "../reuseableComponent/Input";
import Button from "../reuseableComponent/Button";
import { updateUserProfileApi } from "../../../services/api/UsersProfileApi";
import validator from "validator";
import PopUpMessage from "../../Dashboard/components/PopUpMessage";
const ChangeAccounInfo = ({ getName, getEmail, getFirstName, getLastName }) => {

  const [editName, setEditName] = useState(getName);
  const [editEmail, setEditEmail] = useState(getEmail);
  const [firstName, setFirstName] = useState(getFirstName);
  const [lastName, setLastName] = useState(getLastName);

  const [errorEditName, setErrorEditName] = useState("");
  const [errorEditEmail, setErrorEditEmail] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");

  const [messageAlert, setMessageAlert] = useState(false)

  const onAccoutInfoSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editName && editEmail && firstName && lastName) {
        if (!validator.isEmail(editEmail)) {
          setErrorEditEmail("Enter valid email");
        } else {
          let userId = localStorage.getItem("UserLoginID");
          let updateUserProfile = {
            email: editEmail,
            username: editName,
            first_name: firstName,
            last_name: lastName
          };
          const response = await updateUserProfileApi(userId, updateUserProfile);
          console.log("update => ", response);

          //popup
          if (response.status == "200") {
            setMessageAlert(true);
          }
        }
      } else {
        if (!editName) setErrorEditName("Feild is required")
        if (!editEmail) setErrorEditEmail("Feild is required")
        if (!firstName) setErrorFirstName("Feild is required")
        if (!lastName) setErrorLastName("Feild is required")
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [file, setFile] = useState();
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files);
  };

  return (
    <>
      <div className="change-info">
        <div className="edit-profile-heading">
          <h2>Change Your Profile Information</h2>
        </div>
        <form onSubmit={onAccoutInfoSubmit}>
          <Input
            type="text"
            label="User Name"
            placeholder=""
            onChange={(e) => {
              if (editName != null) {
                setErrorEditName("");
              }
              setEditName(e.target.value)
            }}
            value={editName}
          />
          {errorEditName ? <p className="email-error">{errorEditName}</p> : null}
          <Input
            type="text"
            label="First Name"
            placeholder=""
            onChange={(e) => {
              setFirstName(e.target.value)
              if (firstName != null) {
                setErrorFirstName("");
              }
            }}
            value={firstName}
          />
          {errorFirstName ? <p className="email-error">{errorFirstName}</p> : null}
          <Input
            type="text"
            label="Last Name"
            placeholder=""
            onChange={(e) => {
              setLastName(e.target.value)
              if (lastName != null) {
                setErrorLastName("");
              }
            }}
            value={lastName}
          />
          {errorLastName ? <p className="email-error">{errorLastName}</p> : null}
          <Input
            type="email"
            label="Email Address"
            placeholder=""
            onChange={(e) => {
              setEditEmail(e.target.value)
              if (editEmail != null) {
                setErrorEditEmail("");
              }
            }}
            value={editEmail}
          />
          {errorEditEmail ? <p className="email-error">{errorEditEmail}</p> : null}
          <Button type="Submit" className="blue-btn" title="Update" />
          <PopUpMessage
            LinkURL="/admin"
            message="Your profile has been updated successfully"
            show={messageAlert}
            onHide={() => setMessageAlert(false)}
          />
        </form>
      </div>
    </>
  );
};

export default ChangeAccounInfo;
