import axiosApi from '../axios-config/axios';

export const getNagivationsApi = async () => {
  try {
    const userID = localStorage.getItem('UserLoginID');
    // console.log("navigation auth user id =>", userID);
    const { data, status } = await axiosApi({
      endpoint: `navigations/?user=${userID}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getNagivationsUpdatedApi = async () => {
  try {
    const userID = localStorage.getItem('UserLoginID');
    // console.log("navigation auth user id =>", userID);
    const { data, status } = await axiosApi({
      endpoint: `navigation-list/?user=${userID}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
