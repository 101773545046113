import ChangeAccounPassword from './ChangeAccountPassword';
import React, { useState } from 'react';

const ForgetPassword = () => {
  const [newPass, setNewPass] = useState('');
  const [cnfrmPass, setCnfrmPass] = useState('');
  return (
    <div className="forgetpassword main">
      <ChangeAccounPassword
        setNewPass={setNewPass}
        setCnfrmPass={setCnfrmPass}
        newPass={newPass}
        cnfrmPass={cnfrmPass}
      />
    </div>
  );
};

export default ForgetPassword;
