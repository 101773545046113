import React from "react";
import DashboardQuestionDetailPage from "./DashboardQuestionDetailPage";
const AssignedQuestionDetailPage = ({ setNavigationLoad }) => {
    let isAssigned = true
    let pageBreadcrum = "Assigned Question"
    let pageLink = "/admin/questions/assigned-question"
    let previousLink = "/admin/assigned-question-view/"
    return (
        <>
            <DashboardQuestionDetailPage
                isAssigned={isAssigned}
                pageBreadcrum={pageBreadcrum}
                pageLink={pageLink}
                previousLink={previousLink}
                setNavigationLoad={setNavigationLoad}
            />
        </>
    );
};
export default AssignedQuestionDetailPage;
