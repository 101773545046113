import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BreadCrumbs from "./components/BreadCrumbs";
import QuestionEditPageAdmin from "./QuestionEditPageAdmin";
import { useParams } from 'react-router-dom';

const ApprovalEditQuestion = () => {

    const [pageTableLink, setPageTableLink] = useState();
    const [pageTableName, setPageTableName] = useState();
    const [previousPage, setPreviousPage] = useState();

    useEffect(() => {
        setPageTableLink(Link?.state?.pageTableLink);
        setPageTableName(Link?.state?.pageTableName);
        setPreviousPage(Link?.state?.previousPage)

        console.log("  pageTableLink  ", Link?.state?.pageTableLink);
        console.log("  pageTableName  ", Link?.state?.pageTableName);
        console.log("  previousPage   ", Link?.state?.previousPage);
    }, []);

    const Link = useLocation();
    const params = useParams();
    const questionID = params.id;
    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: pageTableName,
                path: pageTableLink,
            },
            {
                name: "Answer Deatil Page",
                path: `${previousPage}${questionID}`
            },
            { name: "Edit Questions", },
        ]

    const pageName = "Edit Questions"
    return (
        <>
            <BreadCrumbs
                breadcrum={breadcrum}
                pageName={pageName}
                pageTableLink={pageTableLink}
                pageTableName={pageTableName}
                previousPage={previousPage}
            />
            <QuestionEditPageAdmin idOfQuestion={questionID} />
        </>
    );
};
export default ApprovalEditQuestion;
