import axiosApi from "../axios-config/axios";
export const postUsersApi = async (UserData) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `users/`,
      method: "POST",
      bodyData: UserData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getUsersApi = async () => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `users/`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const filterUsersApi = async ({ searchOffset, word }) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `users/?offset=${0}&search=${word}`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getUsersDetailApi = async ({ id }) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `users/${id}/`,
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const updateUsersDetailApi = async (userData, id) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `users/${id}/`,
      method: "PUT",
      bodyData: userData,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const deleteUsersDetailApi = async (id) => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `users/${id}`,
      method: "DELETE",
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};

export const getLoginUserApi = async () => {
  try {
    const { data, status } = await axiosApi({
      endpoint: `auth/user/`,
      method: "GET",
      bodyData: null,
    });
    return { data, status };
  } catch (error) {
    console.log(error);
  }
};
