import React, { useState, useEffect } from "react";
import Button from "../utils/reuseableComponent/Button";
import TextArea from "./components/TextArea";
import "../Dashboard/assests/GrandMuftiTopic.css";
import Input from "../utils/reuseableComponent/Input";
import { getTopicsApi, postTopicsApi } from "./../../services/api/TopicsApi";
import PopUpMessage from "./components/PopUpMessage";
import RequiredToolTip from "./components/RequiredToolTip";
import { AiOutlineInfo } from "react-icons/ai";
import BreadCrumbs from './components/BreadCrumbs';
const GrandMuftiTopic = () => {
  const [topics, setTopics] = useState([]);

  const [name, setName] = useState("");
  const [nameUrdu, setNameUrdu] = useState("");
  const [topicId, setTopicId] = useState("");
  const [visible, setVisible] = useState("");
  const [active, setActive] = useState("");
  const [textArea, setTextArea] = useState("");
  const [textAreaUrdu, setTextAreaUrdu] = useState("");

  const [topicError, setTopicError] = useState("");
  const [nameError, setNameError] = useState("");
  const [nameUrduError, setNameUrduError] = useState("");
  const [visibleError, setVisibleError] = useState("");
  const [activeError, setActiveError] = useState("");
  const [textAreaError, setTextAreaError] = useState("");
  const [textAreaUrduError, setTextAreaUrduError] = useState("");
  const [direction, setDirection] = useState("");

  const [messageAlert, setMessageAlert] = useState(false);
  const [language, setLanguage] = useState(true);

  useEffect(() => {
    getTopics();
  }, [language]);

  async function getTopics() {
    const response = await getTopicsApi();
    console.log("response topics=> ", response);
    setTopics(response?.data);
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      name &&
      visible &&
      active &&
      textArea &&
      visible != "Select the option" &&
      active != "Select the option" &&
      nameUrdu &&
      textAreaUrdu
    ) {
      console.log("active => ", active);
      try {
        let TopicData = {
          name: name,
          name_ur: nameUrdu,
          is_active: active,
          is_visible_frontend: visible,
          description: textArea,
          description_ur: textAreaUrdu,
          parent: topicId,
        };
        const response = await postTopicsApi(TopicData);
        console.log("response => ", response);

        // Popup
        if (response.status == "201") {
          setMessageAlert(true);
        }
      } catch (err) {
        const { response } = err;
        console.log("error");
      }
    } else {
      if (!name) setNameError("Topic name is required");
      if (!nameUrdu) setNameUrduError("عنوان نام ضروری ہے");
      if (!visible || visible == "Select the option")
        setVisibleError("Topic visibilty is required");
      if (!active || active == "Select the option")
        setActiveError("Topic status is required");
      if (!textArea) setTextAreaError("Topic description is required");
      if (!textAreaUrdu) setTextAreaUrduError("عنوان کی وضاحت ضروری ہے");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const languageChange = (e) => {
    e.preventDefault()
    setLanguage(!language)
  }
  const [breadcrum, setBreadcrum] = useState([
    {
      name: "Dashboard",
      path: "/admin"
    },
    { name: " Create Topics", },
  ])

  const pageName = "Create Topics"
  return (
    <>
      <div className="tables-breadcrums">
        <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
      </div>
      <form onSubmit={onSubmit}>
        <div className="wrap-grand-mufti main">
          <div className="language-dedication">
            <h4>Create Topic <RequiredToolTip Text="Use this form to create the topics of categorize the question" Direction="right" icon={<AiOutlineInfo />} className="information-ttoltip" /></h4>
          </div>
          <div className="grand-mufti-input-topic">
            <h6 >Name <RequiredToolTip Text="Feild Required" Direction="right" icon="*" className="required-ttoltip" /></h6>
            <Input
              placeholder="Topic name "
              value={name}
              onChange={(e) => {
                if (name != null) {
                  setNameError("");
                }
                setName(e.target.value);
              }}
            />
            {nameError ? <p className="email-error">{nameError}</p> : null}
          </div>
          <div className="grand-mufti-input-topic urdu-language font-urdu">
            <h6><RequiredToolTip urduFont="font-urdu" Text="فیلڈ درکار ہے" Direction="left" icon="*" className="required-ttoltip " /> نام</h6>
            <Input
              placeholder="اپنا نام درج کریں"
              value={nameUrdu}
              onChange={(e) => {
                if (nameUrdu != null) {
                  setNameUrduError("");
                }
                setNameUrdu(e.target.value);
              }}
              className="urdu-language"
            />
            {nameUrduError ? <p className="email-error font-urdu">{nameUrduError}</p> : null}
          </div>

          <div className="grand-mufti-description">
            <h6>Description <RequiredToolTip Text="Feild Required" Direction="right" icon="*" className="required-ttoltip" /></h6>
            <TextArea
              rows="6"
              cols="60"
              placeholder="Enter Your Description"
              value={textArea}
              onChange={(e) => {
                if (textArea != null) {
                  setTextAreaError("");
                }
                setTextArea(e.target.value);
              }}
            />
            {textAreaError ? (
              <p className="email-error">{textAreaError}</p>
            ) : null}
          </div>
          <div className="grand-mufti-description urdu-language font-urdu">
            <h6><RequiredToolTip Text="فیلڈ درکار ہے" Direction="left" icon="*" className="required-ttoltip " urduFont="font-urdu" />  تفصیل</h6>
            <TextArea
              rows="6"
              cols="60"
              placeholder="اپنی تفصیل درج کریں"
              value={textAreaUrdu}
              className="urdu-language"
              onChange={(e) => {
                if (textAreaUrdu != null) {
                  setTextAreaUrduError("");
                }
                setTextAreaUrdu(e.target.value);
              }}
            />
            {textAreaUrduError ? (
              <p className="email-error font-urdu">{textAreaUrduError}</p>
            ) : null}
          </div>

          <div className="right-side-form">
            <div className="grand-mufti-select-topic">
              <h6>Type </h6>
              <select
                onChange={(e) => {
                  if (topicId != null) {
                    setTopicError("");
                  }
                  setTopicId(e.target.value);
                }}
                className="topic-dropdown"
              >
                <option value="">Select the topic </option>
                {topics &&
                  topics.map((topic) => (
                    <>
                      <option key={topic.id} value={topic.id}>
                        {topic.name_en} , {topic.name_ur}
                      </option>
                    </>
                  ))}
              </select>
              {topicError ? <p className="email-error">{topicError}</p> : null}
            </div>
            <div className="grand-mufti-select-topic">
              <h6>Status <RequiredToolTip Text="Feild Required" Direction="right" icon="*" className="required-ttoltip" /></h6>
              <select
                className="topic-dropdown"
                onChange={(e) => {
                  if (active != null) {
                    setActiveError("");
                  }
                  setActive(e.target.value);
                }}
              >
                <option>Select the option</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              {activeError ? <p className="email-error">{activeError}</p> : null}
            </div>

            <div className="grand-mufti-select-topic">
              <h6>Visiblity <RequiredToolTip Text="Feild Required" Direction="right" icon="*" className="required-ttoltip" /></h6>
              <select
                className="topic-dropdown"
                onChange={(e) => {
                  if (visible != null) {
                    setVisibleError("");
                  }
                  setVisible(e.target.value);
                }}
              >
                <option>Select the option </option>
                <option value="true">Visible</option>
                <option value="false">Hidden</option>
              </select>
              {visibleError ? (
                <p className="email-error">{visibleError}</p>
              ) : null}
            </div>
          </div>
          <div className="grand-mufti-button">
            <Button title="Create topic" className="grand-mufti-create-topic" />
          </div>
        </div>
        <PopUpMessage
          LinkURL="/admin/topic"
          message="Topic has been created"
          show={messageAlert}
          onHide={() => setMessageAlert(false)}
        />
      </form>
    </>
  );
};
export default GrandMuftiTopic;
