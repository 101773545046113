import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import RejectedQuestion from "./RejectedQuestion";
import ApprovedQuestion from "./ApprovedQuestion";
import UpdateQuestion from "./UpdateQuestion";
import Topic from "./Topic";
import DashboardHome from "./DashboardHome";
import "../Dashboard/assests/Dashboard.css";
import AdminWidget from "./components/AdminWidget";
import NewQuestions from "./NewQuestions";
import AllQuestions from "./AllQuestions";
import AllOpenQuestions from "./AllOpenQuestions";
import ChilderTopics from "./ChilderTopics";
import DashboardQuestionDetailPage from "./DashboardQuestionDetailPage";
import GrandMuftiTopic from "./GrandMuftiTopic";
import User from "./User";
import MuftiUserProfile from "./MuftiUserProfile";
import EditTopic from "./EditTopic";
import "./DashboardResponsive.css";
import SideBarMenu from "./components/SideBarMenu";
import UserProfile from "../utils/userProfile/UserProfile";
import EditProfile from "../utils/userProfile/EditProfile";
import EditProfileSuccess from "../utils/userProfile/EditProfileSuccess";
import GrandMuftiCreateUser from "./GrandMuftiCreateUser";
import AssignedQuestion from "./AssignedQuestion";
import MuftiEditUserProfile from "./MuftiEditUserProfile";
import PendingQuestion from "./PendingQuestion";
import ReviewAnswerDetailPage from "./ReviewAnswerDetailPage";
import GrandMuftiPending from "./GrandMuftiPending";
import MuftiTopics from "./MuftiTopics";
import ChildTopicMutfi from "./ChildTopicMutfi";
import QuestionEditPageAdmin from "./QuestionEditPageAdmin";
import AnswerEditPageAdmin from "./AnswerEditPageAdmin";
import EditAnswerDirectPage from "./EditAnswerDirectPage";
import EditPageDirectLink from "./EditPageDirectLink";
import EditPageQuestion from "./EditPageQuestion";
import ChildEditPage from "./ChildEditPage";
import UserEdit from "./UserEdit";
import ManageBooks from "./ManageBooks";
import DirectEditMiftiProfile from "./DirectEditMiftiProfile";
import TopicEditPage from "./TopicEditPage";
import Notification from "./Notification";
import NewQuestionDetailPage from "./NewQuestionDetailPage";
import PendingAnswerReview from "./PendingAnswerReview";
import RejectAnswerReview from "./RejectAnswerReview";
import ApprovedAnswerReview from "./ApprovedAnswerReview";
import AssignedQuestionDetailPage from "./AssignedQuestionDetailPage";
import CreateQuestionAdmin from "./CreateQuestionAdmin";
import CreateBook from "./CreateBook";
import EditBook from "./EditBook";
import ApprovalEditQuestion from "./ApprovalEditQuestion";
import AssignedEditQuestionPage from "./AssignedEditQuestionPage";
import { postFireBaseApi } from "../../services/api/FireBaseApi";
import { postDeviceActiveApi } from "../../services/api/DeviceApi";
import { getToken } from "./firebase"
import { useTranslation } from 'react-i18next';
import { getUsersDetailApi } from '../../services/api/UsersApi';

const Dashboard = ({ setLanguageDashbaord }) => {

  const { t, i18n } = useTranslation();
  const [navigationLoad, setNavigationLoad] = useState(false);

  useEffect(async () => {
    try {
      i18n.changeLanguage('en');
      let UserIdData = localStorage.getItem("UserLoginID");
      setLanguageDashbaord(true)
      const deviceData = await postDeviceActiveApi(UserIdData);
      localStorage.setItem('language', 'en');
      let Token = await getToken()
      let UserID = localStorage.getItem("UserLoginID");
      const FireBaseData = {
        type: "web",
        registration_token: Token,
        user: UserID,
        registration_id: Token
      };
      const response = postFireBaseApi(FireBaseData);
      const responseUser = await getUsersDetailApi({ id: UserIdData });
      localStorage.setItem("isAdmin", responseUser.data.is_superuser);
    } catch (error) {
      console.log("error")
    }
  }, [localStorage.getItem("language")]);
  return (
    <>
      <div className="App" >
        <div className="dashboard-main">
          <div className="dashboard-side-nav">
            <SideBarMenu
              navigationLoad={navigationLoad}
              setNavigationLoad={setNavigationLoad}
            />
          </div>
          <div className="dashboard-content">
            <AdminWidget />
            <Notification
              setNavigationLoad={setNavigationLoad}
            />
            <Switch>
              <Route exact path="/admin">
                <DashboardHome
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/question-edit/:id">
                <EditPageDirectLink />
              </Route>
              <Route path="/admin/assigned-edit-question/:id">
                <AssignedEditQuestionPage />
              </Route>
              <Route path="/admin/edit-answer/:id">
                <EditAnswerDirectPage />

              </Route>
              <Route path="/admin/edit-questions/:id">
                <EditPageQuestion />
              </Route>

              <Route path="/admin/admin-edit-questions/:id">
                <ApprovalEditQuestion />
              </Route>

              <Route path="/admin/create-questions">
                <CreateQuestionAdmin />
              </Route>

              <Route path="/admin/user-profile-edit/:id">
                <DirectEditMiftiProfile />
              </Route>

              <Route path="/admin/edit-child-topics/:id">
                <ChildEditPage />
              </Route>
              <Route path="/admin/edit-parents-topics/:id">
                <TopicEditPage />
              </Route>
              <Route path="/admin/grand-mufti-topic">
                <GrandMuftiTopic />
              </Route>
              <Route path="/admin/edit-question-page/:id">
                <QuestionEditPageAdmin />
              </Route>
              <Route path="/admin/edit-answer-page/:id">
                <AnswerEditPageAdmin />
              </Route>
              <Route path="/admin/mufti-topic">
                <MuftiTopics />
              </Route>
              <Route path="/admin/grand-mufti-user-edit/:id">
                <MuftiEditUserProfile />
              </Route>
              <Route path="/admin/topic">
                <Topic />
              </Route>
              <Route path="/admin/manage-books">
                <ManageBooks />
              </Route>
              <Route path="/admin/questions/assigned-question">
                <AssignedQuestion
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/edit-book/:id">
                <EditBook />
              </Route>
              <Route path="/admin/create-book">
                <CreateBook />
              </Route>
              <Route path="/admin/questions/answer-review/:id">
                <ReviewAnswerDetailPage />
              </Route>
              <Route path="/admin/questions/grand-mufti-pending-questions">
                <GrandMuftiPending
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/questions/pending-questions">
                <PendingQuestion />
              </Route>
              <Route path="/admin/new-question">
                <NewQuestions
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/all-questions">
                <AllQuestions
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/all-open-questions">
                <AllOpenQuestions
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/questions/update-questions">
                <UpdateQuestion />
              </Route>
              <Route path="/admin/questions/rejected-questions">
                <RejectedQuestion
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/questions/approved-questions">
                <ApprovedQuestion
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/user">
                <User />
              </Route>
              <Route path="/admin/user-dashtable/:id">
                <MuftiUserProfile />
              </Route>
              <Route path="/admin/user-edit/:id">
                <UserEdit />
              </Route>
              <Route path="/admin/view-question/:id">
                <DashboardQuestionDetailPage />
              </Route>
              <Route path="/admin/new-question-view/:id">
                <NewQuestionDetailPage
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/questions/pending-answer-review/:id">
                <PendingAnswerReview
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/questions/reject-answer-review/:id">
                <RejectAnswerReview
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/questions/approve-answer-review/:id">
                <ApprovedAnswerReview
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/assigned-question-view/:id">
                <AssignedQuestionDetailPage
                  setNavigationLoad={setNavigationLoad}
                />
              </Route>
              <Route path="/admin/childerns-view/:id">
                <ChilderTopics />
              </Route>
              <Route path="/admin/user-profile">
                <UserProfile />
              </Route>
              <Route path="/admin/editprofile">
                <EditProfile />
              </Route>
              <Route path="/admin/edit-topics/:id">
                <EditTopic />
              </Route>
              <Route path="/admin/mufti-childerns-view/:id">
                <ChildTopicMutfi />
              </Route>
              <Route path="/admin/editsuccess">
                <EditProfileSuccess />
              </Route>
              <Route path="/admin/create-user">
                <GrandMuftiCreateUser />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
