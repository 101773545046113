import React, { useEffect, useState } from "react";
import BreadCrumbs from "./components/BreadCrumbs";
import { useParams } from 'react-router-dom';
import {
    getChilderTopics,
} from "./../../services/api/TopicsApi";
import EditTopic from "./EditTopic";
const ChildEditPage = () => {

    const params = useParams();
    const topicsID = params.id;

    const [parentsTopics, setParentsTopics] = useState("");
    const [parentsId, setParentsId] = useState("");
    const [parentsName, setParentsName] = useState("");

    useEffect(() => {
        getCurrentTopicData();
    }, []);

    async function getCurrentTopicData() {
        try {
            const response = await getChilderTopics(topicsID);
            setParentsTopics(response.data.name)
            setParentsId(response.data.parent)
            let parentsID = response.data.parent
            const TopicsParentsName = await getChilderTopics(parentsID);
            setParentsName(TopicsParentsName.data.name)
        } catch (err) {
            console.log("Error is ", err);
        }
    }

    let Breadcurms = localStorage.getItem("Breadcurms")
    let breadcurmsPath = localStorage.getItem("breadcurmsPath")

    const breadcrum =
        [
            {
                name: "Dashboard",
                path: "/admin"
            },
            {
                name: "Topics",
                path: "/admin/topic"
            },
            {
                name: parentsName,
                path: `/admin/childerns-view/${parentsId}`
            },
            {
                name: `Edit " ${parentsTopics} "`,
            },

        ]

    const pageName = "Edit Topic"
    return (
        <>
            <BreadCrumbs breadcrum={breadcrum} pageName={pageName} />
            <EditTopic />
        </>
    );
};
export default ChildEditPage;
